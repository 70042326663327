import { all } from 'redux-saga/effects';

// Sagas
import { saga as authSaga } from './duck/authDuck';
import { saga as passwordSaga } from './duck/passwordDuck';
import { saga as userConsentSaga } from './duck/userConsentDuck';
import { saga as ecgTestListSaga } from './duck/ecgTestList/ecgTestListDuck';
import { saga as patchEcgsSaga } from './duck/patchEcgsDuck';
import { saga as medicalStaffsSaga } from './duck/medicalStaffsDuck';
import { saga as reportsSaga } from './duck/reportsDuck';
import { saga as reportSaga } from './duck/reportDuck';
import { saga as deviceSummarySaga } from './duck/deviceSummaryDuck';
import { saga as deviceEcgTotalSaga } from './duck/deviceEcgDuck';
import { saga as patientEventsSaga } from './duck/patientEventsDuck';
import { saga as testResultSaga } from './duck/testResultDuck';
import { saga as beatReviewSaga } from './duck/beatReviewDuck';
import { saga as hrReviewSaga } from './duck/hrReviewDuck';
import { saga as beatsRequestQueueSaga } from './duck/beatsRequestQueueDuck';
import { saga as feedbackSaga } from './duck/feedback/feedbackDuck';
import { saga as hospitalSaga } from './duck/hospital/hospitalDuck';
import { saga as testNotificationSaga } from './duck/testNotificationDuck';
import { saga as paymentSaga } from './duck/paymentDuck';

export default function* rootSaga() {
  yield all([
    authSaga(),
    passwordSaga(),
    userConsentSaga(),
    ecgTestListSaga(),
    patchEcgsSaga(),
    medicalStaffsSaga(),
    reportsSaga(),
    reportSaga(),
    deviceSummarySaga(),
    deviceEcgTotalSaga(),
    patientEventsSaga(),
    testResultSaga(),
    beatReviewSaga(),
    hrReviewSaga(),
    beatsRequestQueueSaga(),
    feedbackSaga(),
    hospitalSaga(),
    testNotificationSaga(),
    paymentSaga(),
  ]);
}
