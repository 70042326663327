import { call, select } from 'redux-saga/effects';

import { TIME_EVENT_TYPE } from 'constant/EventConst';
import { ECG_CHART_UNIT } from 'constant/ChartEditConst';

import ApiManager from 'network/ApiManager/ApiManager';

import {
  selectBeatsNEctopicList,
  selectSelectionStrip,
} from 'redux/duck/testResultDuck';

/** 2초 */
const PAUSE_MIN_DURATION_WAVEFORM_LENGTH = 500;

/**
 * @enum {number} TIME_EVENT_TYPE
 */
const CONST_TIME_EVENT_TYPE = TIME_EVENT_TYPE;

/**
 * @typedef {keyof TIME_EVENT_TYPE} BeatType
 *
 * @typedef BeatsNEctopicData BeatsNEctopic의 data 객체
 * @property {Date} createAt
 * @property {WaveformIndex[]} onsetWaveformIndex
 * @property {WaveformIndex[]} terminationWaveformIndex
 * @property {CONST_TIME_EVENT_TYPE} beatType
 * @property {number} hrAvg
 * @property {any[]} noises
 * @property {any[]} ectopics
 */

/**
 * Selection Marker 범위 내에 존재하는 R peek의 정보를 반환하는 함수
 * @param {{onsetWaveformIndex: WaveformIndex, terminationWaveformIndex: WaveformIndex, beatsNEctopicList: any}} props
 * @return {{waveformIndex: WaveformIndex[], beatType: BeatType[]}}
 */
function getBeatInfosFromSelectionMarker({
  onsetWaveformIndex,
  terminationWaveformIndex,
  beatsNEctopicList,
}) {
  // 비트 정보를 받아올 long term strip의 index를 구한다
  const startStripIndex = parseInt(
    onsetWaveformIndex / ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX
  );
  const lastStripIndex = parseInt(
    terminationWaveformIndex / ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX
  );

  let waveformIndex = []; // 반환할 R peek의 waveformIndex의 배열
  let beatType = []; // 반환할 R beat의 type 배열

  for (let i = startStripIndex; i <= lastStripIndex; i++) {
    const stripKey = String(i * ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX);
    const beatObject = beatsNEctopicList[stripKey].beats;
    // onset Selection Marker의 안쪽 인접 beat의 위치로 범위를 좁힌 후 waveformIndex를 구한다
    const modifiedOnsetIndex = beatObject.waveformIndex.findIndex(
      (v) => v >= onsetWaveformIndex
    );

    // termination Selection Marker의 안쪽 인접 beat의 위치로 범위를 좁힌 후 waveformIndex를 구한다
    const modifiedTerminationIndex = beatObject.waveformIndex.findLastIndex(
      (v) => v <= terminationWaveformIndex
    );

    // 구한 범위 안쪽에 존재하는 비트의 값들을 가져온다.
    const beatsList = beatObject.waveformIndex.slice(
      modifiedOnsetIndex,
      modifiedTerminationIndex + 1
    );
    const beatTypeList = beatObject.beatType.slice(
      modifiedOnsetIndex,
      modifiedTerminationIndex + 1
    );

    waveformIndex = waveformIndex.concat(beatsList);
    beatType = beatType.concat(beatTypeList);
  }

  return {
    waveformIndex,
    beatType,
  };
}

/**
 * beat의 배열을 받아 R-R interval 구간으로 만들어 반환하는 함수
 */
const getRRIBeatsArray = ({ waveformIndex, beatType }) => {
  const rRIBeatsArray = [];

  if (waveformIndex.length < 2) {
    throw createPreProcessError('NoRRIExists');
  }

  for (let i = 1; i < waveformIndex.length; i++) {
    if (beatType[i - 1] === 3) continue;
    if (beatType[i] === 3) continue;
    // 간격이 2초 이상만 구간으로 사용
    if (
      waveformIndex[i] - waveformIndex[i - 1] <
      PAUSE_MIN_DURATION_WAVEFORM_LENGTH
    )
      continue;

    rRIBeatsArray.push({
      onsetWaveformIndex: waveformIndex[i - 1],
      terminationWaveformIndex: waveformIndex[i],
    });
  }

  return rRIBeatsArray;
};

/**
 * eventType이 PAUSE일 때의 전처리 함수
 */
function* preProcessForPause({
  onsetWaveformIndex,
  terminationWaveformIndex,
  isRemove,
  tid,
  eventType = CONST_TIME_EVENT_TYPE.PAUSE,
}) {
  const beatsNEctopicList = yield select(selectBeatsNEctopicList);
  const { onset, termination } = yield select(selectSelectionStrip);

  // 기존 구간을 onset으로만 선택하여 remove할때, termination이 없는 경우를 처리
  if (isRemove && termination && !termination.clickedWaveformIndex) {
    return [
      call(ApiManager.postTimeEvent, {
        isRemove,
        tid,
        eventType,
        onsetWaveformIndex: onsetWaveformIndex,
        terminationWaveformIndex: terminationWaveformIndex,
      }),
    ];
  }

  // onset이 beatsNEctopicList에 존재하는지 여부
  const isOnsetInList = beatsNEctopicList[onset.representativeWaveformIndex];
  // termination이 beatsNEctopicList에 존재하는지 여부
  const isTerminationInList =
    beatsNEctopicList[termination.representativeWaveformIndex];
  if (!isOnsetInList || !isTerminationInList) {
    throw createPreProcessError('CannotSelectBetweenJump');
  }

  const selectedBeatsInfo = getBeatInfosFromSelectionMarker({
    onsetWaveformIndex: onsetWaveformIndex,
    terminationWaveformIndex: terminationWaveformIndex,
    beatsNEctopicList,
  });

  if (isRemove) {
    // 철회의 경우 전체구간을 1번만 전송해도 삭제할 수 있으므로 1번의 API를 요청한다
    return [
      call(ApiManager.postTimeEvent, {
        isRemove,
        tid,
        eventType,
        onsetWaveformIndex: selectedBeatsInfo.waveformIndex[0],
        terminationWaveformIndex:
          selectedBeatsInfo.waveformIndex[
            selectedBeatsInfo.waveformIndex.length - 1
          ],
      }),
    ];
  }

  // PAUSE 설정의 경우
  const rRIBeatsArray = getRRIBeatsArray(selectedBeatsInfo);
  return rRIBeatsArray.map((rri) =>
    call(ApiManager.postTimeEvent, {
      ...rri,
      isRemove,
      tid,
      eventType,
    })
  );
}

/**
 * TimeEvent 요청 전 선택된 항목에 대한 전처리를 하는 함수.
 * @param {TimeEventsPostRequestBodyType} requestBody
 */
export function* preProcessEditedTimeEvent(params) {
  switch (params.eventType) {
    case CONST_TIME_EVENT_TYPE.PAUSE: {
      return yield call(preProcessForPause, params);
    }
    case CONST_TIME_EVENT_TYPE.AF:
    case CONST_TIME_EVENT_TYPE.OTHERS: {
      return [call(ApiManager.postTimeEvent, params)];
    }
    case CONST_TIME_EVENT_TYPE.AVB_2:
    case CONST_TIME_EVENT_TYPE.AVB_3: {
      params.groupType = CONST_TIME_EVENT_TYPE.AV_BLOCK;
      return [call(ApiManager.postTimeEvent, params)];
    }
    case CONST_TIME_EVENT_TYPE.AV_BLOCK: {
      params.groupType = params.eventType;
      delete params.eventType;
      return [call(ApiManager.postTimeEvent, params)];
    }
    default:
      return;
  }
}

const createPreProcessError = (message) => {
  const error = new Error();
  error.name = 'preProcessEditedTimeEvent Error';
  error.message = message;
  return error;
};
