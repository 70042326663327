const API_URL = `${window.location.origin}/api`;
const REPORT_API_URL = `${window.location.origin}/report`;

const WITHOUT_PROXY_API_URL =
  process.env.NODE_ENV === 'production'
    ? `${(() => {
        if (process.env.REACT_APP_CUSTOM_ENV === 'prod') {
          return process.env.REACT_APP_PROD_API_SERVER_URL;
        } else if (process.env.REACT_APP_CUSTOM_ENV === 'qa') {
          return process.env.REACT_APP_QA_API_SERVER_URL;
        } else if (process.env.REACT_APP_CUSTOM_ENV === 'dev') {
          return process.env.REACT_APP_DEV_API_SERVER_URL;
        } else {
          return process.env.REACT_APP_ADHOC_API_SERVER_URL;
        }
      })()}/api/v3`
    : API_URL;

const UrlList = {
  /**
   * Authentication
   **/
  getAuthUrl: function () {
    return `${API_URL}/auth`;
  },
  getPublicKeyUrl: function () {
    return `${this.getAuthUrl()}/public-key`;
  },
  getEmailCheckUrl: function () {
    return `${this.getAuthUrl()}/email-check`;
  },
  getPasswordResetUrl: function () {
    return `${this.getAuthUrl()}/password-reset`;
  },
  getPasswordResetEmailWithTokenUrl: function () {
    return `${this.getAuthUrl()}/password-reset-email-with-token`;
  },
  getPasswordResetEmailUrl: function () {
    return `${this.getAuthUrl()}/password-reset-email`;
  },
  getPasswordResetEmailCompleteUrl: function () {
    return `${this.getAuthUrl()}/password-reset-email-complete`;
  },
  getPasswordResetNextTimeUrl: function () {
    return `${this.getAuthUrl()}/password-reset-next-time`;
  },
  getPasswordResetWithOldPasswordUrl: function () {
    return `${this.getAuthUrl()}/password-reset-with-old-password`;
  },
  getLoginUrl: function () {
    return `${this.getAuthUrl()}/token/login`;
  },
  getLogoutUrl: function () {
    return `${this.getAuthUrl()}/token/logout`;
  },
  getTokenRefreshUrl: function () {
    return `${this.getAuthUrl()}/token/refresh`;
  },
  getUserConsentUrl: function () {
    return `${this.getAuthUrl()}/user-consent`;
  },
  getReadMyInformationURL: function () {
    return `${API_URL}/me`;
  },

  /**
   * ECG Tests
   **/
  getEcgTestsUrl: function () {
    return `${API_URL}/ecg-tests`;
  },
  getEcgTestsIdUrl: function (ecgTestId) {
    return `${this.getEcgTestsUrl()}/${ecgTestId}`;
  },
  getEcgTestsIdRevertUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/revert-customer-confirm`;
  },
  getEcgTestsValidateUploadUrl: function () {
    return `${this.getEcgTestsUrl()}/validate-upload`;
  },
  getEcgTestsUploadEcgUrl: function () {
    return `${this.getEcgTestsUrl()}/upload-ecg`;
  },
  getEcgTestsIdReturnDeviceUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/return-device`;
  },
  getEcgTestsIdConfirmReviewUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/confirm`;
  },
  getEcgTestsIdEditDoneReviewUrl: function (ecgTestId) {
    // only for partner
    return `${this.getEcgTestsIdUrl(ecgTestId)}/cloud-status/edit-done`;
  },
  getEcgTestsIdCompleteUploadUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/complete-upload`;
  },
  getEcgTestsMedicalFeeExcelDownloadUrl: function () {
    return `${WITHOUT_PROXY_API_URL}/ecg-tests/medical-fee-excel`;
  },
  getEcgTestsPaymentExcelDownloadUrl: function () {
    return `${API_URL}/ecg-tests/payment-excel`;
  },

  /**
   * Patch ECGs
   **/
  getPatchEcgsUrl: function () {
    return `${API_URL}/patch-ecgs`;
  },
  getPatchEcgsIdUrl: function (ecgTestId) {
    return `${this.getPatchEcgsUrl()}/${ecgTestId}`;
  },
  getPatchEcgsIdDailyStatChart: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/daily-stat-chart`;
  },
  getPatchEcgsIdExplorerUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/explorer`;
  },
  getPatchRawEcgsUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/raw`;
  },
  getPatchEcgsIdEventCountsUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/event-count`;
  },
  bulkConfirmUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/bulk-confirm`;
  },
  getPatchEcgsIdStatistics: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/statistics`;
  },
  getPatchEcgsEctopic: function (ecgTestId) {
    return `${this.getPatchEcgsUrl(ecgTestId)}/ectopic`;
  },
  getPatchEcgsIdBeatsUrl: function (ecgTestId, suffix) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/beats/${suffix}`;
  },
  getLimitUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/limit`;
  },
  getRevertUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/revert`;
  },
  getEctopicUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/ectopic`;
  },
  getEctopicWaveformIndexesUrl: function (ecgTestId) {
    return `${this.getEctopicUrl(ecgTestId)}/onset-waveform-indexes`;
  },
  getEctopicDetailUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/ectopic/filter/type`;
  },
  getEctopicFilterTypeUrl: function (ecgTestId) {
    return `${this.getEctopicUrl(ecgTestId)}/filter/type`;
  },
  getEctopicFilterBulkTypeUrl: function (ecgTestId) {
    return `${this.getEctopicUrl(ecgTestId)}/filter-bulk/type`;
  },
  getEctopicFilterWaveformIndexUrl: function (ecgTestId) {
    return `${this.getEctopicUrl(ecgTestId)}/filter/waveform-index`;
  },
  getEctopicFilterWaveformIndexRangeUrl: function (ecgTestId) {
    return `${this.getEctopicUrl(ecgTestId)}/filter/waveform-index-range`;
  },
  getBeatsUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/beats`;
  },
  getAddBeatsUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/add-beats`;
  },
  getUpdateBeatsByBetweenIndexUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/update-beats/between-waveform-index`;
  },
  getUpdateBeatsByIndexesUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/update-beats/waveform-indexes`;
  },
  getRemoveBeatsUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/remove-beats`;
  },
  getBeatsFilterWaveformIndexRangeUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/filter/waveform-index-range`;
  },
  getBeatPostprocessUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/postprocess`;
  },
  /* Patch ECGs > Histogram */
  getHrHistogramUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/hr-histogram`;
  },
  getHrHistogramBinDetailUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/hr-histogram/bin`;
  },
  getRrHistogramUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/rr-histogram`;
  },
  getRrHistogramBinDetailUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/rr-histogram/bin`;
  },

  /** Will be DEPRECATE */
  getGetEventCountsUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/event-count`;
  },

  // Patient Events
  getPatientEventsUrl: function () {
    return `${API_URL}/patient-events`;
  },
  getPatientEventIdUrl: function (pteId) {
    return `${API_URL}/patient-events/${pteId}`;
  },

  // Time Events
  getTimeEventsDirectUrl: function () {
    return `${WITHOUT_PROXY_API_URL}/time-events`;
  },
  getTimeEventsUrl: function () {
    return `${API_URL}/time-events`;
  },
  getTimeEventsIdUrl: function (timeEventId) {
    return `${this.getTimeEventsUrl()}/${timeEventId}`;
  },

  // Geminy
  getGeminyUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/geminy`;
  },
  getGeminyFilterTypeUrl: function (ecgTestId) {
    return `${this.getGeminyUrl(ecgTestId)}/filter/type`;
  },
  getGeminyFilterWaveformIndexUrl: function (ecgTestId) {
    return `${this.getGeminyUrl(ecgTestId)}/filter/waveform-index`;
  },

  /**
   * Events
   **/
  getEventsUrl: function () {
    return `${API_URL}/events`;
  },
  getEventsIdUrl: function (eventId) {
    return `${this.getEventsUrl()}/${eventId}`;
  },
  getEventsIdConfirmUrl: function (eventId) {
    return `${this.getEventsIdUrl(eventId)}/confirm`;
  },

  /**
   * Reports
   */
  getReportsUrl: function () {
    return `${API_URL}/reports`;
  },
  getReportsDetailURL: function (reportId) {
    return `${this.getReportsUrl()}/${reportId}/entire`;
  },
  getReportIdCalculateUrl: function (reportId) {
    return `${this.getReportsUrl()}/${reportId}/compute`;
  },
  getReportIdIsValidUrl: function (reportId) {
    return `${this.getReportsUrl()}/${reportId}/is-valid`;
  },
  getReportSelectedEventUrl: function (reportId) {
    return `${this.getReportsUrl()}/${reportId}/selected-events`;
  },
  getReportGenerateUrl: function () {
    return `${REPORT_API_URL}/generate`;
  },
  getReportHandleUrl: function (reportId) {
    return `${REPORT_API_URL}/${reportId}/events`;
  },
  getReportsStatistics: function (reportId) {
    return `${this.getReportsUrl()}/${reportId}/statistics`;
  },

  /**
   * Report Events
   */
  getReportEventsUrl: function () {
    return `${API_URL}/report-events`;
  },
  getReportEventsIdUrl: function (reportEventId) {
    return `${API_URL}/report-events/${reportEventId}`;
  },

  /**
   * Medical Staffs
   **/
  getMedicalStaffsUrl: function () {
    return `${API_URL}/medical-staffs`;
  },
  getMedicalStaffsUserNameUrl: function (userName) {
    return `${this.getMedicalStaffsUrl()}/${userName}`;
  },

  /**
   * User
   **/
  getUserUrl: function () {
    return `${API_URL}/user`;
  },
  getUserForgotPasswordUrl: function () {
    return `${this.getUserUrl()}/forgotpassword`;
  },
  getUserChangePasswordUrl: function () {
    return `${this.getUserUrl()}/changePassword`;
  },

  /**
   * Device
   **/
  getDeviceUrl: function () {
    return `${API_URL}/device`;
  },
  getDeviceIdUrl: function (deviceId) {
    return `${this.getDeviceUrl()}/${deviceId}`;
  },

  /**
   * Markings
   **/
  getDeviceMarkingsUrl: function (deviceId) {
    return `${this.getDeviceIdUrl(deviceId)}/markings`;
  },
  getDeviceMarkingsIdUrl: function (deviceId, markingsId) {
    return `${this.getDeviceMarkingsUrl(deviceId)}/${markingsId}`;
  },

  /**
   * Common
   **/
  getStaffPatientsUrl: function () {
    return `${API_URL}/staff/fetch-patient`;
  },
  getDeviceSummaryUrl: function (deviceId) {
    return `${API_URL}/device/${deviceId}/summary`;
  },
  getDeviceEcgTotalUrl: function (deviceId) {
    return `${API_URL}/device/${deviceId}/ecg/total`;
  },

  // Hospital Statistics
  getAnalyticsStatisticUrl: function (hospitalId) {
    return `${API_URL}/hospitals/${hospitalId}/stats/usage`;
  },
  _getPatchStatisticsUrl: function () {
    return `${API_URL}/devices/patch/stats`;
  },

  /**
   * Test Notification
   */
  getTestNotificationUrl: function () {
    return `${API_URL}/test-notification`;
  },
  getPatchReadByTidUrl: function () {
    return `${this.getTestNotificationUrl()}/set-read-by-tids`;
  },
  getPatchReadByDateRangeUrl: function () {
    return `${this.getTestNotificationUrl()}/set-read-by-date-range`;
  },

  /**
   * Parcel
   */
  getParcelUrl: function () {
    return `${API_URL}/parcel`;
  },
  getParcelByIdUrl: function (parcelId) {
    return `${this.getParcelUrl()}/${parcelId}`;
  },

  /**
   *  EMR / Patch Report Status
   */
  getPatchReportUploadStatusUrl: function (ecgTestId) {
    return `${API_URL}/ecg-tests/${ecgTestId}/is-uploaded-to-emr`;
  },

  /**
   *  MFD Download Path
   */
  getMfdVersionUrl: function () {
    return `${API_URL}/file-manager/versions`;
  },
};

export default UrlList;
