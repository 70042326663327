import {
  BeatEventOrUndefined,
  BeatsNBeatEventsList,
  BeatsNBeatEvents,
  EventUpdateDirection,
  IBeatEvent,
  Range,
  WaveformIndex,
  WaveformIndexOrUndefined,
  RangeOrUndefined,
} from './types';

import {
  compareBeatEvents,
  getEctopicType,
  getEdgeMarkerValues,
  getKeyWaveformIndex,
  isSomeBeatIncludesOtherList,
} from './common';

import rfdc from 'rfdc';
import { EVENT_CONST_TYPES } from 'constant/EventConst';
import { getEventInfoByQuery } from 'util/EventConstUtil';

const rfdcClone = rfdc();

const NOISE_TYPE = EVENT_CONST_TYPES.NOISE;
const THIRTY_SEC_WAVEFORM_LENGTH: number = 7500;
const INITIAL_ONSET_MARKER_WAVEFORM_INDEX: WaveformIndex = -1;
const INITIAL_TERMINATION_MARKER_WAVEFORM_INDEX: WaveformIndex =
  Number.MAX_SAFE_INTEGER;

//
export function getTotalFreshBeatsNBeatEventsList(
  _staleList: BeatsNBeatEventsList,
  _freshList: BeatsNBeatEventsList
): BeatsNBeatEventsList {
  if (Object.values(_staleList).length === 0) return _freshList;
  if (Object.values(_freshList).length === 0) return _staleList;

  const staleList = rfdcClone(_staleList);
  const freshList = rfdcClone(_freshList);

  const {
    /** 로드된 EGC 구간의 모든 Beat 의 Waveform Index 목록 */
    totalBeatList,
    /** Fresh 데이터 직전 Beat 위치 */
    beforeStaleLastBeatWI,
    /** Fresh 데이터 첫 번째 Beat 위치 */
    freshFirstBeatWI,
    /** Fresh 데이터 마지막 Beat 위치 */
    freshLastBeatWI,
    /** Fresh 데이터 직후 Beat 위치 */
    afterStaleFirstBeatWI,
    /** Before Stale 데이터 구간 */
    beforeStaleRange,
    /** Fresh 데이터 구간 */
    freshRange,
    /** After Stale 데이터 구간 */
    afterStaleRange,
  } = getCoreValues(staleList, freshList);
  const beforeStaleLastBeatIncludedEvent = getSomeBeatIncludedEvent(
    staleList,
    beforeStaleLastBeatWI
  );
  const freshFirstBeatIncludedEvent = getSomeBeatIncludedEvent(
    freshList,
    freshFirstBeatWI
  );
  const freshLastBeatIncludedEvent = getSomeBeatIncludedEvent(
    freshList,
    freshLastBeatWI
  );
  const afterStaleFirstBeatIncludedEvent = getSomeBeatIncludedEvent(
    staleList,
    afterStaleFirstBeatWI
  );

  /*
  1. freshFirstBeatIncludedEvent 와 freshLastBeatIncludedEvent 이 똑같은 경우
    1-1. beforeStaleLastBeatIncludedEvent, freshFirstBeatIncludedEvent, afterStaleFirstBeatIncludedEvent 모두 합쳐저야 하는 경우
    1-2. beforeStaleLastBeatIncludedEvent, freshFirstBeatIncludedEvent 합쳐저야 하는 경우
      1-2-1. afterStaleFirstBeatIncludedEvent 만 있을경우 Onset Marker 정보 업데이트
    1-3. freshFirstBeatIncludedEvent, afterStaleFirstBeatIncludedEvent 합쳐저야 하는 경우
      1-3-1. beforeStaleLastBeatIncludedEvent 만 있을경우 Termination Marker 정보 업데이트
    1-4. beforeStaleLastBeatIncludedEvent, freshFirstBeatIncludedEvent, afterStaleFirstBeatIncludedEvent 모두 다른 Event인 경우
  2. freshFirstBeatIncludedEvent 와 freshLastBeatIncludedEvent 이 다른 경우
    2-1(참). beforeStaleLastBeatIncludedEvent 와 freshFirstBeatIncludedEvent 이 합쳐저야 하는 경우
    2-1(거짓). beforeStaleLastBeatIncludedEvent 와 freshFirstBeatIncludedEvent 이 다른 이벤트인 경우
    2-2(참). freshLastBeatIncludedEvent 와 afterStaleFirstBeatIncludedEvent 이 합쳐저야 하는 경우
    2-2(거짓). freshLastBeatIncludedEvent 와 afterStaleFirstBeatIncludedEvent 이 다른 이벤트인 경우
  */
  let updatedEventList = Array<IBeatEvent>();
  if (
    isEqualBothBeatEvent(
      freshFirstBeatIncludedEvent,
      freshLastBeatIncludedEvent
    )
  ) {
    // 1. freshFirstBeatIncludedEvent 와 freshLastBeatIncludedEvent 이 똑같은 경우
    if (
      beforeStaleLastBeatIncludedEvent &&
      freshFirstBeatIncludedEvent &&
      afterStaleFirstBeatIncludedEvent &&
      isSameBothBeatEvent(
        beforeStaleLastBeatIncludedEvent,
        freshFirstBeatIncludedEvent,
        totalBeatList
      ) &&
      isSameBothBeatEvent(
        freshFirstBeatIncludedEvent,
        afterStaleFirstBeatIncludedEvent,
        totalBeatList
      )
    ) {
      //  1-1. beforeStaleLastBeatIncludedEvent, freshFirstBeatIncludedEvent, afterStaleFirstBeatIncludedEvent 모두 합쳐저야 하는 경우
      const firstEventIncludedEdgeBeat =
        (freshFirstBeatIncludedEvent.waveformIndex.at(0) ??
          Number.MIN_SAFE_INTEGER) - 1;
      const secondEventIncludedEdgeBeat =
        freshFirstBeatIncludedEvent.waveformIndex.at(-1) ??
        Number.MAX_SAFE_INTEGER;
      const mergedBeatEvent = getMergedBeatEventInfo(
        beforeStaleLastBeatIncludedEvent,
        firstEventIncludedEdgeBeat,
        freshFirstBeatIncludedEvent,
        secondEventIncludedEdgeBeat,
        afterStaleFirstBeatIncludedEvent
      );
      updatedEventList.push(mergedBeatEvent);
    } else if (
      beforeStaleLastBeatIncludedEvent &&
      freshFirstBeatIncludedEvent &&
      isSameBothBeatEvent(
        beforeStaleLastBeatIncludedEvent,
        freshFirstBeatIncludedEvent,
        totalBeatList
      )
    ) {
      //  1-2. beforeStaleLastBeatIncludedEvent, freshFirstBeatIncludedEvent 합쳐저야 하는 경우
      const firstEventIncludedEdgeBeat =
        (freshFirstBeatIncludedEvent.waveformIndex.at(0) ??
          Number.MIN_SAFE_INTEGER) - 1;
      const mergedBeatEvent = getMergedBeatEventInfo(
        beforeStaleLastBeatIncludedEvent,
        firstEventIncludedEdgeBeat,
        freshFirstBeatIncludedEvent
      );
      const updatedEvent = getUpdatedBeatEvent(
        mergedBeatEvent,
        afterStaleFirstBeatWI,
        false,
        afterStaleRange
      );
      updatedEventList.push(updatedEvent);

      if (afterStaleFirstBeatIncludedEvent) {
        //   1-2-1. afterStaleFirstBeatIncludedEvent 만 있을경우 Onset Marker 정보 업데이트
        const updatedEvent = getUpdatedBeatEvent(
          afterStaleFirstBeatIncludedEvent,
          freshLastBeatWI,
          true,
          freshRange
        );
        updatedEventList.push(updatedEvent);
      }
    } else if (
      freshFirstBeatIncludedEvent &&
      afterStaleFirstBeatIncludedEvent &&
      isSameBothBeatEvent(
        freshFirstBeatIncludedEvent,
        afterStaleFirstBeatIncludedEvent,
        totalBeatList
      )
    ) {
      //  1-3. freshFirstBeatIncludedEvent, afterStaleFirstBeatIncludedEvent 합쳐저야 하는 경우
      const firstEventIncludedEdgeBeat =
        freshFirstBeatIncludedEvent.waveformIndex.at(-1) ??
        Number.MIN_SAFE_INTEGER;
      const mergedBeatEvent = getMergedBeatEventInfo(
        freshFirstBeatIncludedEvent,
        firstEventIncludedEdgeBeat,
        afterStaleFirstBeatIncludedEvent
      );
      const updatedEvent = getUpdatedBeatEvent(
        mergedBeatEvent,
        beforeStaleLastBeatWI,
        true,
        beforeStaleRange
      );
      updatedEventList.push(updatedEvent);

      if (beforeStaleLastBeatIncludedEvent) {
        //   1-3-1. beforeStaleLastBeatIncludedEvent 만 있을경우 Termination Marker 정보 업데이트
        const updatedEvent = getUpdatedBeatEvent(
          beforeStaleLastBeatIncludedEvent,
          freshFirstBeatWI,
          false,
          freshRange
        );
        updatedEventList.push(updatedEvent);
      }
    } else {
      //  1-4. beforeStaleLastBeatIncludedEvent, freshFirstBeatIncludedEvent, afterStaleFirstBeatIncludedEvent 모두 다른 Event인 경우
      if (freshFirstBeatIncludedEvent) {
        const markerUpdatedEvent = getUpdatedBeatEventOnEdgeMarker(
          freshFirstBeatIncludedEvent,
          totalBeatList,
          EventUpdateDirection.BI
        );
        updatedEventList.push(markerUpdatedEvent);
      }

      if (beforeStaleLastBeatIncludedEvent) {
        const updatedEvent = getUpdatedBeatEvent(
          beforeStaleLastBeatIncludedEvent,
          freshFirstBeatWI,
          false,
          freshRange
        );
        updatedEventList.push(updatedEvent);
      }

      if (afterStaleFirstBeatIncludedEvent) {
        const updatedEvent = getUpdatedBeatEvent(
          afterStaleFirstBeatIncludedEvent,
          freshLastBeatWI,
          true,
          freshRange
        );
        updatedEventList.push(updatedEvent);
      }
    }
  } else {
    // 2. freshFirstBeatIncludedEvent 와 freshLastBeatIncludedEvent 이 다른 경우
    if (
      beforeStaleLastBeatIncludedEvent &&
      freshFirstBeatIncludedEvent &&
      isSameBothBeatEvent(
        beforeStaleLastBeatIncludedEvent,
        freshFirstBeatIncludedEvent,
        totalBeatList
      )
    ) {
      //  2-1(참). beforeStaleLastBeatIncludedEvent 와 freshFirstBeatIncludedEvent 이 합쳐저야 하는 경우
      const firstEventIncludedEdgeBeat =
        (freshFirstBeatIncludedEvent.waveformIndex.at(0) ??
          Number.MIN_SAFE_INTEGER) - 1;
      const mergedBeatEvent = getMergedBeatEventInfo(
        beforeStaleLastBeatIncludedEvent,
        firstEventIncludedEdgeBeat,
        freshFirstBeatIncludedEvent
      );
      updatedEventList.push(mergedBeatEvent);
    } else {
      //  2-1(거짓). beforeStaleLastBeatIncludedEvent 와 freshFirstBeatIncludedEvent 이 다른 이벤트인 경우
      if (freshFirstBeatIncludedEvent) {
        const markerUpdatedEvent = getUpdatedBeatEventOnEdgeMarker(
          freshFirstBeatIncludedEvent,
          totalBeatList,
          EventUpdateDirection.PREV
        );
        updatedEventList.push(markerUpdatedEvent);
      }

      if (beforeStaleLastBeatIncludedEvent) {
        const updatedEvent = getUpdatedBeatEvent(
          beforeStaleLastBeatIncludedEvent,
          freshFirstBeatWI,
          false,
          freshRange
        );
        updatedEventList.push(updatedEvent);
      }
    }

    if (
      freshLastBeatIncludedEvent &&
      afterStaleFirstBeatIncludedEvent &&
      isSameBothBeatEvent(
        freshLastBeatIncludedEvent,
        afterStaleFirstBeatIncludedEvent,
        totalBeatList
      )
    ) {
      //  2-2(참). freshLastBeatIncludedEvent 와 afterStaleFirstBeatIncludedEvent 이 합쳐저야 하는 경우
      const firstEventIncludedEdgeBeat =
        freshLastBeatIncludedEvent.waveformIndex.at(-1) ??
        Number.MIN_SAFE_INTEGER;
      const mergedBeatEvent = getMergedBeatEventInfo(
        freshLastBeatIncludedEvent,
        firstEventIncludedEdgeBeat,
        afterStaleFirstBeatIncludedEvent
      );
      updatedEventList.push(mergedBeatEvent);
    } else {
      //  2-2(거짓). freshLastBeatIncludedEvent 와 afterStaleFirstBeatIncludedEvent 이 다른 이벤트인 경우
      if (freshLastBeatIncludedEvent) {
        const markerUpdatedEvent = getUpdatedBeatEventOnEdgeMarker(
          freshLastBeatIncludedEvent,
          totalBeatList,
          EventUpdateDirection.NEXT
        );
        updatedEventList.push(markerUpdatedEvent);
      }

      if (afterStaleFirstBeatIncludedEvent) {
        const updatedEvent = getUpdatedBeatEvent(
          afterStaleFirstBeatIncludedEvent,
          freshLastBeatWI,
          true,
          freshRange
        );
        updatedEventList.push(updatedEvent);
      }
    }
  }

  // Stale 상태의 BeatsNBeatEvents 목록에 Fresh 데이터를 덮어쓰고, 업데이트된 Beat Event 목록을 재할당
  const updatedList = getTunedMergedList(
    staleList,
    freshList,
    updatedEventList
  );

  return updatedList;
}

/** 두 BeatsNBeatEvents 목록을 Fresh List 로 덮어씌우도록 합치고, 각 구간의 Beat Event 정보를 최신화 하여 반환 */
function getTunedMergedList(
  staleList: BeatsNBeatEventsList,
  freshList: BeatsNBeatEventsList,
  updatedBeatEventList: Array<IBeatEvent>
): BeatsNBeatEventsList {
  const resultList = Object.assign({}, staleList, freshList);

  for (let keyWI in resultList) {
    const beatEventList = getVisibleEventListOnStrip(
      resultList[keyWI],
      resultList[keyWI].beatEvents
    );
    const updatedBeatEventSubList = getVisibleEventListOnStrip(
      resultList[keyWI],
      updatedBeatEventList
    );
    resultList[keyWI].beatEvents = getMergedBeatEventLists(
      beatEventList,
      updatedBeatEventSubList
    );

    const { noises, ectopics } = resultList[keyWI].beatEvents.reduce(
      (acc, cur) => {
        if (cur.type === NOISE_TYPE) acc.noises.push(cur);
        else acc.ectopics.push(cur);
        return acc;
      },
      { noises: Array<IBeatEvent>(), ectopics: Array<IBeatEvent>() }
    );
    resultList[keyWI].noises = [...noises];
    resultList[keyWI].ectopics = [...ectopics];
  }

  return resultList;
}

/** 두 Beat Event 목록을 합친 목록을 반환, 단 겹치는 구간이 있는 것이 있을 경우 updatedList 의 것만 목록에 포함 */
function getMergedBeatEventLists(
  notUpdatedList: Array<IBeatEvent>,
  updatedList: Array<IBeatEvent>
): Array<IBeatEvent> {
  if (updatedList.length === 0) return notUpdatedList;
  if (notUpdatedList.length === 0) return updatedList;

  const filteredList = notUpdatedList.filter(
    (nue) =>
      !updatedList.some((ue) =>
        isSomeBeatIncludesOtherList(nue.waveformIndex, ue.waveformIndex)
      )
  );
  const result = [...filteredList, ...updatedList].sort(compareBeatEvents);
  return result;
}

/** 특정 30초 구간에 시각화 될 Beat Event 목록을 반환 */
function getVisibleEventListOnStrip(
  stripInfo: BeatsNBeatEvents,
  beatEventList: Array<IBeatEvent>
): Array<IBeatEvent> {
  const {
    beats: { waveformIndex },
  } = stripInfo;
  const result = beatEventList.filter(
    (value) =>
      isSomeBeatIncludesOtherList(value.waveformIndex, waveformIndex) ||
      isIncludeInRange(stripInfo, value.onsetWaveformIndex) ||
      isIncludeInRange(stripInfo, value.terminationWaveformIndex, true)
  );

  return result;
}

/**
 * 같은 Beat Event 이지만 정보 구성이 상이한 Beat Event 들을 종합하여 반환
 * @param firstEvent secondEvent 가 포함되어 있던 정규 30초 구간보다 앞선 구간에 포함된 Beat Event
 * @param firstEventIncludedEdgeBeat
 * @param secondEvent firstEvent 가 포함되어 있던 정규 30초 구간보다 뒷 구간에 포함된 Beat Event
 * @param secondEventIncludedEdgeBeat
 * @param thirdEvent secondEvent 가 포함되어 있던 정규 30초 구간보다 뒷 구간에 포함된 Beat Event
 * @returns
 */
function getMergedBeatEventInfo(
  firstEvent: IBeatEvent,
  firstEventIncludedEdgeBeat: WaveformIndex,
  secondEvent: IBeatEvent,
  secondEventIncludedEdgeBeat: WaveformIndexOrUndefined = Number.MAX_SAFE_INTEGER,
  thirdEvent?: BeatEventOrUndefined
): IBeatEvent {
  const terminationWaveformIndex = thirdEvent
    ? thirdEvent.terminationWaveformIndex
    : secondEvent.terminationWaveformIndex;
  const hasTerminationMarker = thirdEvent
    ? thirdEvent.hasTerminationMarker
    : secondEvent.hasTerminationMarker;
  const nextExclusive = thirdEvent
    ? thirdEvent.nextExclusive
    : secondEvent.nextExclusive;

  const waveformIndexList = [
    ...firstEvent.waveformIndex.filter(
      (waveformIndex) => waveformIndex <= firstEventIncludedEdgeBeat
    ),
    ...secondEvent.waveformIndex.filter(
      (waveformIndex) =>
        firstEventIncludedEdgeBeat < waveformIndex &&
        waveformIndex <= secondEventIncludedEdgeBeat
    ),
    ...(thirdEvent
      ? thirdEvent.waveformIndex.filter(
          (waveformIndex) => secondEventIncludedEdgeBeat < waveformIndex
        )
      : []),
  ];
  const ectopicType = getEctopicType(waveformIndexList);
  const beatEventType =
    getEventInfoByQuery({
      beatType: firstEvent.beatType,
      ectopicType,
    })?.type ?? NOISE_TYPE;

  const mergedEvent = {
    onsetRPeakIndex: firstEvent.onsetRPeakIndex,
    ectopicType,
    beatType: firstEvent.beatType,
    waveformIndex: waveformIndexList,
    onsetWaveformIndex: firstEvent.onsetWaveformIndex,
    hasOnsetMarker: firstEvent.hasOnsetMarker,
    prevExclusive: firstEvent.prevExclusive,
    terminationWaveformIndex,
    hasTerminationMarker,
    nextExclusive,
    type: beatEventType,
  };
  return mergedEvent;
}

/** Beat Event 의 구성 Beat 가 변경하여 수정된 정보를 반환 */
function getUpdatedBeatEvent(
  staleBeatEvent: IBeatEvent,
  exclusiveBeatWI: WaveformIndexOrUndefined,
  isPrevExclusive: boolean,
  exclusiveRange: Range
): IBeatEvent {
  const waveformIndexList = staleBeatEvent.waveformIndex.filter(
    (waveformIndex) => {
      if (exclusiveBeatWI) {
        if (isPrevExclusive) {
          return exclusiveBeatWI < waveformIndex;
        }
        return waveformIndex < exclusiveBeatWI;
      }
      return !isIncludeInRange(exclusiveRange, waveformIndex);
    }
  );
  const ectopicType = getEctopicType(waveformIndexList);
  const beatEventType =
    getEventInfoByQuery({
      beatType: staleBeatEvent.beatType,
      ectopicType,
    })?.type ?? NOISE_TYPE;

  const exclusiveInfo = isPrevExclusive
    ? staleBeatEvent.prevExclusive
    : staleBeatEvent.nextExclusive;
  const updatedMarkerValue = getUpdatedMarkerValue(
    exclusiveBeatWI,
    isPrevExclusive,
    waveformIndexList,
    exclusiveInfo
  );

  const updatedEvent = {
    onsetRPeakIndex: waveformIndexList[0],
    ectopicType,
    beatType: staleBeatEvent.beatType,
    waveformIndex: waveformIndexList,
    type: beatEventType,
    //
    onsetWaveformIndex: staleBeatEvent.onsetWaveformIndex,
    hasOnsetMarker: staleBeatEvent.hasOnsetMarker,
    prevExclusive: staleBeatEvent.prevExclusive,
    terminationWaveformIndex: staleBeatEvent.terminationWaveformIndex,
    hasTerminationMarker: staleBeatEvent.hasTerminationMarker,
    nextExclusive: staleBeatEvent.nextExclusive,
    ...updatedMarkerValue,
  };
  return updatedEvent;
}

function getUpdatedMarkerValue(
  exclusiveBeatWI: WaveformIndexOrUndefined,
  isPrevExclusive: boolean,
  BeatEventWaveformIndexList: Array<WaveformIndex>,
  staleExclusiveRangeData: RangeOrUndefined
):
  | {}
  | {
      onsetWaveformIndex: WaveformIndexOrUndefined;
      hasOnsetMarker: boolean;
      prevExclusive: RangeOrUndefined;
    }
  | {
      terminationWaveformIndex: WaveformIndexOrUndefined;
      hasTerminationMarker: boolean;
      nextExclusive: RangeOrUndefined;
    } {
  if (!exclusiveBeatWI) return {};

  let result:
    | {
        onsetWaveformIndex: WaveformIndexOrUndefined;
        hasOnsetMarker: boolean;
        prevExclusive: RangeOrUndefined;
      }
    | {
        terminationWaveformIndex: WaveformIndexOrUndefined;
        hasTerminationMarker: boolean;
        nextExclusive: RangeOrUndefined;
      };
  let typeChangedRange;
  if (isPrevExclusive) {
    const firstBeatWI =
      BeatEventWaveformIndexList.at(0) ?? INITIAL_ONSET_MARKER_WAVEFORM_INDEX;

    typeChangedRange = {
      onsetWaveformIndex: exclusiveBeatWI,
      terminationWaveformIndex: firstBeatWI,
    };
    const {
      markerWaveformIndex: onsetWaveformIndex,
      hasMarker: hasOnsetMarker,
    } = getEdgeMarkerValues(typeChangedRange);
    const staleExclusiveEdge =
      staleExclusiveRangeData?.terminationWaveformIndex ?? firstBeatWI;
    if (
      staleExclusiveRangeData &&
      (!onsetWaveformIndex ||
        (onsetWaveformIndex < staleExclusiveEdge &&
          staleExclusiveEdge < firstBeatWI))
    ) {
      result = {
        onsetWaveformIndex: staleExclusiveEdge,
        hasOnsetMarker: true,
        prevExclusive: staleExclusiveRangeData,
      };
      return result;
    }

    result = {
      onsetWaveformIndex,
      hasOnsetMarker,
      prevExclusive: undefined,
    };
    return result;
  } else {
    const lastBeatWI =
      BeatEventWaveformIndexList.at(-1) ??
      INITIAL_TERMINATION_MARKER_WAVEFORM_INDEX;

    typeChangedRange = {
      onsetWaveformIndex: lastBeatWI,
      terminationWaveformIndex: exclusiveBeatWI,
    };
    const {
      markerWaveformIndex: terminationWaveformIndex,
      hasMarker: hasTerminationMarker,
    } = getEdgeMarkerValues(typeChangedRange);
    const staleExclusiveEdge =
      staleExclusiveRangeData?.onsetWaveformIndex ?? lastBeatWI;
    if (
      staleExclusiveRangeData &&
      (!terminationWaveformIndex ||
        (lastBeatWI < staleExclusiveEdge &&
          staleExclusiveEdge < terminationWaveformIndex))
    ) {
      result = {
        terminationWaveformIndex: staleExclusiveEdge,
        hasTerminationMarker: true,
        nextExclusive: staleExclusiveRangeData,
      };
      return result;
    }

    result = {
      terminationWaveformIndex,
      hasTerminationMarker,
      nextExclusive: undefined,
    };
    return result;
  }
}

/**
 * Beat Event 의 Marker 위치 정보를 업데이트
 *
 * case 1. Fresh 구간 전체를 커버하는 하나의 Beat Event 의 양쪽 Marker 업데이트
 * case 2. Fresh 구간 시작 지점을 커버하는 Beat Event 의 Onset Marker 업데이트
 * case 3. Fresh 구간 종료 지점을 커버하는 Beat Event 의 Termination Marker 업데이트
 * @param beatEvent
 * @param totalBeatList
 * @param updateType
 * @returns
 */
function getUpdatedBeatEventOnEdgeMarker(
  beatEvent: IBeatEvent,
  totalBeatList: Array<WaveformIndex>,
  updateType: EventUpdateDirection
): IBeatEvent {
  let updatedBeatEvent = { ...beatEvent };

  let onsetWaveformIndex: WaveformIndexOrUndefined =
    INITIAL_ONSET_MARKER_WAVEFORM_INDEX;
  let hasOnsetMarker = false;
  const firstBeatWI =
    updatedBeatEvent.waveformIndex.at(0) ?? Number.MIN_SAFE_INTEGER;
  const firstBeatIndex = totalBeatList.findIndex(
    (waveformIndex) => waveformIndex === firstBeatWI
  );
  if (hasPrevItem(firstBeatIndex)) {
    const prevExclusiveBeatWI = totalBeatList[firstBeatIndex - 1];
    const { markerWaveformIndex, hasMarker } = getEdgeMarkerValues({
      onsetWaveformIndex: prevExclusiveBeatWI,
      terminationWaveformIndex: firstBeatWI,
    });
    onsetWaveformIndex = markerWaveformIndex;
    hasOnsetMarker = hasMarker;
  }

  let terminationWaveformIndex: WaveformIndexOrUndefined =
    INITIAL_TERMINATION_MARKER_WAVEFORM_INDEX;
  let hasTerminationMarker = false;
  const lastBeatWI =
    updatedBeatEvent.waveformIndex.at(-1) ?? Number.MAX_SAFE_INTEGER;
  const lastBeatIndex = totalBeatList.findIndex(
    (waveformIndex) => waveformIndex === lastBeatWI
  );
  if (hasNextItem(lastBeatIndex, totalBeatList.length)) {
    const nextExclusiveBeatWI = totalBeatList[lastBeatIndex + 1];
    const { markerWaveformIndex, hasMarker } = getEdgeMarkerValues({
      onsetWaveformIndex: lastBeatWI,
      terminationWaveformIndex: nextExclusiveBeatWI,
    });
    terminationWaveformIndex = markerWaveformIndex;
    hasTerminationMarker = hasMarker;
  }

  if (
    updateType === EventUpdateDirection.PREV &&
    !updatedBeatEvent.prevExclusive
  ) {
    updatedBeatEvent.onsetWaveformIndex = onsetWaveformIndex;
    updatedBeatEvent.hasOnsetMarker = hasOnsetMarker;
  }
  if (
    updateType === EventUpdateDirection.NEXT &&
    !updatedBeatEvent.nextExclusive
  ) {
    updatedBeatEvent.terminationWaveformIndex = terminationWaveformIndex;
    updatedBeatEvent.hasTerminationMarker = hasTerminationMarker;
  }
  if (updateType === EventUpdateDirection.BI) {
    if (!updatedBeatEvent.prevExclusive) {
      updatedBeatEvent.onsetWaveformIndex = onsetWaveformIndex;
      updatedBeatEvent.hasOnsetMarker = hasOnsetMarker;
    }
    if (!updatedBeatEvent.nextExclusive) {
      updatedBeatEvent.terminationWaveformIndex = terminationWaveformIndex;
      updatedBeatEvent.hasTerminationMarker = hasTerminationMarker;
    }
  }
  return updatedBeatEvent;
}

/** 두 Beat Event 가 완전히 똑같을 경우 True 반환 */
function isEqualBothBeatEvent(
  leftEvent: BeatEventOrUndefined,
  rightEvent: BeatEventOrUndefined
) {
  if (!(leftEvent && rightEvent)) return false;

  const result =
    rightEvent.onsetRPeakIndex === leftEvent.onsetRPeakIndex &&
    rightEvent.ectopicType === leftEvent.ectopicType &&
    rightEvent.beatType === leftEvent.beatType &&
    rightEvent.waveformIndex.at(0) === leftEvent.waveformIndex.at(0) &&
    rightEvent.waveformIndex.at(-1) === leftEvent.waveformIndex.at(-1);
  return result;
}

/** 두 Beat Event 의 정보 구성은 다르지만, 정황상 동일할 경우 True 반환 */
function isSameBothBeatEvent(
  beforeEvent: BeatEventOrUndefined,
  afterEvent: BeatEventOrUndefined,
  totalBeatList: Array<WaveformIndex>
) {
  if (!(beforeEvent && afterEvent)) return false;

  const result =
    beforeEvent.beatType === afterEvent.beatType &&
    !(beforeEvent.nextExclusive || afterEvent.prevExclusive) &&
    isContinueBothEvent(beforeEvent, afterEvent, totalBeatList);
  return result;
}

/** 두 Beat Event 가 정보 구성 상 연속되는 Beat Event 인지 여부 반환 */
function isContinueBothEvent(
  beforeEvent: BeatEventOrUndefined,
  afterEvent: BeatEventOrUndefined,
  totalBeatList: Array<WaveformIndex>
) {
  if (
    !(beforeEvent && afterEvent) ||
    (beforeEvent.nextExclusive && afterEvent.prevExclusive)
  )
    return false;

  const beforeLastBeatIndex = totalBeatList.findIndex(
    (waveformIndex) => waveformIndex === beforeEvent.waveformIndex.at(-1)
  );
  const beforeNextBeatWI = [totalBeatList[beforeLastBeatIndex + 1]].filter(
    (value) => value !== undefined
  );

  const afterFirstBeatIndex = totalBeatList.findIndex(
    (waveformIndex) => waveformIndex === afterEvent.waveformIndex.at(0)
  );
  const afterPrevBeatWI = [totalBeatList[afterFirstBeatIndex - 1]].filter(
    (value) => value !== undefined
  );

  const beforeExtendedWaveformIndexList = [
    ...beforeEvent.waveformIndex,
    ...beforeNextBeatWI,
  ];
  const afterExtendedWaveformIndexList = [
    ...afterPrevBeatWI,
    ...afterEvent.waveformIndex,
  ];

  const result = isSomeBeatIncludesOtherList(
    beforeExtendedWaveformIndexList,
    afterExtendedWaveformIndexList
  );
  return result;
}

/**
 * 정규 30초 구간정보인 BeatsNBeatEvents 의 목록에서 특정 Beat 가 포함된 Beat Event 정보를 반환
 * @param beatsNBeatEventsList Beat Event 를 찾을 BeatsNBeatEvents 배열
 * @param beatWaveformIndex 검색하는 Beat Event 에 포함되는 Beat 의 위치
 * @returns 검색된 Beat Event, 검색된 것이 없다면 undefined
 */
function getSomeBeatIncludedEvent(
  beatsNBeatEventsList: BeatsNBeatEventsList,
  beatWaveformIndex: WaveformIndexOrUndefined
): BeatEventOrUndefined {
  if (beatWaveformIndex === undefined) return undefined;

  const keyWI = getKeyWaveformIndex(beatWaveformIndex);
  const beatEvent = beatsNBeatEventsList[keyWI].beatEvents.find((beatEvent) =>
    beatEvent.waveformIndex.includes(beatWaveformIndex)
  );

  return beatEvent;
}

/** Beat Event 탐색 및 수정을 위한 주요 Beat 위치와 전체 Beat 목록 반환 */
function getCoreValues(
  staleList: BeatsNBeatEventsList,
  freshList: BeatsNBeatEventsList
) {
  let beforeStaleBeatList = Array<WaveformIndex>();
  let freshBeatList = Array<WaveformIndex>();
  let afterStaleBeatList = Array<WaveformIndex>();

  const freshKeyList = getSortedKeyList(freshList);
  getMergedWaveformIndexList(getSortedKeyList(staleList), freshKeyList).forEach(
    (key) => {
      if (freshKeyList.includes(key)) {
        freshBeatList = [
          ...freshBeatList,
          ...freshList[key].beats.waveformIndex,
        ];
      } else {
        if (freshKeyList[0] < key) {
          afterStaleBeatList = [
            ...afterStaleBeatList,
            ...staleList[key].beats.waveformIndex,
          ];
        } else {
          beforeStaleBeatList = [
            ...beforeStaleBeatList,
            ...staleList[key].beats.waveformIndex,
          ];
        }
      }
    }
  );
  const freshRange = {
    onsetWaveformIndex: freshKeyList.at(0) ?? 0,
    terminationWaveformIndex:
      (freshKeyList.at(-1) ?? 0) + THIRTY_SEC_WAVEFORM_LENGTH,
  };

  const result = {
    totalBeatList: [
      ...beforeStaleBeatList,
      ...freshBeatList,
      ...afterStaleBeatList,
    ],
    beforeStaleLastBeatWI: beforeStaleBeatList.at(-1),
    freshFirstBeatWI: freshBeatList.at(0),
    freshLastBeatWI: freshBeatList.at(-1),
    afterStaleFirstBeatWI: afterStaleBeatList.at(0),
    beforeStaleRange: {
      onsetWaveformIndex: 0,
      terminationWaveformIndex: freshRange.onsetWaveformIndex,
    },
    freshRange,
    afterStaleRange: {
      onsetWaveformIndex: freshRange.terminationWaveformIndex,
      terminationWaveformIndex:
        afterStaleBeatList.at(-1) ?? freshRange.terminationWaveformIndex,
    },
  };

  return result;
}

function getSortedKeyList<V>(object: {
  [x: WaveformIndex]: V;
}): Array<WaveformIndex> {
  const result = Object.keys(object)
    .map((value) => Number.parseInt(value, 10))
    .sort((a, b) => a - b);
  return result;
}

function getMergedWaveformIndexList(
  leftList: Array<WaveformIndex>,
  rightList: Array<WaveformIndex>
): Array<WaveformIndex> {
  let mergedList = [...leftList];
  rightList.forEach((waveformIndex) => {
    if (!mergedList.includes(waveformIndex)) {
      mergedList = [...mergedList, waveformIndex];
    }
  });
  const result = mergedList.sort((a, b) => a - b);
  return result;
}

function isIncludeInRange(
  someRange: Range,
  targetWaveformIndex: WaveformIndexOrUndefined,
  isTerminationClose?: boolean
) {
  if (!targetWaveformIndex) return false;
  if (isTerminationClose) {
    const result =
      someRange.onsetWaveformIndex <= targetWaveformIndex &&
      targetWaveformIndex <= someRange.terminationWaveformIndex;
    return result;
  }
  const result =
    someRange.onsetWaveformIndex <= targetWaveformIndex &&
    targetWaveformIndex < someRange.terminationWaveformIndex;
  return result;
}

/** Parameter 로 전달 받은 Item 의 Index 를 기준으로, 직전 Item 이 존재하는지 여부 반환 */
function hasPrevItem(targetItemIndex: number) {
  const result = 0 <= targetItemIndex - 1;
  return result;
}

/** Parameter 로 전달 받은 Item 의 Index 를 기준으로, 직후 Item 이 존재하는지 여부 반환 */
function hasNextItem(targetItemIndex: number, listLength: number) {
  const result = targetItemIndex + 1 < listLength;
  return result;
}
