export const PARCEL_STATUS = {
  NONE: { text: '예약하기', value: null },
  RECEIPT_AWAITING: { text: '예약 완료', value: 'RECEIPT_AWAITING' },
  PICK_UP_AWAITING: { text: '예약 완료', value: 'PICK_UP_AWAITING' },
  UPLOAD_AWAITING: { text: '기기 반납 완료', value: 'UPLOAD_AWAITING' },
  DEVICE_RETURN_AWAITING: {
    text: '기기 발송 준비',
    value: 'DEVICE_RETURN_AWAITING',
  },
  DEVICE_RETURN_COMPLETE: {
    text: '기기 발송 완료',
    value: 'DEVICE_RETURN_COMPLETE',
  },
  CANCELED: { text: '예약 취소', value: 'CANCELED' },
};

export interface Parcel {
  id: Id;
  status: Status;
  pickUpDate: PickUpDate;
  senderName: SenderName;
  senderPhoneNumber: SenderPhoneNumber;
  note: Note;
  address1: Address1;
  address2: Address2;
  postcode: Postcode;
  createdAt: CreatedAt;
  updatedAt: UpdatedAt;
}

export type Id = number | null;
export type Status = keyof typeof PARCEL_STATUS | null;
export type PickUpDate = string | null;
export type SenderName = string | null;
export type SenderPhoneNumber = string | null;
export type Note = string | null;
export type Address1 = string | null;
export type Address2 = string | null;
export type Postcode = string | null;
export type CreatedAt = string | null;
export type UpdatedAt = string | null;
