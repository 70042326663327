import {
  call,
  put,
  takeLatest,
  select,
  debounce,
  all,
  takeEvery,
  delay,
} from 'redux-saga/effects';
import axios from 'axios';
import rfdc from 'rfdc';

import Const from 'constant/Const';
import {
  BASIS_AMPLITUDE_RATE,
  REPORT_EVENT_EDITOR_STEP_MAP,
  STRIP_TYPE_MAP,
} from 'constant/ReportConst';
import {
  AMPLITUDE_OPTION,
  ECG_CHART_UNIT,
  SELECTION_MARKER_TYPE,
  TEN_SEC_SCRIPT_DETAIL,
  TEN_SEC_STRIP,
  TEN_SEC_STRIP_DETAIL,
  TEN_SEC_STRIP_EDIT,
} from 'constant/ChartEditConst';
import {
  EVENT_CONST_TYPES,
  EVENT_GROUP_TYPE,
  BEAT_TYPE,
  TIME_EVENT_TYPE,
  REPORT_SECTION,
  AV_BLOCK_LIST,
} from 'constant/EventConst';
import { INFINITY_SCROLL } from 'constant/InfinityScrollConst';
import {
  AF_SORT_DEFAULT,
  PAUSE_SORT_DEFAULT,
  SVT_SORT_DEFAULT,
  VT_SORT_DEFAULT,
  OTHERS_SORT_DEFAULT,
  APC_SORT_DEFAULT,
  VPC_SORT_DEFAULT,
  PTE_SORT_DEFAULT,
} from 'constant/SortConst';
import LocalStorageKey from 'constant/LocalStorageKey';

import DateUtil from 'util/DateUtil';
import {
  transformRawList,
  transformTimeEvents,
  getInitRepresentativeStripInfo,
  getSearchBeatsNEctopicListRangeAfterUpdateEvent,
  _getTenSecStripInfo,
  _getFilterBeatsNEctopicList,
  mergeLeadOffInfo,
  _getBeatLabelButtonDataList,
  getLocalCenterWaveformIndex,
  getRepresentativeCenterWaveformIndex,
  getOnsetTerminationByCenter,
  getIsRawDataOnly,
} from 'util/reduxDuck/TestResultDuckUtil';
import {
  getEventInfoByQuery,
  getEventInfoByType,
  getSidePanelEventData,
} from 'util/EventConstUtil';
import ChartUtil, { getTenSecStripParam } from 'util/ChartUtil';
import { validateBeatEditResponse } from 'util/validation/ValidateBeatsEdit';
import { postProcessEditedTimeEvent } from 'util/postProcesses/PostProcessTimeEventEdit';
import {
  selectFilteredEpisodeOrLeadOffList,
  getOverlapRangeFilter,
  getBeatsNBeatEventsList,
  getTotalFreshBeatsNBeatEventsList,
} from 'util/BeatUtil';
import { preProcessEditedTimeEvent } from 'util/preProcesses/PreProcessTimeEventEdit';
import { getTenSecAvgHrByFromTo } from 'util/StripDataUtil';
import { isNotNullOrUndefined, optionalParameter } from 'util/Utility';

import StatusCode from 'network/StatusCode';
import ApiManager from 'network/ApiManager/ApiManager';

import LocalStorageManager from 'manager/LocalStorageManager';

import { resetHrReviewState } from './hrReviewDuck';
import {
  resetBeatReviewState,
  patchBeatPostprocessRequested,
} from './beatReviewDuck';
import { enqueueRequest } from './beatsRequestQueueDuck';
import {
  selectFetchData,
  setReportDownloadStatusCheckSucceed,
} from './ecgTestList/ecgTestListDuck';

const rfdcClone = rfdc();

// Selector
// Actions
// etc function
// Reducer
// Action Creators
// Saga functions
// Saga
const DEBUGGING_LOG_ECG_RAW = 0;
const storedThirtySecAmplitudeRate = LocalStorageManager.getItem(
  LocalStorageKey.LAST_VIEWED_TID_STATE
)?.thirtySecAmplitudeRate;

// Selector
export const selectEcgTestId = (state) => state.testResultReducer.ecgTestId;
export const selectReportId = (state) =>
  state.testResultReducer.ecgTest.data?.latestReport?.rid;
export const selectRecordingTime = (state) =>
  state.testResultReducer.recordingTime;
export const selectSideTabValue = ({ testResultReducer: state }) =>
  state.eventReview.sidePanelState.tabValue;
export const selectSelectedValueList = ({ testResultReducer: state }) =>
  state.eventReview.sidePanelState.selectedValueList;
export const selectEventReviewSortOrder = ({ testResultReducer: state }) =>
  state.eventReview.sortOrder;
export const selectSelectionStrip = ({ testResultReducer: state }) =>
  state.eventReview.selectionStrip;
const selectEcgTestStatus = (state) =>
  state.testResultReducer.ecgTest.data?.ecgTestStatus;
export const selectIsRawDataOnly = (state) =>
  getIsRawDataOnly({
    isRawDataOnly: state.testResultReducer.ecgTest.data?.isRawDataOnly,
    isRawDataReady: state.testResultReducer.ecgTest.data?.isRawDataReady,
    ecgTestStatus: selectEcgTestStatus(state),
  });
export const selectEcgRawList = (state) =>
  state.testResultReducer.ecgRaw.ecgRawList;
const getTenSecStrip = (state) =>
  state.testResultReducer.eventReview.tenSecStrip;
export const selectBeatsNEctopicList = (state) =>
  state.testResultReducer.beatsNEctopicList.data;
/** @returns {import('constant/ReportConst').ReportEventEditorState} ReportEventEditor 모듈의 Global State */
export const selectReportEventEditorState = (state) =>
  state.testResultReducer.eventReview.reportEventEditor;
export const selectReportEventEditorStep = (state) =>
  state.testResultReducer.eventReview.reportEventEditor.editorStep;
/** 리포트 담기 중 대표 Strip 선택 가능 상황 */
export const selectIsSelectableRepresentativeStrip = (state) =>
  selectReportEventEditorState(state).editorStep ===
  REPORT_EVENT_EDITOR_STEP_MAP.TITLE;
/** Events 텝에서 전체 이벤트 조회 가능 상황 */
export const selectIsSelectableChart = (state) =>
  state.testResultReducer.eventReview.sidePanelState.tabValue ===
    EVENT_GROUP_TYPE.EVENTS &&
  selectReportEventEditorState(state).editorStep ===
    REPORT_EVENT_EDITOR_STEP_MAP.CANCEL;
/** 선택된 Event Marker 의 Highlight 에서 Context Menu 가 열린 상황 */
export const selectIsWholeUnMark = (state) =>
  Boolean(
    state.testResultReducer.eventReview.isOpenArrhythmiaContextmenu &&
      selectSelectedValueList(state).length === 1 &&
      !state.testResultReducer.eventDetail.pending &&
      state.testResultReducer.eventDetail.data
  );
export const selectTimeEventList = (state, eventType) =>
  state.testResultReducer.timeEventsList.data.filter((value) =>
    Boolean(eventType) ? value.type === eventType : true
  );
export const selectThirtySecAmplitudeRate = (state) =>
  state.testResultReducer.eventReview.thirtySecAmplitudeRate;
export const selectEventDetail = (state) => state.testResultReducer.eventDetail;

// Actions
// Global State Actions
const INITIALIZE = 'memo-web/test-result/INITIALIZE';
// EntireEcgFragment Data
const SET_NAVIGATOR_TIMESTAMP =
  'memo-web/test-result/event/SET_NAVIGATOR_TIMESTAMP';
const SET_HR_HIGHLIGHT_TIMESTAMP =
  'memo-web/test-result/event/SET_HR_HIGHLIGHT';
// Manage Side Panel State
const SET_SIDE_PANEL_TAB_VALUE =
  'memo-web/test-result/event/SET_SIDE_PANEL_TAB_VALUE';
const SET_SIDE_PANEL_SELECTED_VALUE_LIST =
  'memo-web/test-result/event/SET_SIDE_PANEL_SELECTED_VALUE_LIST';
// Representative Strip State Management
const SET_REPRESENTATIVE_STRIP_INFO =
  'memo-web/test-result/event/SET_REPRESENTATIVE_STRIP_INFO';
const RESET_REPRESENTATIVE_STRIP_INFO =
  'memo-web/test-result/event/RESET_REPRESENTATIVE_STRIP_INFO';
const SET_CHART_SELECTED_STRIP =
  'memo-web/test-result/SET_CHART_SELECTED_STRIP';
// Report Event Editor Management
const SET_REPORT_EVENT_EDITOR_START =
  'memo-web/test-result/SET_REPORT_EVENT_EDITOR_START';
const SET_REPORT_EVENT_EDITOR_CLOSE =
  'memo-web/test-result/SET_REPORT_EVENT_EDITOR_CLOSE';
const SET_REPORT_EVENT_EDITOR_NEW_STATE =
  'memo-web/test-result/SET_REPORT_EVENT_EDITOR_NEW_STATE';
const SET_BASIC_LEAD_OFF = 'memo-web/test-result/SET_BASIC_LEAD_OFF';
const SET_SORT_ORDER = 'memo-web/test-result/SET_SORT_ORDER';
const SET_THIRTY_SEC_AMPLITUDE_RATE =
  'memo-web/test-result/SET_THIRTY_SEC_AMPLITUDE_RATE';

// Server State Actions
// Get ECG Test
const GET_ECG_TEST_REQUESTED = 'memo-web/test-result/GET_ECG_TEST_REQUESTED';
const GET_ECG_TEST_SUCCEED = 'memo-web/test-result/GET_ECG_TEST_SUCCEED';
const GET_ECG_TEST_FAILED = 'memo-web/test-result/GET_ECG_TEST_FAILED';
// Patch
const PATCH_ECG_TEST_REQUESTED =
  'memo-web/test-result/PATCH_ECG_TEST_REQUESTED';
const PATCH_ECG_TEST_SUCCEED = 'memo-web/test-result/PATCH_ECG_TEST_SUCCEED';
const PATCH_ECG_TEST_FAILED = 'memo-web/test-result/PATCH_ECG_TEST_FAILED';
// Get single events
const GET_EVENT_DETAIL_REQUESTED =
  'memo-web/test-result/GET_EVENT_DETAIL_REQUESTED';
const GET_EVENT_DETAIL_SUCCEED =
  'memo-web/test-result/GET_EVENT_DETAIL_SUCCEED';
const GET_EVENT_DETAIL_FAILED = 'memo-web/test-result/GET_EVENT_DETAIL_FAILED';
const SET_EVENT_DETAIL_EDITED = 'memo-web/test-result/SET_EVENT_DETAIL_EDITED';
const SET_EVENT_DETAIL_PEND_EDIT =
  'memo-web/test-result/SET_EVENT_DETAIL_PEND_EDIT';
// Get & Post & Delete & Update report events
const GET_REPORT_EVENTS_REQUESTED =
  'memo-web/test-result/GET_REPORT_EVENTS_REQUESTED';
const GET_REPORT_EVENTS_SUCCEED =
  'memo-web/test-result/GET_REPORT_EVENTS_SUCCEED';
const GET_REPORT_EVENTS_FAILED =
  'memo-web/test-result/GET_REPORT_EVENTS_FAILED';
const POST_REPORT_EVENT_REQUESTED =
  'memo-web/test-result/POST_REPORT_EVENT_REQUESTED';
const POST_REPORT_EVENT_SUCCEED =
  'memo-web/test-result/POST_REPORT_EVENT_SUCCEED';
const POST_REPORT_EVENT_FAILED =
  'memo-web/test-result/POST_REPORT_EVENT_FAILED';
const UPDATE_REPORT_EVENT_REQUESTED =
  'memo-web/test-result/UPDATE_REPORT_EVENT_REQUESTED';
const UPDATE_REPORT_EVENT_SUCCEED =
  'memo-web/test-result/UPDATE_REPORT_EVENT_SUCCEED';
const UPDATE_REPORT_EVENT_FAILED =
  'memo-web/test-result/UPDATE_REPORT_EVENT_FAILED';
const DELETE_REPORT_EVENT_REQUESTED =
  'memo-web/test-result/DELETE_REPORT_EVENT_REQUESTED';
const DELETE_REPORT_EVENT_SUCCEED =
  'memo-web/test-result/DELETE_REPORT_EVENT_SUCCEED';
const DELETE_REPORT_EVENT_FAILED =
  'memo-web/test-result/DELETE_REPORT_EVENT_FAILED';
const GET_NEXT_REPORT_EVENT = 'memo-web/test-result/GET_NEXT_REPORT_EVENT';

// PTE Report Info Update
const UPDATE_PTE_REPORT_INFO_REQUESTED =
  'memo-web/test-result/UPDATE_PTE_REPORT_INFO_REQUESTED';
const UPDATE_PTE_REPORT_INFO_SUCCEED =
  'memo-web/test-result/UPDATE_PTE_REPORT_INFO_SUCCEED';
const UPDATE_PTE_REPORT_INFO_FAILED =
  'memo-web/test-result/UPDATE_PTE_REPORT_INFO_FAILED';

// GET ECG Statistics data
const GET_ECGS_STATISTICS_REQUESTED =
  'memo-web/test-result/GET_ECGS_STATISTICS_REQUESTED';
const GET_ECGS_STATISTICS_SUCCEED =
  'memo-web/test-result/GET_ECGS_STATISTICS_SUCCEED';
const GET_ECGS_STATISTICS_FAILED =
  'memo-web/test-result/GET_ECGS_STATISTICS_FAILED';

// GET Report Statistics data
const GET_REPORTS_STATISTICS_REQUESTED =
  'memo-web/test-result/GET_REPORTS_STATISTICS_REQUESTED';
const GET_REPORTS_STATISTICS_SUCCEED =
  'memo-web/test-result/GET_REPORTS_STATISTICS_SUCCEED';
const GET_REPORTS_STATISTICS_FAILED =
  'memo-web/test-result/GET_REPORTS_STATISTICS_FAILED';

// Get All Statistics data
const GET_BOTH_STATISTICS_DELEGATED =
  'memo-web/test-result/GET_BOTH_STATISTICS_DELEGATED';
const GET_ALL_STATISTICS_REQUESTED =
  'memo-web/test-result/GET_ALL_STATISTICS_REQUESTED';
const GET_ALL_STATISTICS_SUCCEED =
  'memo-web/test-result/GET_ALL_STATISTICS_SUCCEED';
const GET_ALL_STATISTICS_FAILED =
  'memo-web/test-result/GET_ALL_STATISTICS_FAILED';

// Adjust Statistics Count
const ADJUST_ECGS_STATISTICS = 'memo-web/test-result/ADJUST_ECGS_STATISTICS';
const ADJUST_REPORTS_STATISTICS =
  'memo-web/test-result/ADJUST_REPORTS_STATISTICS';

// Get entire events
const GET_TIME_EVENTS_LIST_REQUESTED =
  'memo-web/test-result/GET_TIME_EVENTS_LIST_REQUESTED';
const GET_TIME_EVENTS_LIST_SUCCEED =
  'memo-web/test-result/GET_TIME_EVENTS_LIST_SUCCEED';
const GET_TIME_EVENTS_LIST_FAILED =
  'memo-web/test-result/GET_TIME_EVENTS_LIST_FAILED';
const GET_BEATS_N_ECTOPIC_LIST_REQUESTED =
  'memo-web/test-result/GET_BEATS_N_ECTOPIC_LIST_REQUESTED';
const GET_BEATS_N_ECTOPIC_LIST_SUCCEED =
  'memo-web/test-result/GET_BEATS_N_ECTOPIC_LIST_SUCCEED';
const GET_BEATS_N_ECTOPIC_LIST_FAILED =
  'memo-web/test-result/GET_BEATS_N_ECTOPIC_LIST_FAILED';

// Get daily heart rate
const GET_DAILY_HEART_RATE_REQUESTED =
  'memo-web/test-result/GET_DAILY_HEART_RATE_REQUESTED';
const GET_DAILY_HEART_RATE_SUCCEED =
  'memo-web/test-result/GET_DAILY_HEART_RATE_SUCCEED';
const GET_DAILY_HEART_RATE_FAILED =
  'memo-web/test-result/GET_DAILY_HEART_RATE_FAILED';
const SET_PATIENT_TRIGGERED_EVENT_LIST =
  'memo-web/test-result/SET_PATIENT_TRIGGERED_EVENT_LIST';

// Get ecgRaw data
const GET_ECGRAW_INIT_REQUESTED =
  'memo-web/test-result/GET_ECGRAW_INIT_REQUESTED';
const GET_ECGRAW_BACKWARD_REQUESTED =
  'memo-web/test-result/GET_ECGRAW_BACKWARD_REQUESTED';
const GET_ECGRAW_FORWARD_REQUESTED =
  'memo-web/test-result/GET_ECGRAW_FORWARD_REQUESTED';
const GET_ECGRAW_REQUESTED = 'memo-web/test-result/GET_ECGRAW_REQUESTED';
const GET_ECGRAW_INIT_SUCCEED = 'memo-web/test-result/GET_ECGRAW_INIT_SUCCEED';
const GET_ECGRAW_SUCCEED = 'memo-web/test-result/GET_ECGRAW_SUCCEED';
const GET_ECGRAW_FAILED = 'memo-web/test-result/GET_ECGRAW_FAILED';

// Arrhythmia Edit
const SET_SELECTION_STRIP = 'memo-web/test-result/SET_SELECTION_STRIP';
const SET_TENSEC_STRIP = 'memo-web/test-result/SET_TENSEC_STRIP';
const SET_TENSEC_STRIP_DETAIL = 'memo-web/test-result/SET_TENSEC_STRIP_DETAIL';
const SET_ARRHYTHMIA_CONTEXTMENU =
  'memo-web/test-result/SET_ARRHYTHMIA_CONTEXTMENU';
const SET_BEAT_CONTEXTMENU = 'memo-web/test-result/SET_BEAT_CONTEXTMENU';

// ECG chart list - setting
const SET_ECGCHARTLIST_SCROLL_TOP =
  'memo-web/test-result/SET_ECGCHARTLIST_SCROLL_TOP';
const SET_IS_ECGCHARTLIST_SCROLL_TO_INDEX =
  'memo-web/test-result/SET_IS_ECGCHARTLIST_SCROLL_TO_INDEX';

// post beats
const POST_BEATS_REQUESTED = 'memo-web/event-review/POST_BEATS_REQUESTED';
const POST_BEATS_SUCCEED = 'memo-web/event-review/POST_BEATS_SUCCEED';
const POST_BEATS_FAILED = 'memo-web/event-review/POST_BEATS_FAILED';

// patch beats
const PATCH_BEATS_REQUESTED = 'memo-web/event-review/PATCH_BEATS_REQUESTED';
const PATCH_BEATS_SUCCEED = 'memo-web/event-review/PATCH_BEATS_SUCCEED';
const PATCH_BEATS_FAILED = 'memo-web/event-review/PATCH_BEATS_FAILED';

// delete beats
const DELETE_BEATS_REQUESTED = 'memo-web/event-review/DELETE_BEATS_REQUESTED';
const DELETE_BEATS_SUCCEED = 'memo-web/event-review/DELETE_BEATS_SUCCEED';
const DELETE_BEATS_FAILED = 'memo-web/event-review/DELETE_BEATS_FAILED';

// post time events
const POST_TIME_EVENT_REQUESTED =
  'memo-web/time-event/POST_TIME_EVENT_REQUESTED';
const POST_TIME_EVENT_SUCCEED = 'memo-web/time-event/POST_TIME_EVENT_SUCCEED';
const POST_TIME_EVENT_FAILED = 'memo-web/time-event/POST_TIME_EVENT_FAILED';

// Request Print Report
const REQUEST_PRINT_REPORT_REQUESTED =
  'memo-web/test-result/REQUEST_PRINT_REPORT_REQUESTED';
const REQUEST_PRINT_REPORT_SUCCEED =
  'memo-web/test-result/REQUEST_PRINT_REPORT_SUCCEED';
const REQUEST_PRINT_REPORT_FAILED =
  'memo-web/test-result/REQUEST_PRINT_REPORT_FAILED';

// Caliper
const SET_CALIPER_PLOT_LINES = 'memo-web/event-review/SET_CALIPER_PLOT_LINES';
const SET_IS_CALIPER_MODE = 'memo-web/event-review/SET_IS_CALIPER_MODE';
const SET_IS_TICK_MARKS_MODE = 'memo-web/event-review/SET_IS_TICK_MARKS_MODE';

// Move position (by current value)
const REQUEST_MOVE_ECTOPIC_POSITION_REQUESTED =
  'memo-web/test-result/REQUEST_MOVE_ECTOPIC_POSITION_REQUESTED';

// Reducer
const initialState = {
  // View Data
  ecgTestId: null,
  recordingTime: { recordingStartMs: null, recordingEndMs: null },
  general: {},
  eventReview: {
    isSetEcgChartListScroll: false,
    thirtySecAmplitudeRate:
      storedThirtySecAmplitudeRate || AMPLITUDE_OPTION.TWENTY_MV.RATE,
    navigatorTimestamp: null, // initValue = recordingTime, 시간이동시 여기에 저장
    hrHighlightTimestamp: null, // hr차트에서 선택한 시간
    ecgCaretTimestamp: null, // 캐럿타임
    /**
     * SidePanel 관련 State
     * tabValue: 선택된 탭 밸류
     * selectedValueList: {type: EVENT_CONST_TYPES, position: Number, timeEventId: Number | null, waveformIndex: Number: null}
     */
    sidePanelState: {
      tabValue: EVENT_GROUP_TYPE.EVENTS,
      selectedValueList: [],
      isUpdateFromChart: false,
    },
    sortOrder: {
      [EVENT_CONST_TYPES.AF]: AF_SORT_DEFAULT,
      [EVENT_CONST_TYPES.PAUSE]: PAUSE_SORT_DEFAULT,
      [EVENT_CONST_TYPES.SVT]: SVT_SORT_DEFAULT,
      [EVENT_CONST_TYPES.ISO_APC]: APC_SORT_DEFAULT,
      [EVENT_CONST_TYPES.COUPLET_APC]: APC_SORT_DEFAULT,
      [EVENT_CONST_TYPES.BIGEMINY_APC]: APC_SORT_DEFAULT,
      [EVENT_CONST_TYPES.TRIGEMINY_APC]: APC_SORT_DEFAULT,
      [EVENT_CONST_TYPES.QUADRIGEMINY_APC]: APC_SORT_DEFAULT,
      [EVENT_CONST_TYPES.VT]: VT_SORT_DEFAULT,
      [EVENT_CONST_TYPES.ISO_VPC]: VPC_SORT_DEFAULT,
      [EVENT_CONST_TYPES.COUPLET_VPC]: VPC_SORT_DEFAULT,
      [EVENT_CONST_TYPES.BIGEMINY_VPC]: VPC_SORT_DEFAULT,
      [EVENT_CONST_TYPES.TRIGEMINY_VPC]: VPC_SORT_DEFAULT,
      [EVENT_CONST_TYPES.QUADRIGEMINY_VPC]: VPC_SORT_DEFAULT,
      [EVENT_CONST_TYPES.OTHERS]: OTHERS_SORT_DEFAULT,
      [EVENT_CONST_TYPES.PATIENT]: PTE_SORT_DEFAULT,
    },
    /** @type {import('constant/ReportConst').ReportEventEditorState} ReportEventEditor 모듈의 Global State */
    reportEventEditor: {
      // 수정용
      reportEventId: null,
      prevSelectedReportSection: null,
      prevAnnotation: null,
      prevMainRepresentativeInfo: {
        selectedMs: null,
        representativeOnsetIndex: null,
        representativeTerminationIndex: null,
        amplitudeRate: BASIS_AMPLITUDE_RATE, // import { BASIS_AMPLITUDE_RATE } from constant/ReportConst
        isRemoved: true,
      },
      prevSubRepresentativeInfo: {
        selectedMs: null,
        representativeOnsetIndex: null,
        representativeTerminationIndex: null,
        amplitudeRate: BASIS_AMPLITUDE_RATE,
        isRemoved: true,
        reportEventId: null,
      },
      // 수정, 신규
      eventType: null, // import { EVENT_CONST_TYPES } from constant/EventConst
      editorStep: 0, // import { REPORT_EVENT_EDITOR_STEP_MAP } from constant/ReportConst
      selectedReportSection: null,
      selectedStripType: STRIP_TYPE_MAP.MAIN,
      mainRepresentativeInfo: {
        selectedMs: null,
        representativeOnsetIndex: null,
        representativeTerminationIndex: null,
        amplitudeRate: BASIS_AMPLITUDE_RATE, // import { BASIS_AMPLITUDE_RATE } from constant/ReportConst
      },
      subRepresentativeInfo: {
        selectedMs: null,
        representativeOnsetIndex: null,
        representativeTerminationIndex: null,
        amplitudeRate: BASIS_AMPLITUDE_RATE, // import { BASIS_AMPLITUDE_RATE } from constant/ReportConst
        isRemoved: true,
        isMainChanged: false,
      },
      selectedAmplitudeRate: BASIS_AMPLITUDE_RATE, // import { BASIS_AMPLITUDE_RATE } from constant/ReportConst
    },
    // 리포트 담기 및 수정 시 초기 10초 스트립 정보
    representativeStripInfo: {
      selectedMs: null,
      representativeOnsetIndex: null,
      representativeTerminationIndex: null,
    },
    tenSecStripDetail: {
      onsetMs: undefined,
      terminationMs: undefined,
      onsetWaveformIdx: undefined,
      terminationWaveformIdx: undefined,
      hrAvg: undefined,
      ecgRaw: undefined,
      beatLabelButtonDataList: undefined,
      responseValidationResult: {
        requestAt: null,
        validResult: null,
        editTargetBeatType: null,
      },
      pending: false,
      error: null,
    },
    chartSelectedTimestamp: 0,
    // event edit
    selectionStrip: {
      onset: {
        representativeTimestamp: undefined,
        representativeWaveformIndex: undefined,
        clickedWaveformIndex: undefined,
        clickedTimestamp: undefined,
      },
      termination: {
        representativeTimestamp: undefined,
        representativeWaveformIndex: undefined,
        clickedWaveformIndex: undefined,
        clickedTimestamp: undefined,
      },
    },
    tenSecStrip: {
      representativeCenterTimeStamp: undefined,
      representativeCenterWaveformIndex: undefined,
      centerWaveformIndex: undefined,
      main: {
        type: TEN_SEC_STRIP.TYPE.MAIN,
        position: '',
        representativeTimestamp: undefined,
        onsetWaveformIndex: undefined,
        terminationWaveformIndex: undefined,
      },
      extra: {
        type: TEN_SEC_STRIP.TYPE.EXTRA,
        position: '',
        representativeTimestamp: undefined,
        onsetWaveformIndex: undefined,
        terminationWaveformIndex: undefined,
      },
    },
    isOpenArrhythmiaContextmenu: false,
    isOpenBeatContextmenu: false,
    ecgChartListScrollTop: 0,
  },

  // API Data
  ecgTest: {
    pending: false,
    data: null,
    error: null,
  },
  timeEventsList: {
    pending: false,
    error: null,
    leadOff: [],
    data: [
      // {
      //   createAt: 0, // 정보 생성 시각의 timestamp === 부정맥 정보 업데이트 시점
      //   type: '',
      //   onsetMs: 0,
      //   onsetWaveformIndex: 0,
      //   terminationMs: 0,
      //   terminationWaveformIndex: 0,
      //   // 이하 API 조회 시 필요한 정보
      //   timeEventId: '',
      //   onsetRPeakIndex: 0,
      //   position: 0,
      // },
    ],
  },
  beatsNEctopicList: {
    pending: false,
    error: null,
    data: {},
  },
  // 10s strip detail - beat data
  beats: {
    pending: false,
    data: {
      waveformIndex: [],
      beatType: [],
      hr: [],
      bpm: null,
      position: [],
    },
    error: null,
  },
  //  10s strip detail: 선택된 비트 차트 (hr 리뷰 오른쪽 데이터들 idx, 10sec Strip...) Req.3번 방식
  beatsTenSecStripDetail: {
    pending: false,
    data: {
      baselineWaveformIndex: 0,
      ecgData: [],
      beatTypeZones: [],
      beats: {},
      duration: null,
      hrAvg: null,
      hrMax: null,
      hrMin: null,
      onsetMs: null,
      terminationMs: null,
      onsetWaveformIndex: null,
      terminationWaveformIndex: null,
      beatRenderData: null,
      beatBtnRenderData: null,
    },
    error: null,
  },
  hrv: {
    pending: false,
    data: {
      heartRatePoints: [],
      hourlyBands: [],
    },
    error: null,
  },
  /** @type {Array<import('component/hook/useGetPatientEvents').PatientTriggeredEventInfo>} Patient Triggered Events 목록, HRV 데이터 생성과정 중 함께 생성 */
  patientEvents: [
    // {
    //   position: 0,
    //   eventTimestamp: 1652353692000,
    //   eventBy: 1,
    //   eventType: 10,
    //   eventEntryType: 1,
    //   eventEtcNote: null,
    //   patientNote: null,
    //   triggerStartTimestamp: 1652353650000,
    //   triggerEndTimestamp: 1652353740000,
    // },
  ],
  // timeEvent, ectopicEvent 를 아우르는 구성으로 변경?
  updateTimeEvent: {
    // time Events api (AF, Pause, Others)
    pending: false,
    data: {},
    error: null,
  },
  ectopicEvent: {
    // ectopic Events api (iso, couplet , VT, VPC, ...)
    // 계산을 클라이언트에서 할지, 서버에서 계산해서 받을지?
    pending: false,
    data: {},
    error: null,
  },
  eventDetail: {
    pending: false,
    data: null,
    error: null,
    isEventEdited: false,
    pendingEdit: false,
  },
  reportDetail: {
    pending: false,
    /** @type {import('redux/container/fragment/test-result/side-panel/ReportEventEditorFragmentContainer').ReportEvent} */
    data: null,
    error: null,
  },
  reportEvents: {
    pending: false,
    data: null,
    error: null,
  },
  reportState: {
    pending: false,
    data: null,
    error: null,
  },
  ecgStatistics: {
    updatedAt: null,
    pending: false,
    data: null,
    error: null,
  },
  reportStatistics: {
    updatedAt: null,
    pending: false,
    data: null,
    error: null,
  },
  allStatistics: {
    updatedAt: null,
    pending: false,
    data: null,
    error: null,
  },
  ecgRaw: {
    // pending
    pending: [],
    backwardPending: false,
    forwardPending: false,

    // direction
    isBackward: false,
    isForward: false,

    // data list
    ecgRawList: [],
    ecgRawSection: {
      beginOnset: undefined,
      endTermination: undefined,
    },
    initExtraSelection: {
      backward: { onset: undefined, termination: undefined },
      forward: { onset: undefined, termination: undefined },
    },

    // option
    isJumpToTime: undefined,
    navigatorTime: undefined,
    caretTime: undefined,
    scrollType: undefined,
    prependDataLength: undefined,
    withBeat: undefined,
    isBeatStrip: undefined,
    error: null,
  },
  caliper: {
    caliperPlotLines: [],
    isCaliperMode: false,
    isTickMarksMode: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE:
      if (action.targetSection) {
        return {
          ...state,
          [action.targetSection]: initialState[action.targetSection],
        };
      } else {
        return {
          ...initialState,
          ecgTestId: action.ecgTestId,
        };
      }
    // EntireEcgFragment Data
    case SET_NAVIGATOR_TIMESTAMP:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          navigatorTimestamp: action.timestamp,
          ecgCaretTimestamp: action.timestamp,
          isSetEcgChartListScroll: false,
        },
      };
    case SET_HR_HIGHLIGHT_TIMESTAMP:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          hrHighlightTimestamp: action.timestamp,
          ecgCaretTimestamp: action.timestamp,
          isSetEcgChartListScroll: false,
        },
      };
    // Handle SidePanel State
    case SET_SIDE_PANEL_TAB_VALUE:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          sidePanelState: {
            ...initialState.eventReview.sidePanelState,
            tabValue: action.tabValue,
          },
          tenSecStrip: {
            ...initialState.eventReview.tenSecStrip,
          },
        },
        eventDetail: {
          ...initialState.eventDetail,
        },
        reportDetail: {
          ...initialState.reportDetail,
        },
      };
    case SET_SIDE_PANEL_SELECTED_VALUE_LIST:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          sidePanelState: {
            ...state.eventReview.sidePanelState,
            selectedValueList: action.newSelectedValueList,
            isUpdateFromChart: action.isFromChart,
          },
        },
      };
    // Representative Strip State Management
    case SET_REPRESENTATIVE_STRIP_INFO:
      let { representativeOnsetIndex, representativeTerminationIndex } =
        action.newInfo;
      const representativeStripLength =
        representativeTerminationIndex - representativeOnsetIndex;
      const lastWaveformIndex = Math.floor(
        (state.recordingTime.recordingEndMs -
          state.recordingTime.recordingStartMs) /
          4
      );
      if (representativeOnsetIndex < 0) {
        representativeOnsetIndex = 1;
        representativeTerminationIndex = representativeStripLength + 1;
      } else if (lastWaveformIndex < representativeTerminationIndex) {
        representativeOnsetIndex =
          lastWaveformIndex - representativeStripLength;
        representativeTerminationIndex = lastWaveformIndex;
      }
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          representativeStripInfo: {
            ...action.newInfo,
            representativeOnsetIndex,
            representativeTerminationIndex,
          },
        },
      };
    case RESET_REPRESENTATIVE_STRIP_INFO:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          representativeStripInfo: {
            ...initialState.eventReview.representativeStripInfo,
          },
        },
      };
    // Report Event Editor Management
    case SET_REPORT_EVENT_EDITOR_START:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          reportEventEditor: {
            ...initialState.eventReview.reportEventEditor,
            editorStep: 1,
            eventType: action.eventType,
            ...action.prevSubState,
          },
        },
      };
    case SET_REPORT_EVENT_EDITOR_CLOSE:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          reportEventEditor: {
            ...initialState.eventReview.reportEventEditor,
          },
        },
      };
    case SET_REPORT_EVENT_EDITOR_NEW_STATE:
      // editorStep 이 2로 변경될 때 selectedRepresentativeInfo.
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          reportEventEditor: {
            ...state.eventReview.reportEventEditor,
            ...action.newState,
          },
        },
      };
    case SET_CHART_SELECTED_STRIP:
      const chartSelectedTimestamp =
        action.selectedTimeMs - (action.selectedTimeMs % 30000);

      if (chartSelectedTimestamp === state.eventReview.chartSelectedTimestamp) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          chartSelectedTimestamp,
        },
      };
    case SET_BASIC_LEAD_OFF:
      return {
        ...state,
        timeEventsList: {
          ...state.timeEventsList,
          leadOff: mergeLeadOffInfo(
            state.timeEventsList.leadOff,
            action.basicLeadOffList
          ),
        },
      };
    case SET_SORT_ORDER:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          sortOrder: {
            ...state.eventReview.sortOrder,
            [action.newSortOrderKey]: action.newSortOrderValue,
          },
        },
      };
    case SET_THIRTY_SEC_AMPLITUDE_RATE: {
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          thirtySecAmplitudeRate: action.amplitudeRate,
        },
      };
    }

    // Get ECG Test
    case GET_ECG_TEST_REQUESTED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: true,
          error: null,
        },
      };
    case GET_ECG_TEST_SUCCEED:
      return {
        ...state,
        recordingTime: {
          recordingStartMs: DateUtil.formatMs(
            action.data.patchecg.startTimestamp
          ),
          recordingEndMs: DateUtil.formatMs(action.data.patchecg.endTimestamp),
        },
        ecgTest: {
          ...state.ecgTest,
          pending: false,
          data: {
            ...action.data,
            latestReport: {
              ...action.data.latestReport,
              analyzedTime: action.data.latestReport?.analyzedTime || 0,
              recordingTime:
                action.data.latestReport?.recordingTime ||
                (action.data.patchecg.endTimestamp -
                  action.data.patchecg.startTimestamp) *
                  1000,
            },
          },
        },
      };
    case GET_ECG_TEST_FAILED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: false,
          error: action.error,
        },
      };
    // Patch ECG Test
    case PATCH_ECG_TEST_REQUESTED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: true,
          error: null,
        },
      };
    case PATCH_ECG_TEST_SUCCEED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: false,
          data: action.data,
        },
      };
    case PATCH_ECG_TEST_FAILED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: false,
          error: action.error,
        },
      };
    // Get event detail
    case GET_EVENT_DETAIL_REQUESTED:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          pending: true,
          error: null,
          isEventEdited: false,
          pendingEdit: false,
        },
      };
    case GET_EVENT_DETAIL_SUCCEED:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          pending: false,
          data: action.data,
          error: null,
        },
        eventReview: {
          ...state.eventReview,
          sidePanelState: {
            ...state.eventReview.sidePanelState,
            selectedValueList: action.newSelectedValueList,
          },
        },
      };
    case GET_EVENT_DETAIL_FAILED:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          pending: false,
          data: null,
          error: action.error,
        },
      };
    case SET_EVENT_DETAIL_EDITED:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          isEventEdited: true,
        },
      };
    case SET_EVENT_DETAIL_PEND_EDIT:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          pendingEdit: action.newPendingEditState,
        },
      };
    // Get ecgs statistics
    case GET_ECGS_STATISTICS_REQUESTED:
      return {
        ...state,
        ecgStatistics: {
          ...state.ecgStatistics,
          pending: true,
          error: null,
        },
      };
    case GET_ECGS_STATISTICS_SUCCEED:
      return {
        ...state,
        ecgStatistics: {
          updatedAt: Date.now(),
          pending: false,
          data: action.data,
          error: null,
        },
      };
    case GET_ECGS_STATISTICS_FAILED:
      return {
        ...state,
        ecgStatistics: {
          ...state.ecgStatistics,
          pending: false,
          error: action.error,
        },
      };
    // Get reports statistics
    case GET_REPORTS_STATISTICS_REQUESTED:
      return {
        ...state,
        reportStatistics: {
          ...state.reportStatistics,
          pending: true,
          error: null,
        },
      };
    case GET_REPORTS_STATISTICS_SUCCEED:
      return {
        ...state,
        reportStatistics: {
          updatedAt: Date.now(),
          pending: false,
          data: action.data,
          error: null,
        },
      };
    case GET_REPORTS_STATISTICS_FAILED:
      return {
        ...state,
        reportStatistics: {
          ...state.reportStatistics,
          pending: false,
          error: action.error,
        },
      };
    // get all statistics
    case GET_ALL_STATISTICS_REQUESTED:
      return {
        ...state,
        allStatistics: {
          pending: true,
          error: null,
        },
      };
    case GET_ALL_STATISTICS_SUCCEED:
      return {
        ...state,
        ecgStatistics: {
          ...state.ecgStatistics,
          updatedAt: action.updatedAt,
          data: action.data.ecgStatistics,
        },
        reportStatistics: {
          ...state.reportStatistics,
          updatedAt: action.updatedAt,
          data: action.data.reportStatistics,
        },
        allStatistics: {
          updatedAt: action.updatedAt,
          pending: false,
          data: action.data,
          error: null,
        },
      };
    case GET_ALL_STATISTICS_FAILED:
      return {
        ...state,
        allStatistics: {
          pending: false,
          error: action.error,
        },
      };
    // Adjust ecgs statistics
    case ADJUST_ECGS_STATISTICS:
      return {
        ...state,
        ecgStatistics: {
          ...state.ecgStatistics,
          data: {
            ...state.ecgStatistics.data,
            [action.eventSection]:
              state.ecgStatistics.data[action.eventSection] +
              action.adjustValue,
          },
        },
      };
    // Adjust reports statistics
    case ADJUST_REPORTS_STATISTICS:
      return {
        ...state,
        reportStatistics: {
          ...state.reportStatistics,
          data: {
            ...state.reportStatistics.data,
            [action.reportSection]:
              state.reportStatistics.data[action.reportSection] +
              action.adjustValue,
          },
        },
      };
    // GET report events
    case GET_REPORT_EVENTS_REQUESTED:
    case GET_NEXT_REPORT_EVENT:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,
          pending: true,
          error: null,
        },
        eventDetail: {
          ...state.eventDetail,
          pending: true,
          error: null,
          isEventEdited: false,
        },
      };
    case GET_REPORT_EVENTS_SUCCEED:
      return {
        ...state,
        reportDetail: {
          pending: false,
          data: action.data,
          error: null,
        },
        // ...(() =>
        //   state.eventReview.sidePanelState.tabValue === EVENT_GROUP_TYPE.EVENTS
        //     ? {
        //         eventDetail: {
        //           ...state.eventDetail,
        //           pending: false,
        //         },
        //       }
        //     : {})(),
      };
    case GET_REPORT_EVENTS_FAILED:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,
          pending: false,
          error: action.error,
        },
        ...(() =>
          state.eventReview.sidePanelState.tabValue === EVENT_GROUP_TYPE.EVENTS
            ? {
                eventDetail: {
                  ...state.eventDetail,
                  pending: false,
                },
              }
            : {})(),
      };
    // POST report events
    case POST_REPORT_EVENT_REQUESTED:
      return {
        ...state,
        reportEvents: { ...state.reportEvents, pending: true, error: null },
        // API 응답전 선반영
        eventDetail: {
          ...state.eventDetail,
          pending: true,
          data: {
            ...state.eventDetail.data,
            registeredReport: [
              ...state.eventDetail.data.registeredReport,
              {
                ...action.newReportEventInfo,
                reportEventId: null,
              },
            ],
          },
        },
      };
    case POST_REPORT_EVENT_SUCCEED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: false,
          data: action.data,
        },
        // API 응답후 롤백
        eventDetail: {
          ...state.eventDetail,
          pending: false,
          data: {
            ...state.eventDetail.data,
            registeredReport: [
              ...state.eventDetail.data.registeredReport.filter(
                (value) => value.reportEventId !== null
              ),
              {
                ...action.data,
              },
            ],
          },
        },
      };
    case POST_REPORT_EVENT_FAILED:
      return {
        ...state,
        reportEvents: {
          ...state.eventDetail,
          pending: false,
          error: action.error,
        },
        eventDetail: {
          ...state.eventDetail,
          pending: false,
          data: {
            ...state.eventDetail.data,
            registeredReport: [
              ...state.eventDetail.data.registeredReport.filter(
                (value) => value.reportEventId !== null
              ),
            ],
          },
        },
      };
    // update report event
    case UPDATE_REPORT_EVENT_REQUESTED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: true,
        },
        // API 응답전 선반영
        ...(() => {
          if (!action.isPreUpdate) return {};

          if (
            state.eventReview.sidePanelState.tabValue ===
            EVENT_GROUP_TYPE.EVENTS
          ) {
            return {
              eventDetail: {
                ...state.eventDetail,
                pending: true,
                data: {
                  ...state.eventDetail.data,
                  registeredReport: state.eventDetail.data.registeredReport.map(
                    (value) =>
                      value.reportEventId !== action.reportEventId
                        ? { ...value }
                        : {
                            ...value,
                            ...action.newReportEventInfo,
                          }
                  ),
                },
              },
            };
          } else {
            return {
              eventDetail: {
                ...state.eventDetail,
                pending: true,
              },
              reportDetail: {
                ...state.reportDetail,
                pending: true,
                data: {
                  ...state.reportDetail.data,
                  ...action.newReportEventInfo,
                },
              },
            };
          }
        })(),
      };
    case UPDATE_REPORT_EVENT_SUCCEED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: false,
          data: action.data,
        },
        // API 응답후 선반영 후조치
        ...(() => {
          if (!action.isPreUpdate) return {};

          if (
            state.eventReview.sidePanelState.tabValue ===
            EVENT_GROUP_TYPE.EVENTS
          ) {
            return {
              eventDetail: {
                ...state.eventDetail,
                pending: false,
              },
            };
          } else {
            return {
              eventDetail: {
                ...state.eventDetail,
                pending: false,
              },
              reportDetail: {
                ...state.reportDetail,
                pending: false,
              },
            };
          }
        })(),
      };
    case UPDATE_REPORT_EVENT_FAILED:
      return {
        ...state,
        reportEvents: {
          pending: false,
          error: action.error,
        },
        // API 응답후 선반영 후조치
        ...(() => {
          if (!action.isPreUpdate) return {};

          if (
            state.eventReview.sidePanelState.tabValue ===
            EVENT_GROUP_TYPE.EVENTS
          ) {
            return {
              eventDetail: {
                ...state.eventDetail,
                pending: false,
              },
            };
          } else {
            return {
              eventDetail: {
                ...state.eventDetail,
                pending: false,
              },
              reportDetail: {
                ...state.reportDetail,
                pending: false,
              },
            };
          }
        })(),
      };
    // delete report event
    case DELETE_REPORT_EVENT_REQUESTED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: true,
        },
        // Side Panel Tab 이 Events 인 경우 API 응답전 선반영
        ...(() =>
          state.eventReview.sidePanelState.tabValue === EVENT_GROUP_TYPE.EVENTS
            ? {
                eventDetail: {
                  ...state.eventDetail,
                  pending: true,
                  data: {
                    ...state.eventDetail.data,
                    registeredReport:
                      state.eventDetail.data.registeredReport.filter(
                        (value) => value.reportEventId !== action.reportEventId
                      ),
                  },
                },
              }
            : {})(),
      };
    case DELETE_REPORT_EVENT_SUCCEED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: false,
        },
        // Side Panel Tab 이 Events 인 경우 API 응답후 선반영 후조치
        ...(() =>
          state.eventReview.sidePanelState.tabValue === EVENT_GROUP_TYPE.EVENTS
            ? {
                eventDetail: {
                  ...state.eventDetail,
                  pending: false,
                },
              }
            : {})(),
      };
    case DELETE_REPORT_EVENT_FAILED:
      return {
        ...state,
        reportEvents: {
          pending: false,
          error: action.error,
        },
        // Side Panel Tab 이 Events 인 경우 API 응답후 선반영 후조치
        ...(() =>
          state.eventReview.sidePanelState.tabValue === EVENT_GROUP_TYPE.EVENTS
            ? {
                eventDetail: {
                  ...state.eventDetail,
                  pending: false,
                },
              }
            : {})(),
      };
    // PTE Report Info Update
    case UPDATE_PTE_REPORT_INFO_REQUESTED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: true,
          error: null,
        },
        reportDetail: {
          ...state.reportDetail,
          pending: true,
          error: null,
        },
        eventDetail: {
          ...state.eventDetail,
          pending: true,
          error: null,
        },
      };
    case UPDATE_PTE_REPORT_INFO_SUCCEED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: false,
          data: action.payload.data,
        },
      };
    case UPDATE_PTE_REPORT_INFO_FAILED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: false,
          error: action.payload.error,
        },
        reportDetail: {
          ...state.reportDetail,
          pending: false,
        },
        eventDetail: {
          ...state.eventDetail,
          pending: false,
        },
      };
    // Get entire events
    case GET_TIME_EVENTS_LIST_REQUESTED:
      return {
        ...state,
        timeEventsList: {
          ...state.timeEventsList,
          pending: true,
          error: null,
        },
      };
    case GET_TIME_EVENTS_LIST_SUCCEED:
      return {
        ...state,
        timeEventsList: {
          ...state.timeEventsList,
          pending: false,
          leadOff: action.freshLeadOffList,
          data: action.freshTimeEventList,
        },
      };
    case GET_TIME_EVENTS_LIST_FAILED:
      return {
        ...state,
        timeEventsList: {
          ...state.timeEventsList,
          pending: false,
          error: action.error,
        },
      };
    // get beats, ectopics list
    case GET_BEATS_N_ECTOPIC_LIST_REQUESTED:
      return {
        ...state,
        beatsNEctopicList: {
          ...state.beatsNEctopicList,
          pending: true,
          error: null,
        },
      };
    case GET_BEATS_N_ECTOPIC_LIST_SUCCEED:
      return {
        ...state,
        beatsNEctopicList: {
          ...state.beatsNEctopicList,
          pending: false,
          // data: mergeBeatEventInfoMap(
          //   state.beatsNEctopicList.data,
          //   action.data
          // ),
          data: action.freshBeatsNBeatEventsList,
        },
      };
    case GET_BEATS_N_ECTOPIC_LIST_FAILED:
      return {
        ...state,
        beatsNEctopicList: {
          ...state.beatsNEctopicList,
          pending: false,
          error: action.error,
        },
      };
    // Get daily heart rate
    case GET_DAILY_HEART_RATE_REQUESTED:
      return {
        ...state,
        hrv: {
          ...state.hrv,
          pending: true,
          error: null,
        },
      };
    case GET_DAILY_HEART_RATE_SUCCEED:
      return {
        ...state,
        hrv: {
          ...state.hrv,
          pending: false,
          data: action.data,
        },
        patientEvents: action.patientEvents,
      };
    case GET_DAILY_HEART_RATE_FAILED:
      return {
        ...state,
        hrv: {
          ...state.hrv,
          pending: false,
          error: action.error,
        },
      };
    case SET_PATIENT_TRIGGERED_EVENT_LIST:
      return {
        ...state,
        patientEvents: action.payload.patientEvents,
      };
    // Get ecgRaw data
    case GET_ECGRAW_INIT_REQUESTED:
      let initAtTime = action.atTime;
      return {
        ...state,
        ecgRaw: {
          ...state.ecgRaw,
          ecgRawList: action.isInit ? [] : state.ecgRaw.ecgRawList,
          pending: true,
          // todo: jyoon[221023] backwardPending, forwardPending true 여부 조건 필요.
          backwardPending: true,
          forwardPending: true,
          // pending: action.isInit ? true : state.ecgRaw.pending,
          // backwardPending:
          //   action.isScroll && action.isBackward
          //     ? true
          //     : state.ecgRaw.backwardPending,
          // forwardPending:
          //   action.isScroll && action.isForward
          //     ? true
          //     : state.ecgRaw.forwardPending,

          // isBackward:
          //   action.isScroll && action.isBackward
          //     ? true
          //     : state.ecgRaw.isBackward,

          // isForward: action.isForward ? true : state.ecgRaw.isForward,
          withBeat: action.withBeat,
          isBeatStrip: action.isBeatStrip,
          initAtTime,
          error: null,
        },
        // Beat 정보와 Beat 이벤트 정보 목록 초기화
        beatsNEctopicList: action.isInit
          ? initialState.beatsNEctopicList
          : state.beatsNEctopicList,
      };
    case GET_ECGRAW_BACKWARD_REQUESTED:
    case GET_ECGRAW_FORWARD_REQUESTED:
    case GET_ECGRAW_REQUESTED:
      return {
        ...state,
        ecgRaw: {
          ...state.ecgRaw,
          ecgRawList: action.isInit ? [] : state.ecgRaw.ecgRawList,
          pending: action.isInit ? true : state.ecgRaw.pending,
          backwardPending:
            action.isScroll && action.isBackward
              ? true
              : state.ecgRaw.backwardPending,
          forwardPending:
            action.isScroll && action.isForward
              ? true
              : state.ecgRaw.forwardPending,
          isBackward:
            action.isScroll && action.isBackward
              ? true
              : state.ecgRaw.isBackward,

          isForward: action.isForward ? true : state.ecgRaw.isForward,
          withBeat: action.withBeat,
          isBeatStrip: action.isBeatStrip,
          error: null,
        },
      };
    case GET_ECGRAW_SUCCEED:
      const {
        results,
        action: {
          atTime,
          isBackward,
          isForward,
          isInit,
          isJumpToTime,
          isScroll,
          initAtTimeLocalState,
        },
        newEcgRawList,
      } = action;

      DEBUGGING_LOG_ECG_RAW &&
        console.log('🚨🚨🚨 log 🚨🚨🚨 - GET_ECGRAW_SUCCEED', {
          initAtTimeLocalState: DateUtil.formatDateTime(initAtTimeLocalState),
          'state.ecgRaw.initAtTime': DateUtil.formatDateTime(
            state.ecgRaw.initAtTime
          ),
          isInit,
          resultIndex_0: DateUtil.formatDateTime(results.at(0).onsetMs),
          'resultIndex_-1': DateUtil.formatDateTime(results.at(-1).onsetMs),
        });
      if (initAtTimeLocalState !== state.ecgRaw.initAtTime) {
        console.error('infinity scroll initTime error');
        return { ...state };
      }

      let rawApiResult, scrollType, navigatorTime, caretTime;
      let updatedPending = state.ecgRaw.pending;
      let updatedBackwardPending = state.ecgRaw.backwardPending;
      let updatedForwardPending = state.ecgRaw.forwardPending;

      rawApiResult = results;
      navigatorTime = isInit && atTime;
      caretTime = state.eventReview?.ecgCaretTimestamp || null;

      if (isInit) {
        updatedPending = false;
      }
      if (isScroll && isBackward) {
        scrollType = 'up';
        updatedBackwardPending = false;
      }
      if (isScroll && isForward) {
        scrollType = 'down';
        updatedForwardPending = false;
      }

      DEBUGGING_LOG_ECG_RAW &&
        newEcgRawList.forEach((v) => {
          if (v.onsetMs === results.at(0).onsetMs) {
            console.log('onsetMs: ', DateUtil.formatDateTime(v.onsetMs), '* ');
          } else if (v.onsetMs === state.ecgRaw.initAtTime) {
            console.log(
              'extra Init > onsetMs: ',
              DateUtil.formatDateTime(v.onsetMs),
              '* '
            );
          } else {
            console.log('onsetMs: ', DateUtil.formatDateTime(v.onsetMs));
          }
        });
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          chartSelectedTimestamp: 0,
        },
        ecgRaw: {
          ...state.ecgRaw,
          // pending
          pending: updatedPending,
          backwardPending: isInit
            ? state.ecgRaw.backwardPending
            : updatedBackwardPending,
          forwardPending: isInit
            ? state.ecgRaw.forwardPending
            : updatedForwardPending,
          // data list
          ecgRawList: [...newEcgRawList],
          ecgRawSection: {
            beginOnset: newEcgRawList.at(0).onsetMs,
            endTermination: newEcgRawList.at(-1).onsetMs,
          },
          // fetching ecgRaw data option
          isJumpToTime: isJumpToTime,
          navigatorTime: navigatorTime,
          caretTime: caretTime,
          scrollType,
          prependDataLength: isScroll && isBackward ? rawApiResult.length : 0,
        },
      };
    case GET_ECGRAW_INIT_SUCCEED:
      const {
        action: { backwardListLength, initExtraSelection },
        newEcgRawList: extraInitNewEcgRawList,
      } = action;

      DEBUGGING_LOG_ECG_RAW &&
        extraInitNewEcgRawList.forEach((v) => {
          if (v.onsetMs === initExtraSelection.backward?.onset) {
            console.log(
              'extra Init > onsetMs: ',
              DateUtil.formatDateTime(v.onsetMs),
              '* '
            );
          } else if (v.onsetMs === initExtraSelection.forward?.onset) {
            console.log(
              'extra Init > onsetMs: ',
              DateUtil.formatDateTime(v.onsetMs),
              '* '
            );
          } else if (v.onsetMs === state.ecgRaw.initAtTime) {
            console.log(
              'extra Init > onsetMs: ',
              DateUtil.formatDateTime(v.onsetMs),
              '* '
            );
          } else {
            console.log(
              'extra Init > onsetMs: ',
              DateUtil.formatDateTime(v.onsetMs)
            );
          }
        });

      return {
        ...state,
        ecgRaw: {
          ...state.ecgRaw,
          // pending
          backwardPending: false,
          forwardPending: false,

          // direction
          isBackward: true,
          isForward: true,

          // data list
          ecgRawList: [...extraInitNewEcgRawList],
          ecgRawSection: {
            beginOnset: extraInitNewEcgRawList.at(0).onsetMs,
            endTermination: extraInitNewEcgRawList.at(-1).onsetMs,
          },
          initExtraSelection: {
            backward: {
              onset: initExtraSelection.backward.onset,
              termination: initExtraSelection.backward.termination,
            },
            forward: {
              onset: initExtraSelection.forward.onset,
              termination: initExtraSelection.forward.onset,
            },
          },

          // option
          prependDataLength: backwardListLength,
        },
      };

    case GET_ECGRAW_FAILED:
      return {
        ...state,
        ecgRaw: {
          ...state.ecgRaw,
          pending: false,
          backwardPending: false,
          forwardPending: false,
          error: action.error,
        },
      };
    // POST Beats - 비트추가
    case POST_BEATS_REQUESTED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            pending: true,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: true,
          error: null,
        },
      };
    case POST_BEATS_SUCCEED:
      let newBeatLabelButtonDataListAfterPostBeats;
      (function () {
        const {
          data: { result: apiResResult },
          tabType,
        } = action;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.eventReview.tenSecStripDetail;
        if (
          Array.isArray(apiResResult.waveformIndex) &&
          apiResResult.waveformIndex.length > 0
        ) {
          const editTargetWaveformIndex =
            apiResResult.waveformIndex[0] - onsetWaveformIdx;
          const editTargetBeatType = apiResResult.beatType[0];
          const nextIndexOfAddBeat = beatLabelButtonDataList.findIndex(
            (v) => v.xAxisPoint > editTargetWaveformIndex
          );
          beatLabelButtonDataList.splice(nextIndexOfAddBeat, 0, {
            xAxisPoint: editTargetWaveformIndex,
            beatType: editTargetBeatType,
            title: TEN_SEC_STRIP_EDIT.BEAT_TYPE[editTargetBeatType],
            color: TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[editTargetBeatType],
            isSelected: false,
            isEventReview: '',
          });
        }
        newBeatLabelButtonDataListAfterPostBeats = beatLabelButtonDataList;
      })();

      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            beatLabelButtonDataList: [
              ...newBeatLabelButtonDataListAfterPostBeats,
            ],
            responseValidationResult: action.responseValidationResult,
            pending: false,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    case POST_BEATS_FAILED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            pending: false,
            error: action.error,
          },
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    // PATCH Beats
    case PATCH_BEATS_REQUESTED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            pending: true,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: true,
          error: null,
        },
      };
    case PATCH_BEATS_SUCCEED:
      // 업데이트 성공시 get 재호출
      // 10s strip detail에서 업데이트 하는 경우만
      let newBeatLabelButtonDataListAfterPatchBeats;
      let updateBeatLabelButtonDataList = false;

      (function () {
        const {
          data: { result: apiResResult },
          tabType,
        } = action;

        if (tabType === TEN_SEC_STRIP_DETAIL.TAB.ARRHYTHMIA_CONTEXTMENU) return;

        updateBeatLabelButtonDataList = true;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.eventReview.tenSecStripDetail;

        for (let i in apiResResult.waveformIndex) {
          newBeatLabelButtonDataListAfterPatchBeats =
            beatLabelButtonDataList.map((v) => {
              if (
                v.xAxisPoint ===
                apiResResult.waveformIndex[i] - onsetWaveformIdx
              ) {
                v.isSelected = false;
                v.beatType = apiResResult.beatType[i];
                v.title = TEN_SEC_STRIP_EDIT.BEAT_TYPE[v.beatType];
                v.color = TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[v.beatType];
                return v;
              }
              return v;
            });
        }
      })();

      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            beatLabelButtonDataList: updateBeatLabelButtonDataList
              ? [...newBeatLabelButtonDataListAfterPatchBeats]
              : state.eventReview.tenSecStripDetail.beatLabelButtonDataList,
            responseValidationResult: action.responseValidationResult,
            pending: false,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    case PATCH_BEATS_FAILED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            pending: false,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    // DELETE Beats
    case DELETE_BEATS_REQUESTED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            pending: true,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: true,
          error: null,
        },
      };
    case DELETE_BEATS_SUCCEED:
      let newBeatLabelButtonDataListAfterDeleteBeats;

      (function () {
        const { reqBody } = action;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.eventReview.tenSecStripDetail;

        const editTargetWaveformIndexList = reqBody.waveformIndexes.map(
          (v) => v - onsetWaveformIdx
        );
        const filteredBeatLabelButtonDataList = beatLabelButtonDataList.filter(
          (beatLabelButtonData) =>
            !editTargetWaveformIndexList.includes(
              beatLabelButtonData.xAxisPoint
            )
        );
        newBeatLabelButtonDataListAfterDeleteBeats =
          filteredBeatLabelButtonDataList;
      })();

      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            beatLabelButtonDataList: [
              ...newBeatLabelButtonDataListAfterDeleteBeats,
            ],
            pending: false,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    case DELETE_BEATS_FAILED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            pending: false,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    // Edit feature
    case SET_SELECTION_STRIP:
      const selectionStrip = action.selectionStrip;

      let newSelectionStrip = {};
      let newSelectionStripState;

      // eslint-disable-next-line default-case
      switch (selectionStrip.selectionMarkerType) {
        case SELECTION_MARKER_TYPE.ONSET:
          newSelectionStrip = {
            onset: {
              representativeTimestamp: selectionStrip.representativeTimestamp,
              representativeWaveformIndex:
                selectionStrip.representativeWaveformIndex,
              clickedTimestamp:
                selectionStrip.representativeTimestamp +
                selectionStrip.clickedWaveformIndex * 4,
              clickedWaveformIndex: selectionStrip.clickedWaveformIndex,
            },
            termination: {
              representativeTimestamp: undefined,
              representativeWaveformIndex: undefined,
              clickedTimestamp: undefined,
              clickedWaveformIndex: undefined,
            },
          };

          newSelectionStripState = {
            ...newSelectionStrip,
          };

          return {
            ...state,
            eventReview: {
              ...state.eventReview,
              selectionStrip: { ...newSelectionStripState },
              tenSecStrip: {
                ...state.eventReview.tenSecStrip,
                representativeCenterTimeStamp:
                  selectionStrip.representativeTimestamp,
                representativeCenterWaveformIndex:
                  selectionStrip.representativeWaveformIndex,
                centerWaveformIndex: selectionStrip.clickedWaveformIndex,
              },
            },
          };
        case SELECTION_MARKER_TYPE.TERMINATION:
          newSelectionStrip.termination = {
            representativeTimestamp: selectionStrip.representativeTimestamp,
            representativeWaveformIndex:
              selectionStrip.representativeWaveformIndex,
            clickedTimestamp:
              selectionStrip.representativeTimestamp +
              selectionStrip.clickedWaveformIndex * 4,
            clickedWaveformIndex: selectionStrip.clickedWaveformIndex,
          };

          newSelectionStripState = {
            ...state.eventReview.selectionStrip,
            ...newSelectionStrip,
          };

          let isSwapCondition, isSwapCurrTerminationWithPrevTermination;

          const {
            onset: {
              representativeTimestamp: onsetRepresentativeTimestamp,
              clickedWaveformIndex: onsetWaveformIndex,
            },
            termination: {
              representativeTimestamp: terminationRepresentativeTimestamp,
              clickedWaveformIndex: terminationWaveformIndex,
            },
          } = newSelectionStripState;

          if (
            (onsetRepresentativeTimestamp ===
              terminationRepresentativeTimestamp &&
              onsetWaveformIndex > terminationWaveformIndex) || // single line
            onsetRepresentativeTimestamp > terminationRepresentativeTimestamp // multi line
          ) {
            const swap = newSelectionStripState.termination;
            newSelectionStripState.termination = newSelectionStripState.onset;
            newSelectionStripState.onset = swap;
          }

          if (
            state.eventReview.selectionStrip.termination
              .representativeWaveformIndex !== undefined &&
            newSelectionStrip.termination.representativeWaveformIndex +
              newSelectionStrip.termination.clickedWaveformIndex <
              state.eventReview.selectionStrip.onset
                .representativeWaveformIndex +
                state.eventReview.selectionStrip.onset.clickedWaveformIndex
          ) {
            newSelectionStripState.termination =
              state.eventReview.selectionStrip.termination;
          }

          // onset이 termination보다 클때
          isSwapCondition = () => {
            return (
              (onsetRepresentativeTimestamp ===
                terminationRepresentativeTimestamp &&
                onsetWaveformIndex > terminationWaveformIndex) || // single line
              onsetRepresentativeTimestamp > terminationRepresentativeTimestamp // multi line
            );
          };
          // onset, termination이 이미 모두 있을때, shift+click으로 termination을 update시켜줄때 onset 보다 작은 시점을 클릭 했을때
          //  => 이전의 termination을 현재 termination으로 update
          isSwapCurrTerminationWithPrevTermination = () => {
            return (
              state.eventReview.selectionStrip.termination
                .representativeWaveformIndex !== undefined &&
              newSelectionStrip.termination.representativeWaveformIndex +
                newSelectionStrip.termination.clickedWaveformIndex <
                state.eventReview.selectionStrip.onset
                  .representativeWaveformIndex +
                  state.eventReview.selectionStrip.onset.clickedWaveformIndex
            );
          };

          break;
        case SELECTION_MARKER_TYPE.RESET:
          newSelectionStripState = initialState.eventReview.selectionStrip;
          break;
      }

      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          selectionStrip: { ...newSelectionStripState },
        },
      };
    case SET_TENSEC_STRIP:
      // init - reset
      let newState = {
        representativeCenterTimeStamp:
          action.tenSecStrip.representativeCenterTimeStamp,
        representativeCenterWaveformIndex:
          action.tenSecStrip.representativeCenterWaveformIndex,
        centerWaveformIndex: action.tenSecStrip.centerWaveformIndex,
      };

      const MAIN = action.tenSecStrip[TEN_SEC_STRIP.TYPE.MAIN];
      const EXTRA = action.tenSecStrip[TEN_SEC_STRIP.TYPE.EXTRA];
      const RESET = action.tenSecStrip[TEN_SEC_STRIP.TYPE.RESET];

      if (RESET) {
        return {
          ...state,
          eventReview: {
            ...state.eventReview,
            tenSecStrip: {
              ...initialState.eventReview.tenSecStrip,
            },
          },
        };
      }

      if (MAIN && MAIN.representativeTimestamp !== undefined) {
        Object.assign(newState, {
          main: MAIN,
        });
      }

      if (EXTRA) {
        if (EXTRA.representativeTimestamp !== undefined) {
          Object.assign(newState, {
            extra: EXTRA,
          });
        } else {
          Object.assign(newState, {
            extra: initialState.eventReview.tenSecStrip.extra,
          });
        }
      }

      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStrip: {
            ...state.eventReview.tenSecStrip,
            ...newState,
          },
        },
      };
    case SET_TENSEC_STRIP_DETAIL:
      const { tenSecStripDetail } = action;
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            ...tenSecStripDetail,
          },
        },
      };
    case SET_ARRHYTHMIA_CONTEXTMENU:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          isOpenArrhythmiaContextmenu:
            !getIsRawDataOnly({
              isRawDataOnly: state.ecgTest.data?.isRawDataOnly,
              isRawDataReady: state.ecgTest.data?.isRawDataReady,
              ecgTestStatus: state.ecgTest.data?.ecgTestStatus,
            }) &&
            state.eventReview.reportEventEditor.editorStep ===
              REPORT_EVENT_EDITOR_STEP_MAP.CANCEL &&
            state.eventReview.sidePanelState.tabValue ===
              EVENT_GROUP_TYPE.EVENTS &&
            action.isOpenArrhythmiaContextmenu,
        },
      };
    // 10s strip detail edit feature
    case SET_BEAT_CONTEXTMENU:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          isOpenBeatContextmenu: action.isOpenBeatContextmenu,
        },
      };
    // post TimeEvent when edit Beat on ContextMenu
    case POST_TIME_EVENT_REQUESTED:
      return {
        ...state,
        updateTimeEvent: {
          pending: true,
          data: {},
          error: null,
        },
      };
    case POST_TIME_EVENT_SUCCEED:
      return {
        ...state,
        updateTimeEvent: {
          pending: false,
          data: action.data,
          error: null,
        },
      };
    case POST_TIME_EVENT_FAILED:
      return {
        ...state,
        updateTimeEvent: {
          pending: false,
          data: {},
          error: action.error,
        },
      };
    // event review tab feature
    case SET_ECGCHARTLIST_SCROLL_TOP:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          ecgChartListScrollTop: action.ecgChartListScrollTop,
          chartSelectedTimestamp: 0,
        },
      };
    // Request Print Report
    // refactor(진현) : 리포트 상태 따로 관리 필요
    case REQUEST_PRINT_REPORT_REQUESTED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: true,
          error: null,
        },
        reportState: {
          ...state.reportState,
          pending: true,
          error: null,
        },
      };
    case REQUEST_PRINT_REPORT_SUCCEED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: false,
          data: {
            ...state.ecgTest.data,
            latestReport: {
              ...state.ecgTest.data.latestReport,
              ...action.data,
            },
          },
        },
        reportState: {
          ...state.reportState,
          pending: false,
          data: action.data,
        },
      };
    case REQUEST_PRINT_REPORT_FAILED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: false,
          error: action.error,
        },
        reportState: {
          ...state.reportState,
          pending: false,
          error: action.error,
        },
      };
    case SET_CALIPER_PLOT_LINES:
      return {
        ...state,
        caliper: {
          ...state.caliper,
          caliperPlotLines: action.caliperPlotLines,
        },
      };
    case SET_IS_CALIPER_MODE:
      return {
        ...state,
        caliper: {
          ...state.caliper,
          isCaliperMode: action.isCaliperMode,
        },
      };
    case SET_IS_TICK_MARKS_MODE:
      return {
        ...state,
        caliper: {
          ...state.caliper,
          isTickMarksMode: action.isTickMarksMode,
        },
      };
    case REQUEST_MOVE_ECTOPIC_POSITION_REQUESTED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          sidePanelState: {
            ...state.eventReview.sidePanelState,
            isUpdateFromChart: false,
          },
        },
        eventDetail: {
          ...state.eventDetail,
          pending: true,
          error: null,
          isEventEdited: false,
          pendingEdit: false,
        },
      };
    default:
      return state;
  }
}

// Action Creators
export function initializeState(ecgTestId, targetSection) {
  if (ecgTestId) return { type: INITIALIZE, ecgTestId, targetSection };
}

// EntireEcgFragment Data
export function setNavigatorTimestamp(timestamp, isInit) {
  return { type: SET_NAVIGATOR_TIMESTAMP, timestamp, isInit };
}
export function setHrHighlight(timestamp) {
  return { type: SET_HR_HIGHLIGHT_TIMESTAMP, timestamp };
}

// Handle sidePanelState
export function setSidePanelTabValue(tabValue) {
  return { type: SET_SIDE_PANEL_TAB_VALUE, tabValue };
}
/**
 * Side Panel 의 선택된 이벤트 정보 배열(`sidePanelState.selectedValueList`)을 업데이트 하는 Action
 *
 * Action 처리 후 Side Panel 의 텝에 따라 조회 API 요청함(`_setSelectedValueListHandler`)
 * @param {{type, position, timeEventId, waveformIndex, nearestBeatWaveformIndexWithSelection}[]} newSelectedValueList
 * @param {Boolean} isFromChart 정보 업데이트가 어디서 발생됐는지 확인용, true 면 차트 스크롤 이동 안함
 * @returns
 */
export function setSidePanelSelectedValueList(
  newSelectedValueList,
  isFromChart = false,
  isOnlySet = false
) {
  return {
    type: SET_SIDE_PANEL_SELECTED_VALUE_LIST,
    newSelectedValueList,
    isFromChart,
    isOnlySet,
  };
}

// Representative Strip State Management
/**
 *
 * @param {{selectedMs: Timestamp, representativeOnsetIndex: WaveformIndex, representativeTerminationIndex: WaveformIndex}} newInfo
 * @returns
 */
export function setRepresentativeStripInfo(newInfo) {
  // stale representative marker remove
  const chartEditInst = ChartUtil.chartEdit();
  chartEditInst.removeSelectionMarkerAll();
  chartEditInst.removeRepresentativeReportStrip();

  return { type: SET_REPRESENTATIVE_STRIP_INFO, newInfo };
}
export function resetRepresentativeStripInfo() {
  // stale representative marker remove
  const chartEditInst = ChartUtil.chartEdit();
  chartEditInst.removeSelectionMarkerAll();
  chartEditInst.removeRepresentativeReportStrip();

  return { type: RESET_REPRESENTATIVE_STRIP_INFO };
}

// Report Event Editor Management
/**
 *
 * @param {EVENT_CONST_TYPES} eventType
 * @param {{reportEventId: String, prevAnnotation: String, selectedReportSection: REPORT_SECTION}?} prevSubState
 * @returns
 */
export function setReportEventEditorStart(eventType, prevSubState) {
  return {
    type: SET_REPORT_EVENT_EDITOR_START,
    eventType,
    prevSubState,
  };
}
export function setReportEventEditorClose() {
  return { type: SET_REPORT_EVENT_EDITOR_CLOSE };
}
export function setReportEventEditorNewState(newState) {
  return { type: SET_REPORT_EVENT_EDITOR_NEW_STATE, newState };
}

// Handle chart selected Strips
export function setChartSelectedStrip(selectedTimeMs) {
  return { type: SET_CHART_SELECTED_STRIP, selectedTimeMs };
}

function setBasicLeadOff(basicLeadOffList) {
  return {
    type: SET_BASIC_LEAD_OFF,
    basicLeadOffList,
  };
}
/**
 *
 * @param {{newSortOrderKey: string, newSortOrderValue: object}} param0
 * @returns
 */
export function setSortOrder({ newSortOrderKey, newSortOrderValue }) {
  return {
    type: SET_SORT_ORDER,
    newSortOrderKey,
    newSortOrderValue,
  };
}

export function setThirtySecAmplitudeRate(amplitudeRate) {
  return {
    type: SET_THIRTY_SEC_AMPLITUDE_RATE,
    amplitudeRate,
  };
}

// Get ECG Test
export function getEcgTestRequested(isInitialized) {
  return { type: GET_ECG_TEST_REQUESTED, isInitialized };
}
export function getEcgTestSucceed(data) {
  return {
    type: GET_ECG_TEST_SUCCEED,
    data,
  };
}
function getEcgTestFailed(error) {
  return { type: GET_ECG_TEST_FAILED, error };
}

// Patch ECG Test
export function patchEcgTestRequested({ ecgTestId, form, callback }) {
  return { type: PATCH_ECG_TEST_REQUESTED, ecgTestId, form, callback };
}
function patchEcgTestSucceed(data) {
  return {
    type: PATCH_ECG_TEST_SUCCEED,
    data,
  };
}
function patchEcgTestFailed(error) {
  return { type: PATCH_ECG_TEST_FAILED, error };
}

// Get single events
function getEventDetailRequested(
  eventType,
  eventId,
  position,
  isSelectedValueUpdate,
  isIgnoreTimeJump,
  isGeminyType
) {
  return {
    type: GET_EVENT_DETAIL_REQUESTED,
    eventType,
    eventId,
    position,
    isSelectedValueUpdate,
    isIgnoreTimeJump,
    isGeminyType,
  };
}
function getEventDetailSucceed(data, newSelectedValueList) {
  return {
    type: GET_EVENT_DETAIL_SUCCEED,
    data: data,
    newSelectedValueList,
  };
}
function getEventDetailFailed(error) {
  return { type: GET_EVENT_DETAIL_FAILED, error };
}
function setEventDetailEdited() {
  return { type: SET_EVENT_DETAIL_EDITED };
}
function setEventDetailEditPending(newPendingEditState) {
  return { type: SET_EVENT_DETAIL_PEND_EDIT, newPendingEditState };
}

// Get entire events
/**
 *
 * @param {string?} targetTimeEventType
 * @param {{isWholeUnMark?: boolean, callback?: GeneratorFunction}?} options
 * @returns
 */
export function getTimeEventsListRequested(targetTimeEventType, options) {
  return {
    type: GET_TIME_EVENTS_LIST_REQUESTED,
    targetTimeEventType,
    options,
  };
}

function getTimeEventsListSucceed(freshLeadOffList, freshTimeEventList) {
  return {
    type: GET_TIME_EVENTS_LIST_SUCCEED,
    freshLeadOffList,
    freshTimeEventList,
  };
}
function getTimeEventsListFailed(error) {
  return { type: GET_TIME_EVENTS_LIST_FAILED, error };
}
export function getBeatsNEctopicListRequested(
  onsetWaveformIndex,
  terminationWaveformIndex,
  options
) {
  return {
    type: GET_BEATS_N_ECTOPIC_LIST_REQUESTED,
    onsetWaveformIndex,
    terminationWaveformIndex,
    options,
  };
}
function getBeatsNEctopicListSucceed(freshBeatsNBeatEventsList) {
  return {
    type: GET_BEATS_N_ECTOPIC_LIST_SUCCEED,
    freshBeatsNBeatEventsList,
  };
}
function getBeatsNEctopicListFailed(error) {
  return { type: GET_BEATS_N_ECTOPIC_LIST_FAILED, error };
}

// Get daily heart rate
export function getDailyHeartRateRequested() {
  return { type: GET_DAILY_HEART_RATE_REQUESTED };
}
function getDailyHeartRateSucceed(data, patientEvents) {
  return {
    type: GET_DAILY_HEART_RATE_SUCCEED,
    data: data,
    patientEvents,
  };
}
function getDailyHeartRateFailed(error) {
  return { type: GET_DAILY_HEART_RATE_FAILED, error };
}
function setPatientTriggeredEventList(patientEvents) {
  return { type: SET_PATIENT_TRIGGERED_EVENT_LIST, payload: { patientEvents } };
}

// Get ecgRaw data
export function getEcgRawInitRequested(
  atTime,
  secStep,
  isBackward,
  isForward,
  isInit = true,
  isJumpToTime = false,
  isScroll = false,
  initAtTimeLocalState
) {
  return {
    type: GET_ECGRAW_INIT_REQUESTED,
    atTime,
    secStep,
    isBackward,
    isForward,
    isInit,
    isJumpToTime,
    isScroll,
    initAtTimeLocalState,
  };
}
export function getEcgRawRequestedBackward(
  atTime,
  secStep,
  isBackward,
  isForward,
  isInit = true,
  isJumpToTime = false,
  isScroll = false,
  initAtTimeLocalState
) {
  return {
    type: GET_ECGRAW_BACKWARD_REQUESTED,
    atTime,
    secStep,
    isBackward,
    isForward,
    isInit,
    isJumpToTime,
    isScroll,
    initAtTimeLocalState,
  };
}
export function getEcgRawRequestedForward(
  atTime,
  secStep,
  isBackward,
  isForward,
  isInit = true,
  isJumpToTime = false,
  isScroll = false,
  initAtTimeLocalState
) {
  return {
    type: GET_ECGRAW_FORWARD_REQUESTED,
    atTime,
    secStep,
    isBackward,
    isForward,
    isInit,
    isJumpToTime,
    isScroll,
    initAtTimeLocalState,
  };
}
export function getEcgRawRequested(
  atTime,
  secStep,
  isBackward,
  isForward,
  isInit = true,
  isJumpToTime = false,
  isScroll = false,
  initAtTimeLocalState
) {
  return {
    type: GET_ECGRAW_REQUESTED,
    atTime,
    secStep,
    isBackward,
    isForward,
    isInit,
    isJumpToTime,
    isScroll,
    initAtTimeLocalState,
  };
}
function getEcgRawSucceed(results, action, newEcgRawList) {
  return {
    type: GET_ECGRAW_SUCCEED,
    results,
    action,
    newEcgRawList,
  };
}
function getEcgRawInitSucceed(results, action, newEcgRawList) {
  return {
    type: GET_ECGRAW_INIT_SUCCEED,
    results,
    action,
    newEcgRawList,
  };
}
function getEcgRawFailed(error) {
  return { type: GET_ECGRAW_FAILED, error };
}

// post beats
export function postBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: POST_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
function postBeatsSucceed(data, responseValidationResult) {
  return {
    type: POST_BEATS_SUCCEED,
    data,
    responseValidationResult,
  };
}
function postBeatsFailed(error) {
  return {
    type: POST_BEATS_FAILED,
    error,
  };
}

// patch beats
export function patchBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: PATCH_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
function patchBeatsSucceed(data, tabType, responseValidationResult) {
  return {
    type: PATCH_BEATS_SUCCEED,
    data,
    tabType,
    responseValidationResult,
  };
}
function patchBeatsFailed(error) {
  return {
    type: PATCH_BEATS_FAILED,
    error,
  };
}

// delete beats
export function deleteBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: DELETE_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
function deleteBeatsSucceed(reqBody) {
  return {
    type: DELETE_BEATS_SUCCEED,
    reqBody,
  };
}
function deleteBeatsFailed(error) {
  return {
    type: DELETE_BEATS_FAILED,
    error,
  };
}
// 유효성 검사 로직에도 사용될 예정
export function getEcgsStatisticsRequest() {
  return {
    type: GET_ECGS_STATISTICS_REQUESTED,
  };
}
function getEcgsStatisticsSucceed(data) {
  return {
    type: GET_ECGS_STATISTICS_SUCCEED,
    data,
  };
}
function getEcgsStatisticsFailed(error) {
  return { type: GET_ECGS_STATISTICS_FAILED, error };
}

export function getReportsStatisticsRequest() {
  return {
    type: GET_REPORTS_STATISTICS_REQUESTED,
  };
}
function getReportsStatisticSucceed(data) {
  return {
    type: GET_REPORTS_STATISTICS_SUCCEED,
    data,
  };
}
function getReportStatisticFailed(error) {
  return {
    type: GET_REPORTS_STATISTICS_FAILED,
    error,
  };
}

export function getBothStatisticsDelegated() {
  return {
    type: GET_BOTH_STATISTICS_DELEGATED,
  };
}

export function getAllStatisticsRequest(ecgTestId) {
  return {
    type: GET_ALL_STATISTICS_REQUESTED,
    ecgTestId,
  };
}
function getAllStatisticsSucceed(data, updatedAt) {
  return {
    type: GET_ALL_STATISTICS_SUCCEED,
    data,
    updatedAt,
  };
}
function getAllStatisticsFailed(error) {
  return {
    type: GET_ALL_STATISTICS_FAILED,
    error,
  };
}

export function adjustEcgsStatistic(eventSection, adjustValue) {
  return {
    type: ADJUST_ECGS_STATISTICS,
    eventSection,
    adjustValue,
  };
}

export function adjustReportStatistic(reportSection, adjustValue) {
  return {
    type: ADJUST_REPORTS_STATISTICS,
    reportSection,
    adjustValue,
  };
}

export function getReportEventsRequested(reportSection, position = 1) {
  return { type: GET_REPORT_EVENTS_REQUESTED, reportSection, position };
}
function getReportEventsSucceed(data) {
  return { type: GET_REPORT_EVENTS_SUCCEED, data };
}
function getReportEventsFailed(error) {
  return { type: GET_REPORT_EVENTS_FAILED, error };
}

/**
 * @typedef ReportEventInfoType
 * @property {string} [rid]
 * @property {Number} [timeEventId]
 * @property {number} [onsetWaveformIndex]
 * @property {number} [patientEventId]
 * @property {REPORT_SECTION} reportSection
 * @property {number} representativeOnsetIndex
 * @property {number} representativeTerminationIndex
 * @property {string} annotation
 * @property {number} amplitudeRate
 */
/**
 *
 * @param {ReportEventInfoType} newReportEventInfo
 * @returns
 */
export function postReportEventRequested(newReportEventInfo) {
  return { type: POST_REPORT_EVENT_REQUESTED, newReportEventInfo };
}
function postReportEventSucceed(data) {
  return { type: POST_REPORT_EVENT_SUCCEED, data };
}
function postReportEventFailed(error) {
  return { type: POST_REPORT_EVENT_FAILED, error };
}
/**
 *
 * @param {Number} reportEventId
 * @param {ReportEventInfoType} newReportEventInfo
 * @param {Boolean} isPreUpdate
 * @param {import('redux').Action?} afterAction
 * @returns {import('redux').Action}
 */
export function updateReportEventRequested(
  reportEventId,
  newReportEventInfo,
  isPreUpdate,
  afterAction
) {
  return {
    type: UPDATE_REPORT_EVENT_REQUESTED,
    reportEventId,
    newReportEventInfo,
    isPreUpdate,
    afterAction,
  };
}
function updateReportEventSucceed(data, isPreUpdate) {
  return { type: UPDATE_REPORT_EVENT_SUCCEED, data, isPreUpdate };
}
function updateReportEventFailed(error, isPreUpdate) {
  return { type: UPDATE_REPORT_EVENT_FAILED, error, isPreUpdate };
}
/**
 *
 * @param {*} reportEventId
 * @param {import('redux').Action?} afterAction
 * @returns {import('redux').Action}
 */
export function deleteReportEventRequested(reportEventId, afterAction) {
  return { type: DELETE_REPORT_EVENT_REQUESTED, reportEventId, afterAction };
}
function deleteReportEventSucceed() {
  return { type: DELETE_REPORT_EVENT_SUCCEED };
}
function deleteReportEventFailed(error) {
  return { type: DELETE_REPORT_EVENT_FAILED, error };
}
export function getNextReportEvent(editedReportSection) {
  return {
    type: GET_NEXT_REPORT_EVENT,
    editedReportSection: editedReportSection,
  };
}

export function updatePTEReportInfoRequested(isRemove) {
  return {
    type: UPDATE_PTE_REPORT_INFO_REQUESTED,
    payload: { isRemove: isRemove },
  };
}
function updatePTEReportInfoSucceed(data) {
  return { type: UPDATE_PTE_REPORT_INFO_SUCCEED, payload: { data: data } };
}
function updatePTEReportInfoFailed(error) {
  return { type: UPDATE_PTE_REPORT_INFO_FAILED, payload: { error: error } };
}

// ecg chart list edit ui interaction
export function setSelectionStripRequest(selectionStrip) {
  return { type: SET_SELECTION_STRIP, selectionStrip };
}
export function setTenSecStripRequest(tenSecStrip) {
  return { type: SET_TENSEC_STRIP, tenSecStrip };
}
export function setArrhythmiaContextmenuRequest(isOpenArrhythmiaContextmenu) {
  return { type: SET_ARRHYTHMIA_CONTEXTMENU, isOpenArrhythmiaContextmenu };
}
export function setBeatContextmenuRequest(isOpenBeatContextmenu) {
  return { type: SET_BEAT_CONTEXTMENU, isOpenBeatContextmenu };
}
export function setEcgchartlistScrollTopRequest(ecgChartListScrollTop) {
  return { type: SET_ECGCHARTLIST_SCROLL_TOP, ecgChartListScrollTop };
}

export function setTenSecStripDetailRequest(tenSecStripDetail) {
  return {
    type: SET_TENSEC_STRIP_DETAIL,
    tenSecStripDetail,
  };
}

export function setIsEcgChartListScrollRequest(isSetEcgChartListScroll) {
  return {
    type: SET_IS_ECGCHARTLIST_SCROLL_TO_INDEX,
    isSetEcgChartListScroll,
  };
}

export function postTimeEventRequested(params) {
  return { type: POST_TIME_EVENT_REQUESTED, params };
}
export function postTimeEventSucceed(data) {
  return { type: POST_TIME_EVENT_SUCCEED, data };
}
function postTimeEventFailed(error) {
  return { type: POST_TIME_EVENT_FAILED, error };
}

export function requestPrintReportRequested(tid, request) {
  return {
    type: REQUEST_PRINT_REPORT_REQUESTED,
    tid,
    request,
  };
}
function requestPrintReportSucceed(data) {
  return {
    type: REQUEST_PRINT_REPORT_SUCCEED,
    data,
  };
}
function requestPrintReportFailed(error) {
  return {
    type: REQUEST_PRINT_REPORT_FAILED,
    error,
  };
}

export function requestMoveEctopicPositionRequested(params) {
  return {
    type: REQUEST_MOVE_ECTOPIC_POSITION_REQUESTED,
    params,
  };
}

// Caliper
export function setCaliperPlotLines(caliperPlotLines) {
  return { type: SET_CALIPER_PLOT_LINES, caliperPlotLines };
}
export function setIsCaliperMode(isCaliperMode) {
  return { type: SET_IS_CALIPER_MODE, isCaliperMode };
}
export function setIsTickMarksMode(isTickMarksMode) {
  return { type: SET_IS_TICK_MARKS_MODE, isTickMarksMode };
}

// Saga functions
function* _initializeState(action) {
  const isInitialized = true;
  // hrReview, beatReview redux state 초기화
  yield put(resetBeatReviewState());
  yield put(resetHrReviewState());

  yield put(getEcgTestRequested(isInitialized));
  // isRawData 때문에 아래 2가지 Action의 dispatch 타이밍 변경
  // TODO: 준호 - 전체 이벤트 정보 요청 타이밍 ecg test 데이터 확보 이후로 변경 필요
  // yield put(getTimeEventsListRequested()); //
  // yield put(getDailyHeartRateRequested()); //
  yield put(getTimeEventsListRequested(TIME_EVENT_TYPE.LEAD_OFF));
}

function* _selectionStripHandler(action) {
  try {
    const { selectionStrip } = action;
    const {
      selectionMarkerType,
      representativeTimestamp,
      clickedWaveformIndex,
      extraParam,
    } = selectionStrip;

    if (selectionMarkerType === SELECTION_MARKER_TYPE.RESET) {
      return;
    }
    if (selectionMarkerType === SELECTION_MARKER_TYPE.TERMINATION) {
      // 구간 선택 시 selectedValueList 초기화
      yield put(setSidePanelSelectedValueList([], true));
      return;
    }

    // ECG Chart List 에서 그냥 클릭이 발생된 경우
    // selectionMarkerType === SELECTION_MARKER_TYPE.ONSET
    const isSelectableChart = yield select(selectIsSelectableChart);
    const isSelectableRepresentativeStrip = yield select(
      selectIsSelectableRepresentativeStrip
    );

    if (isSelectableChart && extraParam.isNoise) {
      // Events 텝에서 전체 이벤트 조회 가능 상황인데, 클릭된 지점 Noise 일 경우
      yield call(setEventsInfo, [
        { type: EVENT_CONST_TYPES.NOISE, position: 0 },
      ]);
      return;
    }

    const { recordingStartMs, recordingEndMs } = yield select(
      selectRecordingTime
    );
    const representativeWaveformIndex = parseInt(
      (representativeTimestamp - recordingStartMs) / 4
    );
    const selectionWaveformIndex =
      representativeWaveformIndex + clickedWaveformIndex;
    const selectedMs = representativeTimestamp + clickedWaveformIndex * 4;

    if (isSelectableChart) {
      // Events 텝에서 전체 이벤트 조회 가능 상황
      const beatInfoMap = yield select(({ testResultReducer }) => ({
        [representativeWaveformIndex - ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX]:
          testResultReducer.beatsNEctopicList.data[
            representativeWaveformIndex - ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX
          ],
        [representativeWaveformIndex]:
          testResultReducer.beatsNEctopicList.data[representativeWaveformIndex],
        [representativeWaveformIndex + ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX]:
          testResultReducer.beatsNEctopicList.data[
            representativeWaveformIndex + ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX
          ],
      }));

      const beatWaveformIndexList = Object.values(beatInfoMap)
        .filter((v) => !!v)
        .map((v) => v.beats.waveformIndex)
        .flat()
        .sort((a, b) => a - b);

      // 선택 지점과 제일 인접한 Beat 의 위치
      const nearestBeatWaveformIndexWithSelection = getNearestOne(
        beatWaveformIndexList,
        selectionWaveformIndex
      );
      // 선택 지점의 Ectopic 정보 획득, 사전 List-Up 된 것에서 확보
      const ectopicList = (
        beatInfoMap[representativeWaveformIndex]?.ectopics ?? []
      )
        .filter((value) =>
          value.waveformIndex.includes(nearestBeatWaveformIndexWithSelection)
        )
        .map((value) => ({
          type: value.type,
          position: null,
          timeEventId: null,
          waveformIndex: value.waveformIndex,
          nearestBeatWaveformIndexWithSelection,
        }));
      // 선택 지점의 Time Event 정보 획득, 사전 List-Up 된 것에서 확보
      const timeEventList = yield select((state) =>
        state.testResultReducer.timeEventsList.data.filter(
          (value) =>
            value.onsetMs <= selectedMs && selectedMs <= value.terminationMs
        )
      );

      const eventList = [
        ...ectopicList,
        ...timeEventList.map((v) => ({
          type: v.type,
          position: v.position,
          timeEventId: v.timeEventId,
          waveformIndex: null,
        })),
      ];
      yield call(
        setEventsInfo,
        eventList.length === 0
          ? [{ type: EVENT_CONST_TYPES.NORMAL, position: 0 }]
          : eventList
      );
    } else if (isSelectableRepresentativeStrip) {
      // 리포트 담기 중 대표 Strip 선택 가능 상황
      // fresh representative state update
      const recordingEndWaveformIndex = (recordingEndMs - recordingStartMs) / 4;
      const selectedWaveformIndex = (selectedMs - recordingStartMs) / 4;
      const prevRepresentativeStripInfo = yield select(
        ({ testResultReducer: state }) =>
          state.eventReview.representativeStripInfo
      );
      const stripHalfWaveformLength =
        (prevRepresentativeStripInfo.representativeTerminationIndex -
          prevRepresentativeStripInfo.representativeOnsetIndex) /
        2;
      /** @type {import('constant/ReportConst').ReportEventEditorState} ReportEventEditor 모듈의 Global State */
      const prevReportEventEditorState = yield select(
        selectReportEventEditorState
      );
      const selectedStripType = prevReportEventEditorState.selectedStripType;
      const {
        modifiedCenterWaveformIndex,
        onsetWaveformIndex,
        terminationWaveformIndex,
      } = getOnsetTerminationByCenter(
        selectedWaveformIndex,
        stripHalfWaveformLength,
        recordingEndWaveformIndex
      );

      const newStripInfo = {
        selectedMs: recordingStartMs + modifiedCenterWaveformIndex * 4,
        representativeOnsetIndex: onsetWaveformIndex,
        representativeTerminationIndex: terminationWaveformIndex,
      };
      const prevStripInfo =
        selectedStripType === STRIP_TYPE_MAP.MAIN
          ? prevReportEventEditorState.mainRepresentativeInfo
          : prevReportEventEditorState.subRepresentativeInfo;
      if (
        prevStripInfo.selectedMs !== newStripInfo.selectedMs ||
        prevStripInfo.representativeOnsetIndex !==
          newStripInfo.representativeOnsetIndex ||
        prevStripInfo.representativeTerminationIndex !==
          newStripInfo.representativeTerminationIndex
      ) {
        let newSubState =
          selectedStripType === STRIP_TYPE_MAP.MAIN
            ? {
                mainRepresentativeInfo: {
                  ...prevReportEventEditorState.mainRepresentativeInfo,
                  ...newStripInfo,
                },
              }
            : {
                subRepresentativeInfo: {
                  ...prevReportEventEditorState.subRepresentativeInfo,
                  ...newStripInfo,
                },
              };
        if (
          selectedStripType === STRIP_TYPE_MAP.MAIN &&
          !prevReportEventEditorState.subRepresentativeInfo.isRemoved
        ) {
          newSubState.subRepresentativeInfo = {
            ...prevReportEventEditorState.subRepresentativeInfo,
            isRemoved: true,
            isMainChanged: true,
            amplitudeRate: BASIS_AMPLITUDE_RATE,
          };
        }

        yield put(setReportEventEditorNewState(newSubState));
      }

      const prevRepresentativeStripState = yield select(
        (state) => state.testResultReducer.eventReview.representativeStripInfo
      );
      if (
        !(
          newStripInfo.selectedMs === prevRepresentativeStripState.selectedMs &&
          newStripInfo.representativeOnsetIndex ===
            prevRepresentativeStripState.representativeOnsetIndex &&
          newStripInfo.representativeTerminationIndex ===
            prevRepresentativeStripState.representativeTerminationIndex
        )
      ) {
        // Representative Strip State 에 변경 사항이 있을경우에만 업데이트
        yield put(setRepresentativeStripInfo(newStripInfo));
      }
    } else {
      // XXX: 준호 - ???
    }
  } catch (error) {
    // TODO: 준호 - 에러 발생 시 적절한(!) 예외처리 구현 필요
    console.error(error);
  }

  /**
   *
   * @param {Array<number>} ascList
   * @param {number} target
   * @returns {number}
   */
  function getNearestOne(ascList, target) {
    if (!(Array.isArray(ascList) && ascList.length > 0))
      throw new Error('getNearestOne error: 배열 파라미터 오류');
    if (!(Number.isInteger(target) && target >= 0))
      throw new Error('getNearestOne error: 숫자 값 파라미터 오류');

    const firstBiggerIndex = ascList.findIndex((value) => target <= value);
    const smallestBigger = ascList.at(firstBiggerIndex);
    const largestSmaller = ascList.at(firstBiggerIndex - 1);
    if (
      Math.abs(smallestBigger - target) <= Math.abs(target - largestSmaller)
    ) {
      return smallestBigger;
    }
    return largestSmaller;
  }

  function* setEventsInfo(events) {
    const curEventInfo = yield select(selectSelectedValueList);

    if (
      // 기존과 모두 동일한 상황
      curEventInfo.length === events.length &&
      !events.some(
        (iValue) =>
          !curEventInfo.some(
            (jValue) =>
              iValue.type === jValue.type &&
              (iValue.position === jValue.position ||
                (iValue.timeEventId === jValue.timeEventId &&
                  iValue.waveformIndex.toString() ===
                    jValue.waveformIndex.toString()))
          )
      )
    ) {
      return;
    }

    yield put(setSidePanelSelectedValueList(events, true));
  }
}

function* _reportRepresentativeTenSecStripHandler(action) {
  const { representativeOnsetIndex, representativeTerminationIndex } =
    action.newInfo;

  /**
   * # report 담기시 10s strip detail에 보여줄 기준
   *
   *   CASE 1. REPORT_SECTION이 ADDITIONAL인 아닌 경우
   *     - 이벤트 중앙 기준으로 좌우 5초씩 10초를 10s strip detail에 보여줍니다.
   */
  const localCenterWaveformIndex = getLocalCenterWaveformIndex(
    representativeOnsetIndex,
    representativeTerminationIndex
  );
  const representativeCenterWaveformIndex =
    getRepresentativeCenterWaveformIndex(
      representativeOnsetIndex,
      representativeTerminationIndex
    );

  const event = { clickedWaveformIndex: localCenterWaveformIndex };
  const { recordingStartMs } = yield select(selectRecordingTime);
  const representativeCenterTimeStamp =
    recordingStartMs + representativeCenterWaveformIndex * 4;
  const tenSecStripParam = getTenSecStripParam(
    event,
    representativeCenterTimeStamp,
    representativeCenterWaveformIndex
  );

  yield put(setTenSecStripRequest(tenSecStripParam));
}

function* _tenSecStripHandler(action) {
  try {
    const { tenSecStrip } = action;

    if (action?.tenSecStrip[TEN_SEC_STRIP.TYPE.RESET]) return;

    const ecgRawList = yield select(selectEcgRawList);
    const beatsNEctopicList = yield select(selectBeatsNEctopicList);

    const constHr = TEN_SEC_SCRIPT_DETAIL.HR;
    const constBeatType = TEN_SEC_SCRIPT_DETAIL.BEAT_TYPE;
    const constWaveformIndex = TEN_SEC_SCRIPT_DETAIL.WAVEFORM_INDEX;

    const constMsUnitPerChartPoint = ECG_CHART_UNIT.MS_UNIT_PER_CHART_POINT;
    const constFiveSec = ECG_CHART_UNIT.FIVE_SEC;
    const constTenSec = ECG_CHART_UNIT.TEN_SEC;
    const constFiveSecWaveformIdx = ECG_CHART_UNIT.FIVE_SEC_WAVEFORM_IDX;
    const constTenSecWaveformIdx = ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX;

    let tenSecStripDetail = {
      onsetMs: undefined,
      terminationMs: undefined,
      onsetWaveformIdx: undefined,
      terminationWaveformIdx: undefined,
      hrAvg: undefined,
      ecgRaw: [],
      beatLabelButtonDataList: [],
    };

    let tenSecStripOnsetMs,
      tenSecStripTerminationMs,
      tenSecStripOnsetWaveformIdx,
      tenSecStripTerminationWaveformIdx;

    tenSecStripOnsetMs =
      tenSecStrip.representativeCenterTimeStamp +
      tenSecStrip.centerWaveformIndex * constMsUnitPerChartPoint -
      constFiveSec;

    tenSecStripTerminationMs = tenSecStripOnsetMs + constTenSec;

    tenSecStripOnsetWaveformIdx =
      tenSecStrip.representativeCenterWaveformIndex +
      tenSecStrip.centerWaveformIndex -
      constFiveSecWaveformIdx;

    tenSecStripTerminationWaveformIdx =
      tenSecStripOnsetWaveformIdx + constTenSecWaveformIdx;

    let tenSecStripEcgRaw, filterBeatsNEctopicList;
    let filterHrList, sumHr, tenSecStripHrAvg;
    let beatLabelButtonDataList,
      beatType,
      beatColorType,
      beatTypeList,
      beatWaveformIndexList;

    // 10s strip - ecg raw data
    tenSecStripEcgRaw = _getTenSecStripEcgRaw(
      ecgRawList,
      tenSecStripOnsetWaveformIdx
    );

    if (!tenSecStripEcgRaw) {
      // [예외처리] 10s strip detail open 상태에서 event position 이동시 현재 차트리스트(raw)에 없는 시간대면 10s strip이 이동한 position의 데이터를 보여주지 못함.
      return;
    }

    // 10s strip - detail beat info
    filterBeatsNEctopicList = _getFilterBeatsNEctopicList(
      beatsNEctopicList,
      tenSecStripOnsetWaveformIdx,
      tenSecStripTerminationWaveformIdx
    );

    // 10s strip - avg hr
    const mergedBeats = filterBeatsNEctopicList.reduce(
      (acc, cur) => ({
        //
        waveformIndex: [...acc.waveformIndex, ...cur.beats.waveformIndex],
        beatType: [...acc.beatType, ...cur.beats.beatType],
        hr: [...acc.hr, ...cur.beats.hr],
      }),
      { waveformIndex: [], beatType: [], hr: [] }
    );

    tenSecStripHrAvg = getTenSecAvgHrByFromTo(
      mergedBeats,
      tenSecStripOnsetWaveformIdx,
      tenSecStripTerminationWaveformIdx
    );

    // 10 strip에 render 할 beat label button data
    beatTypeList = _getTenSecStripInfo(
      filterBeatsNEctopicList,
      tenSecStripOnsetWaveformIdx,
      tenSecStripTerminationWaveformIdx,
      constBeatType
    );

    beatWaveformIndexList = _getTenSecStripInfo(
      filterBeatsNEctopicList,
      tenSecStripOnsetWaveformIdx,
      tenSecStripTerminationWaveformIdx,
      constWaveformIndex
    );

    beatLabelButtonDataList = _getBeatLabelButtonDataList({
      beatTypeList,
      beatWaveformIndexList,
    });

    tenSecStripDetail = {
      onsetMs: tenSecStripOnsetMs,
      terminationMs: tenSecStripTerminationMs,
      onsetWaveformIdx: tenSecStripOnsetWaveformIdx,
      terminationWaveformIdx: tenSecStripTerminationWaveformIdx,
      hrAvg: tenSecStripHrAvg,
      ecgRaw: tenSecStripEcgRaw,
      beatLabelButtonDataList,
    };

    yield put(setTenSecStripDetailRequest(tenSecStripDetail));

    function _getTenSecStripEcgRaw(
      ecgRawList,
      startTenSecStripWaveformIdx,
      endTenSecStripWaveformIdx = startTenSecStripWaveformIdx +
        ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX
    ) {
      let result;
      let filterEcgRawList = [];
      const halfTenSecWaveformIdx = ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX;
      filterEcgRawList = ecgRawList.filter((v) => {
        return !(
          v.terminationWaveformIndex <= startTenSecStripWaveformIdx ||
          v.onsetWaveformIndex >= endTenSecStripWaveformIdx
        );
      });

      if (filterEcgRawList.length === 1) {
        result = filterEcgRawList[0].ecgData.slice(
          startTenSecStripWaveformIdx % 7500 < 0
            ? 0
            : startTenSecStripWaveformIdx % 7500,
          (startTenSecStripWaveformIdx + 2500) % 7500 < halfTenSecWaveformIdx
            ? 7500
            : (startTenSecStripWaveformIdx + 2500) % 7500
        );
        // 검사 시작 부분의 tenSecStrip 검증
        if (startTenSecStripWaveformIdx % 7500 < 0) {
          result.unshift(
            ...Array.from(
              {
                length: Math.abs(startTenSecStripWaveformIdx % 7500),
              },
              () => 0
            )
          );
        }
        // 검사 제일 마지막 부분의 tenSecStrip 검증
        if ((startTenSecStripWaveformIdx + 2500) % 7500 < halfTenSecWaveformIdx)
          result.push(
            ...Array.from(
              {
                length: halfTenSecWaveformIdx,
              },
              () => 0
            )
          );
      } else if (filterEcgRawList.length === 2) {
        result = [
          ...filterEcgRawList[0].ecgData.slice(
            startTenSecStripWaveformIdx % 7500
          ),
          ...filterEcgRawList[1].ecgData.slice(
            0,
            (startTenSecStripWaveformIdx + 2500) % 7500
          ),
        ];
      }

      return result;
    }
  } catch (error) {
    console.error(error);
  }
}
/**
 * `sidePanelState.selectedValueList` 업데이트 후 배열의 길이가 1일 경우 API 를 통해 정보를 조회한다.
 *
 * 단, Normal, Noise 는 제외
 * @param {{newSelectedValueList, isFromChart}} action
 */
function* _setSelectedValueListHandler(action) {
  try {
    const { newSelectedValueList, isOnlySet } = action;
    if (
      isOnlySet ||
      !isOnlyOneChosen(newSelectedValueList) ||
      isNormalOrNoiseType(newSelectedValueList[0].type)
    ) {
      return;
    }

    const {
      type,
      position,
      timeEventId,
      waveformIndex,
      /**
       * Onset Selection Marker 만 있는 상황에서, 선택된 위치에 Ectopic 이 있을 경우에만 있음
       *
       * _selectionStripHandler 에서 Side Tab 이 Events 인 분기 참조
       */
      nearestBeatWaveformIndexWithSelection,
    } = newSelectedValueList[0];
    const sideTabValue = yield select(selectSideTabValue);
    if (sideTabValue === EVENT_GROUP_TYPE.EVENTS) {
      yield put(
        getEventDetailRequested(
          type,
          timeEventId || nearestBeatWaveformIndexWithSelection,
          position,
          type !== EVENT_CONST_TYPES.PATIENT
        )
      );
    } else {
      const selectedMetaInfo = getSidePanelEventData({
        groupType: EVENT_GROUP_TYPE.REPORT,
        key: 'type',
        value: type,
      });
      yield put(
        getReportEventsRequested(selectedMetaInfo.reportSection, position)
      );
    }
  } catch (error) {
    console.error(error);
  }

  /**
   *
   * @param {Array<any>} selectedValueList
   * @returns
   */
  function isOnlyOneChosen(selectedValueList) {
    return selectedValueList.length === 1;
  }
  function isNormalOrNoiseType(eventType) {
    return [EVENT_CONST_TYPES.NORMAL, EVENT_CONST_TYPES.NOISE].includes(
      eventType
    );
  }
}

/** 정렬 기준 변경 시 수반되는 작업 핸들러 */
function* _setSortOrderHandler(action) {
  const { newSortOrderKey } = action;

  // 1. Time Event 의 경우 재 정렬된 정보 목록을 조회
  const targetEventMeta = getEventInfoByType(newSortOrderKey);
  if (targetEventMeta.timeEventType) {
    yield* _getTimeEventsList(
      getTimeEventsListRequested(targetEventMeta.timeEventType)
    );
  }

  // 2. 조회 중인 Event 의 Position 을 1로 재 설정하고, Event Detail 조회 발생 유도
  yield put(
    setSidePanelSelectedValueList([{ type: newSortOrderKey, position: 1 }])
  );
}

function* _getEcgTest(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);

    const {
      data: { result },
    } = yield call(ApiManager.readEcgTest, {
      ecgTestId,
    });

    const thirtySecMs = ECG_CHART_UNIT.TEN_SEC * 3;
    const recordingStartMs = DateUtil.formatMs(result.patchecg.startTimestamp);
    const recordingEndMs = DateUtil.formatMs(result.patchecg.endTimestamp);
    const createAt = new Date().getTime();
    yield put(
      setBasicLeadOff([
        {
          createAt,
          onsetMs: recordingStartMs - thirtySecMs,
          terminationMs: recordingStartMs,
          type: EVENT_CONST_TYPES.LEAD_OFF,
          timeEventId: `${EVENT_CONST_TYPES.LEAD_OFF}-basic-onset`,
          onsetRPeakIndex: null,
          position: -1,
        },
        {
          createAt,
          onsetMs: recordingEndMs,
          terminationMs: recordingEndMs + thirtySecMs,
          type: EVENT_CONST_TYPES.LEAD_OFF,
          timeEventId: `${EVENT_CONST_TYPES.LEAD_OFF}-basic-termination`,
          onsetRPeakIndex: null,
          position: -2,
        },
      ])
    );

    yield put(getDailyHeartRateRequested());
    yield put(getEcgTestSucceed(result));

    const isRawDataOnly = yield select(selectIsRawDataOnly);

    if (action.isInitialized && !isRawDataOnly) {
      // 부가정보 요청은 Test Detail 데이터를 받고 난 후 진행!!
      if (process.env.REACT_APP_CLIENT_NAME === 'memo-partner-web') {
        yield put(patchBeatPostprocessRequested());
      }
      yield put(getTimeEventsListRequested());
      yield put(getBothStatisticsDelegated());
    }
  } catch (error) {
    console.error(error);
    yield put(getEcgTestFailed(error));
  }
}

function* _patchEcgTest({ ecgTestId, form, callback }) {
  try {
    const {
      data: { result },
    } = yield call(
      ApiManager.patchEcgTest,
      {
        ecgTestId,
        body: form,
      },
      callback
    );

    yield put(patchEcgTestSucceed(result));
  } catch (error) {
    console.error(error);
    yield put(patchEcgTestFailed(error));
  }
}

let timeEventListCancelMap = {};
/**
 * Time Event 목록 데이터 요청
 *
 * @param {{type: string, targetTimeEventType?: string, options?: {isWholeUnMark?: boolean, callback?: GeneratorFunction}}} action
 */
function* _getTimeEventsList(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const sortOrder = yield select(selectEventReviewSortOrder);
    const { targetTimeEventType, options } = action;
    const eventInfoType =
      targetTimeEventType &&
      getEventInfoByQuery({
        timeEventType: targetTimeEventType,
      }).type;

    const defaultParams = {
      tid: ecgTestId,
      isMinimum: true,
    };
    let timeEventGroups;
    if (targetTimeEventType) {
      try {
        if (timeEventListCancelMap[targetTimeEventType]) {
          yield call(timeEventListCancelMap[targetTimeEventType].cancel);
        }
        timeEventListCancelMap[targetTimeEventType] =
          axios.CancelToken.source();
      } catch (error) {}
      let params = {
        ...defaultParams,
        eventType: targetTimeEventType,
      };
      if (sortOrder[eventInfoType])
        params.ordering = sortOrder[eventInfoType].queryOrderBy;
      const { data } = yield call(
        ApiManager.getTimeEventList,
        params,
        null,
        timeEventListCancelMap[targetTimeEventType]?.token
      );
      timeEventGroups = [{ data }];
    } else {
      try {
        const eventTypes = [
          TIME_EVENT_TYPE.AF,
          TIME_EVENT_TYPE.PAUSE,
          TIME_EVENT_TYPE.OTHERS,
          TIME_EVENT_TYPE.AVB_2,
          TIME_EVENT_TYPE.AVB_3,
        ];

        yield all(eventTypes.map(cancelExistingRequest));

        timeEventGroups = yield all(
          eventTypes.map((eventType) =>
            fetchTimeEventList(eventType, sortOrder, defaultParams)
          )
        );

        // funcs
        function* cancelExistingRequest(eventType) {
          if (timeEventListCancelMap[eventType]) {
            yield call(timeEventListCancelMap[eventType].cancel);
          }
          timeEventListCancelMap[eventType] = axios.CancelToken.source();
        }
        function* fetchTimeEventList(eventType, sortOrder, defaultParams) {
          const convertToEventConstType = EVENT_CONST_TYPES[eventType];
          return yield call(
            ApiManager.getTimeEventList,
            {
              ...defaultParams,
              eventType,
              ordering:
                sortOrder[convertToEventConstType]?.queryOrderBy ??
                'onset_waveform_index',
            },
            null,
            timeEventListCancelMap[convertToEventConstType]?.token
          );
        }
      } catch (error) {
        console.error(error);
      }
    }

    const responseList = transformTimeEvents(
      timeEventGroups.reduce((acc, cur) => [...acc, cur.data.results], [])
    );
    const { leadOff: staleLeadOffList, data: staleTimeEventList } =
      yield select((state) => state.testResultReducer.timeEventsList);

    let freshLeadOffList = [...staleLeadOffList];
    let freshTimeEventList = [...staleTimeEventList];

    if (targetTimeEventType) {
      if (targetTimeEventType === TIME_EVENT_TYPE.LEAD_OFF) {
        freshLeadOffList = mergeLeadOffInfo(staleLeadOffList, responseList);
      } else {
        freshTimeEventList = [
          ...staleTimeEventList.filter((value) => value.type !== eventInfoType),
          ...responseList,
        ];
      }
    } else {
      freshTimeEventList = responseList;
    }

    yield put(getTimeEventsListSucceed(freshLeadOffList, freshTimeEventList));
    if (options?.isWholeUnMark) yield put(setEventDetailEditPending(false));
    if (options?.callback) yield call(options.callback);
  } catch (error) {
    yield put(getTimeEventsListFailed(error));
    console.error(error);
  }
}

/**
 * HRV 차트에 필요한 Daily Heart Rate(dhr) 값과 Patient Trigger Events(pte) 정보 요청
 *
 * @param {*} action
 */
function* _getDailyHeartRate(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const [
      {
        data: { results: rawDhr },
      },
      {
        data: { results: pte },
      },
    ] = yield call(
      (params) => {
        return Promise.all([
          ApiManager.getDailyStatChart(params),
          ApiManager.getPatientTriggerEventList({
            tid: params.ecgTestId,
            ordering: 'event_timestamp',
            isMinimum: true,
          }),
        ]);
      },
      {
        ecgTestId,
      }
    );

    // HR 차트에서 사용할 데이터 셋 구성 진행
    // 1. HR 차트 Line 데이터(PTE 마크를 포함한 데이터)
    /**
     * {
     *  x: 시간,
     *  y: HR Avg,
     *  pteMarker?: {
     *   enabled: 2분 구간 동안 PTE 가 포함됐다면 true
     *  }
     * }
     */
    // 2. 전체 측정 구간에 대한 1시간 단위 밴드(+ 수면 구간 여부) 배열
    const sleeps = [];
    const patientEventTimes = [];
    const patientEvents = [];
    const TWENTY_HOURS_IN_MS = 3600 * 20 * 1000;
    let sleepFrom = null;
    const { recordingStartMs } = yield select(selectRecordingTime);

    pte.forEach((item) => {
      if (Const.PATIENT_EVENT_TYPE.SLEEP === item.eventType) {
        sleepFrom = item.eventTimeMs;
      } else if (Const.PATIENT_EVENT_TYPE.WAKEUP === item.eventType) {
        if (sleepFrom && item.eventTimeMs - sleepFrom < TWENTY_HOURS_IN_MS) {
          sleeps.push([sleepFrom, item.eventTimeMs]);
          sleepFrom = null;
        }
      } else {
        patientEventTimes.push(item.eventTimeMs);
        patientEvents.push({
          representativeStrip: [],
          ...item,
          position: patientEvents.length + 1,
          eventTimeMs: item.eventTimeMs,
          triggerOnsetMs: item.triggerOnsetMs,
          triggerTerminationMs: item.triggerTerminationMs,
          eventTimeWaveformIndex: (item.eventTimeMs - recordingStartMs) / 4,
        });
      }
    });

    const aSecondMs = 1000; // 1초
    const dataPointUnitMs = 2 * 60 * aSecondMs; // 2분
    const bandLastPointMs = 58 * 60 * aSecondMs; // 3480000 = 58분 = 1시간 구간의 마지막 포인트
    const aHourMs = 60 * 60 * aSecondMs; // 1시간

    const isSleep = (fromTimestamp) =>
      sleeps.some(
        (value) =>
          fromTimestamp + aHourMs > value[0] && value[1] > fromTimestamp
      );

    // Daily Heart Rate 데이터 구성이 날자 별로 그룹핑하여 하루치 HR 데이터 배열로 제공되어 2 중 반복문이 필요
    // XXX: HRV 에서 사용하는 Daily Heart Rate 데이터 변조 로직 개선 필요?
    patientEventTimes.reverse();
    let curEventTimestamp = patientEventTimes.pop();
    /** 2. 전체 측정 구간에 대한 1시간 단위 밴드(+ 수면 구간 여부) 배열 */
    const hourlyBands = [];
    let currentBand = null;
    /** 1. HR 차트 Line 데이터(PTE 마크를 포함한 데이터) */
    const heartRatePoints = rawDhr.reduce(
      (acc, cur) =>
        acc.concat(
          cur.data.map((element) => {
            const timestamp = element.timestamp * aSecondMs;
            const isLeadOff = element.isLeadOff;

            if (timestamp % aHourMs === 0) {
              // 구간 시작
              const _isSleep = isSleep(timestamp);
              currentBand = {
                className: `pb-huinno pb-${timestamp}${
                  _isSleep ? ' pb-huinno-sleep' : ''
                }`,
                from: timestamp,
                to: timestamp + aHourMs,
                isSleep: _isSleep,
                atTime: timestamp,
              };
            } else {
              // 구간 내
              if (currentBand?.atTime === null && element.hrsAvgAvg !== null) {
                currentBand.atTime = timestamp;
              }
              if (timestamp % aHourMs === bandLastPointMs) {
                // 구간 끝
                hourlyBands.push(currentBand);
              }
            }

            if (isLeadOff) {
              currentBand.className =
                currentBand.className + ' pb-huinno-lead-off';
            }

            const hr = {
              x: timestamp,
              y: element?.hrsAvgAvg,
            };
            while (curEventTimestamp < hr.x) {
              curEventTimestamp = patientEventTimes.pop();
            }
            if (
              hr.x <= curEventTimestamp &&
              curEventTimestamp < hr.x + dataPointUnitMs
            ) {
              hr.pteMarker = {
                enabled: true,
              };
            }

            return hr;
          })
        ),
      []
    );
    heartRatePoints.sort((a, b) => a.x - b.x);
    yield put(
      getDailyHeartRateSucceed({ heartRatePoints, hourlyBands }, patientEvents)
    );
  } catch (error) {
    console.error(error);
    yield put(getDailyHeartRateFailed(error));
  }
}

/**
 * 비트 이벤트 데이터 요청
 *
 * @param {*} action
 */
function* _getBeatsNEctopicByRange(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);

    const { onsetWaveformIndex, terminationWaveformIndex, options } = action;
    let onsetWI = onsetWaveformIndex < 0 ? 0 : onsetWaveformIndex;

    const {
      data: { result: beatsData },
    } = yield call(ApiManager.getBeatsFilterWaveformIndexRange, {
      ecgTestId,
      onsetWaveformIndex: onsetWI,
      terminationWaveformIndex,
    });

    const leadOffList = yield select((state) =>
      selectFilteredEpisodeOrLeadOffList(
        state,
        Math.max(onsetWI - ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX, 0),
        terminationWaveformIndex + ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX,
        EVENT_CONST_TYPES.LEAD_OFF
      )
    );
    const afList = yield select((state) =>
      selectFilteredEpisodeOrLeadOffList(
        state,
        Math.max(onsetWI - ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX, 0),
        terminationWaveformIndex + ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX,
        EVENT_CONST_TYPES.AF
      )
    );
    const getOverLappedEventList = getOverlapRangeFilter({
      leadOffList,
      afList,
    });
    const freshBeatsNBeatEventsList = getBeatsNBeatEventsList(
      onsetWI,
      terminationWaveformIndex,
      beatsData,
      undefined,
      getOverLappedEventList
    );
    const staleBeatsNBeatEventsList = yield select(selectBeatsNEctopicList);
    const totalFreshBeatsNBeatEventsList = getTotalFreshBeatsNBeatEventsList(
      staleBeatsNBeatEventsList,
      freshBeatsNBeatEventsList
    );

    yield put(getBeatsNEctopicListSucceed(totalFreshBeatsNBeatEventsList));
    if (options?.isWholeUnMark) yield put(setEventDetailEditPending(false));
    // # 10s strip detail open된 케이스
    //  : select 호출 순서 중요
    const tenSecStrip = yield select(getTenSecStrip);
    if (tenSecStrip.main.representativeTimestamp) {
      const hasTenSecStrip =
        freshBeatsNBeatEventsList[
          tenSecStrip.representativeCenterWaveformIndex
        ];

      if (hasTenSecStrip) {
        yield put(setTenSecStripRequest(tenSecStrip));
      }
    }
  } catch (error) {
    getBeatsNEctopicListFailed(error);
    console.error(error);
  }
}

function* _getEcgRaw(action) {
  try {
    const {
      atTime,
      secStep,
      isBackward,
      isForward,
      isInit,
      isJumpToTime,
      isScroll,
      withBeat = false,
      isBeatStrip = false,
    } = action;
    const formatMsAtTime = DateUtil.formatMs(
      parseInt(atTime.toString().slice(0, 9).padEnd(13, 0))
    );
    const ecgTestId = yield select(selectEcgTestId);
    const isRawDataOnly = yield select(selectIsRawDataOnly);

    const {
      recordingStartMs: ecgRawRecordingStartMs,
      recordingEndMs: ecgRawRecordingEndMs,
    } = yield select(selectRecordingTime);

    let onsetMsQueryStr, terminationMsQueryStr;

    if (!isScroll) {
      onsetMsQueryStr =
        formatMsAtTime -
        (isJumpToTime ? INFINITY_SCROLL.EXPLORER_UNIT_MS.OFFSET_ROW : 0);
      terminationMsQueryStr = formatMsAtTime + secStep;

      if (ecgRawRecordingStartMs > terminationMsQueryStr) {
        throw {
          msg: 'req termination Time is before ecg Test start time',
          time: { ecgRawRecordingStartMs, terminationMsQueryStr },
          callStack: new Error(),
        };
      }
      if (terminationMsQueryStr > ecgRawRecordingEndMs) {
        terminationMsQueryStr = ecgRawRecordingEndMs;
      }
    }

    if (isScroll) {
      if (isBackward && !isForward) {
        onsetMsQueryStr = formatMsAtTime;
        terminationMsQueryStr = formatMsAtTime + secStep;

        if (ecgRawRecordingStartMs > terminationMsQueryStr) {
          throw {
            msg: 'req termination Time is before ecg Test start time',
            time: { ecgRawRecordingStartMs, terminationMsQueryStr },
            callStack: new Error(),
          };
        }
      } else if (isForward && !isBackward) {
        onsetMsQueryStr = formatMsAtTime;
        terminationMsQueryStr = formatMsAtTime + secStep;

        if (ecgRawRecordingEndMs < onsetMsQueryStr) {
          throw {
            msg: 'req onset Time is after ecg Test end time',
            time: { ecgRawRecordingStartMs, terminationMsQueryStr },
            callStack: new Error(),
          };
        }
      }
    }

    if (!isRawDataOnly) {
      // Raw 데이터를 요청하는 구간으로 Beats + Ectopic 데이터도 요청
      yield put(
        getBeatsNEctopicListRequested(
          parseInt(Math.max(onsetMsQueryStr - ecgRawRecordingStartMs, 0) / 4),
          parseInt((terminationMsQueryStr - ecgRawRecordingStartMs) / 4)
        )
      );
    }

    DEBUGGING_LOG_ECG_RAW &&
      console.log('🚨🚨🚨 log 🚨🚨🚨 - function * _getEcgRaw', [
        onsetMsQueryStr,
        terminationMsQueryStr,
        DateUtil.formatDateTime(onsetMsQueryStr),
        DateUtil.formatDateTime(terminationMsQueryStr),
      ]);

    // # call init
    const {
      data: { results },
    } = yield call(ApiManager.getRawEcg, {
      ecgTestId,
      onsetMs: onsetMsQueryStr,
      terminationMs: terminationMsQueryStr,
      withBeat: isBeatStrip,
    });
    const newLoadedList = transformRawList(results);

    let tmpEcgRawList, newEcgRawList;
    const ecgRawList = yield select(selectEcgRawList);

    // 위로 스크롤시
    if (isScroll && isBackward) {
      tmpEcgRawList = [...newLoadedList, ...ecgRawList];
    }
    // 아래로 스크롤시
    if (isScroll && isForward) {
      tmpEcgRawList = [...ecgRawList, ...newLoadedList];
    }

    newEcgRawList = tmpEcgRawList || newLoadedList;
    yield put(getEcgRawSucceed(results, action, newEcgRawList));

    // # 10s strip detail open된 케이스
    //  : select 호출 순서 중요
    const tenSecStrip = yield select(getTenSecStrip);
    if (tenSecStrip.main.representativeTimestamp) {
      const hasTenSecStrip = newLoadedList.find(
        (v) => v.onsetMs === tenSecStrip.main.representativeTimestamp
      );

      if (hasTenSecStrip) {
        yield put(setTenSecStripRequest(tenSecStrip));
      }
    }

    // # call extra init
    //  : init 전후 api call
    if (isInit) {
      let backwardOnsetMsQueryStr = onsetMsQueryStr - 1000 * 60 * 5;
      let backwardTerminationMsQueryStr = onsetMsQueryStr;
      let forwardOnsetMsQueryStr = terminationMsQueryStr;
      let forwardTerminationMsQueryStr = terminationMsQueryStr + 1000 * 60 * 5;
      let apiCallList = [];
      let initBackwardResults = [],
        initForwardResults = [];

      // reset backward Onset time, forward Termination time
      if (backwardOnsetMsQueryStr < ecgRawRecordingStartMs) {
        backwardOnsetMsQueryStr = ecgRawRecordingStartMs;
      }
      if (forwardTerminationMsQueryStr > ecgRawRecordingEndMs) {
        forwardTerminationMsQueryStr = ecgRawRecordingEndMs;
      }

      // api target list
      if (backwardTerminationMsQueryStr > ecgRawRecordingStartMs) {
        DEBUGGING_LOG_ECG_RAW &&
          console.log(
            '🚨🚨🚨 log 🚨🚨🚨 - function * _getEcgRaw > extra init',
            [
              backwardOnsetMsQueryStr,
              backwardTerminationMsQueryStr,
              DateUtil.formatDateTime(backwardOnsetMsQueryStr),
              DateUtil.formatDateTime(backwardTerminationMsQueryStr),
            ]
          );

        apiCallList.push(
          call(ApiManager.getRawEcg, {
            ecgTestId,
            onsetMs: backwardOnsetMsQueryStr,
            terminationMs: backwardTerminationMsQueryStr,
            withBeat: isBeatStrip,
          })
        );
        if (!isRawDataOnly) {
          yield put(
            getBeatsNEctopicListRequested(
              parseInt(
                Math.max(backwardOnsetMsQueryStr - ecgRawRecordingStartMs, 0) /
                  4
              ),
              parseInt(
                (backwardTerminationMsQueryStr - ecgRawRecordingStartMs) / 4
              )
            )
          );
        }
      }
      if (forwardOnsetMsQueryStr < ecgRawRecordingEndMs) {
        DEBUGGING_LOG_ECG_RAW &&
          console.log(
            '🚨🚨🚨 log 🚨🚨🚨 - function * _getEcgRaw > extra init',
            [
              forwardOnsetMsQueryStr,
              forwardTerminationMsQueryStr,
              DateUtil.formatDateTime(forwardOnsetMsQueryStr),
              DateUtil.formatDateTime(forwardTerminationMsQueryStr),
            ]
          );

        apiCallList.push(
          call(ApiManager.getRawEcg, {
            ecgTestId,
            onsetMs: forwardOnsetMsQueryStr,
            terminationMs: forwardTerminationMsQueryStr,
            withBeat: isBeatStrip,
          })
        );
        if (!isRawDataOnly) {
          yield put(
            getBeatsNEctopicListRequested(
              parseInt(
                Math.max(forwardOnsetMsQueryStr - ecgRawRecordingStartMs, 0) / 4
              ),
              parseInt(
                (forwardTerminationMsQueryStr - ecgRawRecordingStartMs) / 4
              )
            )
          );
        }
      }

      const apiCallListResult = yield all(apiCallList);

      for (let apiCallResult of apiCallListResult) {
        if (apiCallResult.config.params.onsetMs === backwardOnsetMsQueryStr) {
          initBackwardResults = apiCallResult.data.results;
        }
        if (apiCallResult.config.params.onsetMs === forwardOnsetMsQueryStr) {
          initForwardResults = apiCallResult.data.results;
        }
      }

      const newBackwardLoadedList = transformRawList(
        initBackwardResults,
        ecgRawRecordingStartMs
      );
      const newForwardLoadedList = transformRawList(
        initForwardResults,
        ecgRawRecordingStartMs
      );

      const newBackForwardEcgRawList = [
        ...newBackwardLoadedList,
        ...newEcgRawList,
        ...newForwardLoadedList,
      ];

      yield put(
        getEcgRawInitSucceed(
          results,
          {
            atTime: undefined,
            isBackward: true,
            isForward: false,
            isInit: true,
            isJumpToTime: false,
            isScroll: true,
            initAtTimeLocalState: action.initAtTimeLocalState,
            backwardListLength: newBackwardLoadedList.length,
            initExtraSelection: {
              backward: {
                onset: newBackwardLoadedList.at(0)?.onsetMs,
                termination: newBackwardLoadedList.at(-1)?.terminationMs,
              },
              forward: {
                onset: newForwardLoadedList.at(0)?.onsetMs,
                termination: newForwardLoadedList.at(-1)?.terminationMs,
              },
            },
          },
          newBackForwardEcgRawList
        )
      );
    }
    // # down sampling
    // for (let i = 0; i < results.length; i++) {
    //   let ecgDownSamplingArr = [];
    //   const el = results[i];
    //   for (let j = 0; j < el.ecgData.length; j++) {
    //     if (j % 2 === 1) {
    //       ecgDownSamplingArr.push(Math.max(el.ecgData[j - 1], el.ecgData[j]));
    //     }
    //   }
    //   results[i].ecgData = ecgDownSamplingArr;
    // }
  } catch (error) {
    console.error(error);
    yield put(getEcgRawFailed(error));
  }
}

function* _getEcgsStatistics() {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const {
      data: { result },
    } = yield call(ApiManager.getEcgsStatistics, ecgTestId);
    yield put(getEcgsStatisticsSucceed(result));
  } catch (error) {
    yield put(getEcgsStatisticsFailed(error));
  }
}

function* _getReportStatistics() {
  try {
    const tid = yield select(selectEcgTestId);
    const {
      data: { result },
    } = yield call(ApiManager.getReportsStatistics, tid);
    yield put(getReportsStatisticSucceed(result));
  } catch (error) {
    yield put(getReportStatisticFailed(error));
  }
}

function* _getBothStatistics() {
  // GET_ALL_STATISTICS_REQUESTED 가 처리 중이라면 요청 생략
  const allStatisticsPending = yield select(
    (state) => state.testResultReducer.allStatistics.pending
  );
  if (allStatisticsPending) return;

  const ecgStatisticsPending = yield select(
    (state) => state.testResultReducer.ecgStatistics.pending
  );
  if (!ecgStatisticsPending) yield put(getEcgsStatisticsRequest());

  const reportStatisticsPending = yield select(
    (state) => state.testResultReducer.reportStatistics.pending
  );
  if (!reportStatisticsPending) yield put(getReportsStatisticsRequest());
}

function* _getAllStatistics(action) {
  try {
    const CONST_AF_TIME_EVENT_TYPE = TIME_EVENT_TYPE.AF;
    const selectedEcgTestId = yield select(selectEcgTestId);
    const ecgTestId = action.ecgTestId || selectedEcgTestId;
    const [
      {
        data: { result: ecgStatistics },
      },
      {
        data: { result: reportStatistics },
      },
      {
        data: { results: afMinInfoList },
      },
    ] = yield all([
      call(ApiManager.getEcgsStatistics, ecgTestId),
      call(ApiManager.getReportsStatistics, ecgTestId),
      call(ApiManager.getTimeEventList, {
        tid: ecgTestId,
        isMinimum: true,
        eventType: CONST_AF_TIME_EVENT_TYPE,
      }),
    ]);

    // AF는 30초 미만 이벤트는 리포트에서 제외하기 떄문에
    const responseList = transformTimeEvents([afMinInfoList]);
    const { leadOff: staleLeadOffList, data: staleTimeEventList } =
      yield select((state) => state.testResultReducer.timeEventsList);

    let freshLeadOffList = [...staleLeadOffList];
    let freshTimeEventList = [
      ...staleTimeEventList.filter(
        (value) =>
          value.type !==
          getEventInfoByQuery({
            timeEventType: CONST_AF_TIME_EVENT_TYPE,
          }).type
      ),
      ...responseList,
    ];

    yield put(getTimeEventsListSucceed(freshLeadOffList, freshTimeEventList));

    const data = { ecgStatistics, reportStatistics, afMinInfoList };
    yield put(getAllStatisticsSucceed(data, Date.now()));
  } catch (error) {
    yield put(getAllStatisticsFailed(error));
  }
}

function* _getEventDetail(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const isUpdateFromChart = yield select(
      ({ testResultReducer: state }) =>
        state.eventReview.sidePanelState.isUpdateFromChart
    );
    const {
      eventType,
      eventId,
      position,
      isSelectedValueUpdate,
      isIgnoreTimeJump,
      isGeminyType,
    } = action;

    let newSelectedValue = null;

    const eventInfo = getEventInfoByType(eventType);

    let responseData;
    if (eventType === EVENT_CONST_TYPES.PATIENT) {
      // PTE 데이터 조회, Findings 와 HR 의 최신 값 확인을 위함
      /** @type {import('component/hook/useGetPatientEvents').PatientTriggeredEventInfo} */
      const patientEvent = yield select(({ testResultReducer: state }) =>
        state.patientEvents.find((value) => value.position === position)
      );
      if (!isUpdateFromChart && !isIgnoreTimeJump) {
        // 이벤트 조회시 onset 시점으로 ECG 차트 목록 이동
        yield put(setChartSelectedStrip(patientEvent.eventTimeMs));
      }
      const {
        data: { results },
      } = yield call(ApiManager.getPatientTriggerEventList, {
        tid: ecgTestId,
        position,
        excludeSleep: true,
      });
      responseData = results[0];

      // 응답된 데이터로 Patient Triggered Event 목록 업데이트
      yield call(_setPatientTriggeredEventList, {
        ...responseData,
      });
    } else if (eventInfo?.ectopicType || eventInfo?.geminyType) {
      // Ectopic 데이터 조회
      let ectopicInfo;
      const { beatType, ectopicType, geminyType } = eventInfo;
      const sortOrder = yield select(selectEventReviewSortOrder);
      const { queryOrderBy } = sortOrder[eventType]; // 정렬 정보

      if (position) {
        const { data: eventDetailData } = yield select(selectEventDetail);
        const inCludedGeminyEvent = eventDetailData?.geminy;

        const apiCallFunction = determineApiCallFunction({
          geminyType,
          inCludedGeminyEvent,
        });
        const apiParams = createApiParams({
          ecgTestId,
          beatType,
          position,
          queryOrderBy,
          inCludedGeminyEvent,
          geminyType,
          ectopicType,
        });

        // 포지션 입력으로 포지 이동(get event detail by event position)
        const {
          data: { results },
        } = yield call(apiCallFunction, apiParams);
        ectopicInfo = results[0];
      } else {
        // prev, next 버튼으로 포지션 이동(get event detail by waveformIndex)
        const {
          data: { results },
        } = yield call(ApiManager.getEctopicListFilterWaveformIndexRange, {
          ecgTestId,
          onsetWaveformIndex: eventId,
          terminationWaveformIndex: eventId,
          ordering: queryOrderBy,
        });
        ectopicInfo = results[0];
        if (isGeminyType) {
          ectopicInfo.waveformIndex = ectopicInfo.geminy.waveformIndex;
        }

        const ecgStatistics = yield select(
          (state) => state.testResultReducer.ecgStatistics
        );
        const filteredBeatEventSection = getEventInfoByQuery({
          beatType: ectopicInfo.beatType,
          ectopicType: ectopicInfo.ectopicType,
        })?.eventSection;

        /**
         * # 이벤트 개수 동기화 작업
         *   - event review tab의 차트리스트에서 이벤트 선택시 선택된 이벤트 개수가
         *     위 api(getEctopicListFilterWaveformIndexRange) response값과 다를 경우 statistic의 ecgStatistics를 update
         */
        if (
          ecgStatistics.data[filteredBeatEventSection] !==
          ectopicInfo.totalEventCount
        ) {
          const copyEcgStatistics = rfdcClone(ecgStatistics.data);
          copyEcgStatistics[filteredBeatEventSection] =
            ectopicInfo.totalEventCount;
          yield put(getEcgsStatisticsSucceed(copyEcgStatistics));
        }
      }

      responseData = {
        ...ectopicInfo,
        onsetWaveformIndex: ectopicInfo.waveformIndex.at(0),
        terminationWaveformIndex: ectopicInfo.waveformIndex.at(-1),
      };

      if (!isUpdateFromChart && !isIgnoreTimeJump) {
        // 이벤트 조회시 onset 시점으로 ECG 차트 목록 이동
        const { recordingStartMs } = yield select(selectRecordingTime);
        yield put(
          setChartSelectedStrip(
            recordingStartMs + responseData.onsetWaveformIndex * 4
          )
        );
      }

      if (isSelectedValueUpdate) {
        newSelectedValue = {
          position: responseData.position,
          waveformIndex: responseData.waveformIndex,
        };
      }
    } else {
      // Time Event 데이터 조회
      let timeEventId;
      if (position) {
        const timeEventInfo = yield select(({ testResultReducer }) =>
          testResultReducer.timeEventsList.data.find(
            (value) => value.position === position && value.type === eventType
          )
        );
        if (!isUpdateFromChart && !isIgnoreTimeJump) {
          // 이벤트 조회시 onset 시점으로 ECG 차트 목록 이동
          yield put(setChartSelectedStrip(timeEventInfo.onsetMs));
        }
        timeEventId = timeEventInfo.timeEventId;
      } else {
        timeEventId = eventId;
      }
      const {
        data: { result },
      } = yield call(ApiManager.getTimeEventDetail, {
        timeEventId,
      });
      responseData = result;
      if (isSelectedValueUpdate) {
        newSelectedValue = {
          position: yield select(({ testResultReducer }) =>
            testResultReducer.timeEventsList.data.find(
              (value) => value.id === timeEventId
            )
          ),
          timeEventId: timeEventId,
        };
      }
    }

    // Events 텝에서 조회 시 selectedValue 가 수정될 필요 있음(position, timeEventId, waveformIndex)
    const prevSelectedValueList = yield select(selectSelectedValueList);
    const newSelectedValueList = getUpdatedSelectedValueList(
      prevSelectedValueList,
      newSelectedValue,
      isSelectedValueUpdate
    );

    yield put(getEventDetailSucceed(responseData, newSelectedValueList));
  } catch (error) {
    console.error(error);
    yield put(getEventDetailFailed(error));
  }

  function determineApiCallFunction({ geminyType, inCludedGeminyEvent }) {
    if (!geminyType) return ApiManager.getEctopicListFilterType;
    if (inCludedGeminyEvent) return ApiManager.getGeminyListFilterWaveformIndex;
    return ApiManager.getGeminyListFilterType;
  }

  function createApiParams({
    ecgTestId,
    beatType,
    position,
    queryOrderBy,
    inCludedGeminyEvent,
    geminyType,
    ectopicType,
  }) {
    if (inCludedGeminyEvent) {
      return {
        ecgTestId,
        ordering: queryOrderBy,
        waveformIndex: inCludedGeminyEvent.waveformIndex[0],
      };
    }

    return {
      ecgTestId,
      beatType,
      position,
      ordering: queryOrderBy,
      ...optionalParameter({
        key: 'geminyType',
        value: geminyType,
        condition: isNotNullOrUndefined(geminyType),
      }),
      ...optionalParameter({
        key: 'ectopicType',
        value: ectopicType,
        condition: isNotNullOrUndefined(ectopicType),
      }),
    };
  }

  function getUpdatedSelectedValueList(
    prevSelectedValueList,
    newSelectedValue,
    isSelectedValueUpdate
  ) {
    if (prevSelectedValueList.length !== 1 || !isSelectedValueUpdate) {
      return prevSelectedValueList;
    }

    const prevSelectedValue = prevSelectedValueList[0];
    return [
      {
        type: prevSelectedValue.type,
        position: newSelectedValue.position ?? prevSelectedValue.position,
        timeEventId:
          newSelectedValue.timeEventId ?? prevSelectedValue.timeEventId,
        waveformIndex:
          newSelectedValue.waveformIndex ?? prevSelectedValue.waveformIndex,
      },
    ];
  }
}

function* fetchPTEEvent(ecgTestId, position, prevSelectedValueList) {
  const {
    data: { results },
  } = yield call(ApiManager.getPatientTriggerEventList, {
    tid: ecgTestId,
    position,
    excludeSleep: true,
    reportIncluded: true,
  });

  const responseData = {
    ...results[0],
    reportSection: REPORT_SECTION.PTE,
  };

  yield put(setChartSelectedStrip(responseData.eventTimeMs));
  yield put(getEventDetailSucceed(responseData, prevSelectedValueList));
  yield call(_setPatientTriggeredEventList, responseData);

  if (responseData.representativeStrip[0]) {
    const { representativeOnsetIndex, representativeTerminationIndex } =
      responseData.representativeStrip[0];
    yield put(
      setRepresentativeStripInfo({
        selectedMs: null,
        representativeOnsetIndex: representativeOnsetIndex,
        representativeTerminationIndex: representativeTerminationIndex,
      })
    );
  } else {
    yield put(resetRepresentativeStripInfo());
  }

  return responseData;
}

function* fetchReportEvent(
  rid,
  reportSection,
  position,
  recordingStartMs,
  prevSelectedValueList
) {
  const {
    data: { results },
  } = yield call(ApiManager.getReportEvents, {
    rid,
    reportSection,
    position,
  });

  const responseData = results[0];
  const {
    representativeOnsetIndex,
    representativeTerminationIndex,
    timeEvent: timeEventDetail,
  } = responseData;

  const selectedTimeWaveformIndex =
    representativeOnsetIndex +
    Math.floor((representativeTerminationIndex - representativeOnsetIndex) / 2);

  yield put(
    setChartSelectedStrip(recordingStartMs + selectedTimeWaveformIndex * 4)
  );

  if (timeEventDetail?.ectopicType) {
    const ectopicEventType = getEventInfoByQuery({
      beatType: BEAT_TYPE[timeEventDetail.eventType],
      ectopicType: timeEventDetail.ectopicType,
    }).type;

    const isGeminyType = Boolean(timeEventDetail?.extra?.geminyType);

    yield put(
      getEventDetailRequested(
        ectopicEventType,
        timeEventDetail.onsetWaveformIndex,
        null,
        false,
        true,
        isGeminyType
      )
    );
  } else {
    yield put(getEventDetailSucceed(timeEventDetail, prevSelectedValueList));
  }

  yield put(
    setRepresentativeStripInfo(getInitRepresentativeStripInfo(responseData))
  );

  return responseData;
}

function* _getReportEvent(action) {
  try {
    const prevSelectedValueList = yield select(selectSelectedValueList);
    const { reportSection, position } = action;

    if (position < 1) {
      yield put(getEventDetailSucceed(null, prevSelectedValueList));
      return;
    }

    let responseData;

    if (reportSection === REPORT_SECTION.PTE) {
      const ecgTestId = yield select(selectEcgTestId);
      responseData = yield call(
        fetchPTEEvent,
        ecgTestId,
        position,
        prevSelectedValueList
      );
    } else {
      const rid = yield select(selectReportId);
      const { recordingStartMs } = yield select(selectRecordingTime);
      responseData = yield call(
        fetchReportEvent,
        rid,
        reportSection,
        position,
        recordingStartMs,
        prevSelectedValueList
      );
    }

    yield put(getReportEventsSucceed(responseData));
  } catch (error) {
    console.error(error);
    yield put(getReportEventsFailed(error));
  }
}

function* _postReportEvent(action) {
  try {
    const { newReportEventInfo } = action;
    const rid = yield select(selectReportId);
    const param = { ...newReportEventInfo, rid };
    if (!param['timeEventId']) delete param['timeEventId'];
    if (!param['onsetWaveformIndex']) delete param['onsetWaveformIndex'];
    const {
      data: { result },
    } = yield call(ApiManager.postReportEvents, param);
    yield put(postReportEventSucceed(result));
  } catch (error) {
    yield put(postReportEventFailed(error));
  }
}

function* _updateReportEvent(action) {
  try {
    const { reportEventId, newReportEventInfo } = action;
    yield call(ApiManager.updateReportEvents, {
      reportEventId,
      ...newReportEventInfo,
    });
    yield put(updateReportEventSucceed({}, action.isPreUpdate));
    if (action.afterAction) yield put(action.afterAction);
  } catch (error) {
    yield put(updateReportEventFailed(error, action.isPreUpdate));
  }
}

function* _deleteReportEvent(action) {
  try {
    const { reportEventId } = action;

    yield call(ApiManager.deleteReportEvents, { reportEventId });
    yield put(deleteReportEventSucceed());
    if (action.afterAction) yield put(action.afterAction);
  } catch (error) {
    yield put(deleteReportEventFailed(error));
  }
}

function* _getNextReportEventHandler(action) {
  const { editedReportSection } = action;
  const sideTabValue = yield select(selectSideTabValue);
  const selectedValue = yield select(
    (state) => selectSelectedValueList(state)[0]
  );
  const reportStatisticsData = yield select(
    ({ testResultReducer: state }) => state.reportStatistics.data
  );
  /** @type {import('redux/container/fragment/test-result/side-panel/ReportEventEditorFragmentContainer').ReportEvent} */
  const prevReportDetailData = yield select(
    ({ testResultReducer: state }) => state.reportDetail.data
  );

  try {
    yield put(getReportsStatisticsRequest());
    if (sideTabValue !== EVENT_GROUP_TYPE.REPORT || !selectedValue)
      throw new Error('');

    const { position: curPosition } = selectedValue;
    const curSectionNum = reportStatisticsData[editedReportSection];
    // console.log({
    //   curPosition,
    //   curSectionNum,
    // });

    if (curPosition < curSectionNum) {
      // 마지막 Position 이 아닌 Report Event 가 삭제된 경우
      yield call(_getReportEvent, {
        reportSection: editedReportSection,
        position: curPosition,
      });
    } else {
      // 마지막 Position 이 삭제된 경우
      yield call(_getReportEvent, {
        reportSection: editedReportSection,
        position: curPosition - 1,
      });
      yield put(
        setSidePanelSelectedValueList(
          [{ ...selectedValue, position: curPosition - 1 }],
          false,
          true
        )
      );
    }
  } catch (error) {
    yield put(getReportEventsSucceed(prevReportDetailData));
  }
}

function* _updatePteReportInfo(action) {
  try {
    const { isRemove } = action.payload;
    const { recordingStartMs } = yield select(selectRecordingTime);
    const {
      id: pteId,
      eventBy,
      eventTimeMs,
    } = yield select((state) => state.testResultReducer.eventDetail.data);

    let newPTEInfo = {};
    let patientEventInfo = null;

    if (isRemove) {
      // PTE 를 Report 에서 제외하는 경우, Trigger 조건에 해당하는 기본 90초 위치로 초기화
      newPTEInfo.reportIncluded = false;

      const eventTimeWaveformIndex = (eventTimeMs - recordingStartMs) / 4;
      if (eventBy === Const.PATIENT_EVENT_BY.BUTTON) {
        // 버튼 PTE 90초 구간 초기화(PTE 지점으로부터 이전, 이후 45초)
        const fortyFiveSecMs = 45000;
        const fortyFiveSecWaveformLength = fortyFiveSecMs / 4;

        newPTEInfo.triggerOnsetMs = eventTimeMs - fortyFiveSecMs;
        newPTEInfo.triggerTerminationMs = eventTimeMs + fortyFiveSecMs;
        newPTEInfo.triggerOnsetWaveformIndex =
          eventTimeWaveformIndex - fortyFiveSecWaveformLength;
        newPTEInfo.triggerTerminationWaveformIndex =
          eventTimeWaveformIndex + fortyFiveSecWaveformLength;
      } else {
        // 챗봇 PTE 90초 구간 초기화(PTE 지점으로부터 이전 80초, 이후 10초)
        const eightySecMs = 80000;
        const tenSecMs = 10000;
        const eightySecWaveformLength = eightySecMs / 4;
        const tenSecWaveformLength = tenSecMs / 4;

        newPTEInfo.triggerOnsetMs = eventTimeMs - eightySecMs;
        newPTEInfo.triggerTerminationMs = eventTimeMs + tenSecMs;
        newPTEInfo.triggerOnsetWaveformIndex =
          eventTimeWaveformIndex - eightySecWaveformLength;
        newPTEInfo.triggerTerminationWaveformIndex =
          eventTimeWaveformIndex + tenSecWaveformLength;
      }
    } else {
      newPTEInfo.reportIncluded = true;

      const reportEventEditorState = yield select(selectReportEventEditorState);
      const {
        selectedReportSection,
        mainRepresentativeInfo,
        subRepresentativeInfo,
      } = reportEventEditorState;
      newPTEInfo.triggerOnsetMs =
        mainRepresentativeInfo.representativeOnsetIndex * 4 + recordingStartMs;
      newPTEInfo.triggerTerminationMs =
        mainRepresentativeInfo.representativeTerminationIndex * 4 +
        recordingStartMs;
      newPTEInfo.triggerOnsetWaveformIndex =
        mainRepresentativeInfo.representativeOnsetIndex;
      newPTEInfo.triggerTerminationWaveformIndex =
        mainRepresentativeInfo.representativeTerminationIndex;

      // PTE 의 Report Event(보조 Strip) 정보있을 시 구성
      if (!subRepresentativeInfo.isRemoved) {
        const rid = yield select(selectReportId);
        patientEventInfo = {
          rid,
          patientEventId: pteId,
          reportSection: selectedReportSection,
          representativeOnsetIndex:
            subRepresentativeInfo.representativeOnsetIndex,
          representativeTerminationIndex:
            subRepresentativeInfo.representativeTerminationIndex,
          amplitudeRate: subRepresentativeInfo.amplitudeRate,
        };
      }
    }

    // PTE 정보 업데이트 요청, Report Event(보조 Strip) 제거됨
    const {
      data: { result: freshPTE },
    } = yield call(ApiManager.patchPatientTriggerEvent, {
      pteId: pteId,
      ...newPTEInfo,
    });
    // PTE 의 Report Event(보조 Strip) 정보있을 시 생성 요청
    let freshRepresentativeStrip = [];
    if (patientEventInfo) {
      const {
        data: { result: freshPTEReportEvent },
      } = yield call(ApiManager.postReportEvents, patientEventInfo);
      freshRepresentativeStrip.push(freshPTEReportEvent);
    }
    // 삭제인 경우 Report Statistics 중 PTE Section 의 갯수 감소, 반대 경우는 Events Tab 에서만 가능한 시나리오이며 Report Tab 이동 시 Report Statistics 를 조회 하기 때문에 제어 불필요
    if (isRemove) {
      yield put(adjustReportStatistic(REPORT_SECTION.PTE, -1));
    }

    // 응답된 데이터로 Patient Triggered Event 목록 업데이트
    const updatedPTEInfo = {
      ...freshPTE,
      representativeStrip: freshRepresentativeStrip,
    };
    yield call(_setPatientTriggeredEventList, updatedPTEInfo);

    const sideTabValueState = yield select(selectSideTabValue);
    if (sideTabValueState === EVENT_GROUP_TYPE.REPORT) {
      if (patientEventInfo) {
        const { representativeOnsetIndex, representativeTerminationIndex } =
          patientEventInfo;
        yield put(
          setRepresentativeStripInfo({
            selectedMs: null,
            representativeOnsetIndex: representativeOnsetIndex,
            representativeTerminationIndex: representativeTerminationIndex,
          })
        );
      } else {
        yield put(resetRepresentativeStripInfo());
      }
    }
    if (sideTabValueState === EVENT_GROUP_TYPE.REPORT && isRemove) {
      // PTE 정보 조회요청 발생
      yield put(getNextReportEvent(REPORT_SECTION.PTE));
    } else {
      // 응답된 데이터로 조회중인 Event Detail State 업데이트
      // 기존 selectedValue 참조 필요
      const prevSelectedValueList = yield select(selectSelectedValueList);
      yield put(getEventDetailSucceed(updatedPTEInfo, prevSelectedValueList));
      // 응답된 데이터로 조회중인 Report Event Detail State 업데이트
      yield put(
        getReportEventsSucceed({
          ...updatedPTEInfo,
          reportSection: REPORT_SECTION.PTE,
        })
      );
    }

    yield put(updatePTEReportInfoSucceed({}));
  } catch (error) {
    yield put(updatePTEReportInfoFailed(error));
  }
}

/** 업데이트된 데이터로 Patient Triggered Event 목록 업데이트 */
function* _setPatientTriggeredEventList(updatedPTEInfo) {
  /** @type {Array<import('component/hook/useGetPatientEvents').PatientTriggeredEventInfo>} */
  const patientEventList = yield select(
    ({ testResultReducer: state }) => state.patientEvents
  );

  const stalePTEInfo = patientEventList.find(
    (value) => value.id === updatedPTEInfo.id
  );
  const freshPatientList = rfdcClone(
    patientEventList.filter((value) => value.id !== updatedPTEInfo.id)
  );

  freshPatientList.push({
    ...updatedPTEInfo,
    position: stalePTEInfo.position,
    eventTimeWaveformIndex: stalePTEInfo.eventTimeWaveformIndex,
  });
  freshPatientList.sort((a, b) => a.position - b.position);
  yield put(setPatientTriggeredEventList(freshPatientList));
}

function* _postBeats(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const {
      reqBody,
      onsetWaveformIndex,
      terminationWaveformIndex,
      suffix,
      tabType,
    } = action;

    const requestAt = new Date().getTime();

    const requestStatement = {
      requestType: suffix,
      ecgTestId: ecgTestId,
      reqBody: reqBody,
    };

    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ data }) {
      yield put(
        postBeatsSucceed(data, {
          requestAt,
          validResult: validateBeatEditResponse(reqBody, data.result),
          editTargetBeatType: reqBody.beatType,
        })
      );

      if (tabType === TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW) {
        const { searchOnsetRequest, searchTerminationRequest } =
          getSearchBeatsNEctopicListRangeAfterUpdateEvent(
            onsetWaveformIndex,
            terminationWaveformIndex
          );

        yield put(
          getBeatsNEctopicListRequested(
            searchOnsetRequest,
            searchTerminationRequest
          )
        );
      }
    }
    function* failedCallback(error) {
      yield put(postBeatsFailed(error, action));
    }
  } catch (error) {
    console.error(error);
    yield put(postBeatsFailed(error, action));
  }
}

function* _patchBeats(action) {
  try {
    // 선택된 Event 의 전체 철회 상황
    const isWholeUnMark = yield select(selectIsWholeUnMark);
    if (isWholeUnMark) yield put(setEventDetailEditPending(true));

    const ecgTestId = yield select(selectEcgTestId);
    const {
      reqBody,
      onsetWaveformIndex,
      terminationWaveformIndex,
      suffix,
      tabType,
    } = action;

    const requestAt = new Date().getTime();
    const requestStatement = {
      requestType: suffix,
      ecgTestId: ecgTestId,
      reqBody: reqBody,
    };

    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ data }) {
      if (isWholeUnMark) yield put(setEventDetailEdited());

      yield put(
        patchBeatsSucceed(data, tabType, {
          requestAt,
          validResult: validateBeatEditResponse(reqBody, data.result),
          editTargetBeatType: reqBody.beatType,
        })
      );

      if (
        action.tabType === TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW ||
        action.tabType === TEN_SEC_STRIP_DETAIL.TAB.ARRHYTHMIA_CONTEXTMENU
      ) {
        const { searchOnsetRequest, searchTerminationRequest } =
          getSearchBeatsNEctopicListRangeAfterUpdateEvent(
            onsetWaveformIndex,
            terminationWaveformIndex
          );
        yield put(
          getBeatsNEctopicListRequested(
            searchOnsetRequest,
            searchTerminationRequest,
            { isWholeUnMark }
          )
        );
      }
    }
    function* failedCallback(error) {
      yield put(patchBeatsFailed(error, action));
    }
  } catch (error) {
    console.error(error);
    yield put(patchBeatsFailed(error, action));
  }
}

function* _deleteBeats(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const {
      reqBody,
      onsetWaveformIndex,
      terminationWaveformIndex,
      suffix,
      tabType,
    } = action;
    const requestStatement = {
      requestType: suffix,
      ecgTestId: ecgTestId,
      reqBody: reqBody,
    };

    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ status }) {
      if (status !== StatusCode.NO_CONTENT) {
        yield put(deleteBeatsFailed({}, action));
        return;
      }
      yield put(deleteBeatsSucceed(reqBody));

      if (tabType === TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW) {
        const { searchOnsetRequest, searchTerminationRequest } =
          getSearchBeatsNEctopicListRangeAfterUpdateEvent(
            onsetWaveformIndex,
            terminationWaveformIndex
          );
        yield put(
          getBeatsNEctopicListRequested(
            searchOnsetRequest,
            searchTerminationRequest
          )
        );
      }
    }
    function* failedCallback(error) {
      yield put(deleteBeatsFailed(error, action));
    }
  } catch (error) {
    yield put(deleteBeatsFailed(error, action));
  }
}

function* _requestPrintReport(action) {
  try {
    const { data } = yield call(ApiManager.requestPrintReport, {
      tid: action.tid,
      ...action.request,
    });
    const { error, result } = data;

    yield put(requestPrintReportSucceed(result));

    yield call(ApiManager.patchReportUploadStatusById, {
      ecgTestId: action.tid,
      body: { isUploadedToEmr: false },
    });

    // 검사목록의 emr upload status는 ecgTest.fetch 데이터에서 확인
    const { isUploadedToEmr } = data.result;
    const targetFetchData = yield select(selectFetchData);
    if (targetFetchData) {
      const updatedData = targetFetchData.data.map((item) => {
        if (item.tid === action.tid) {
          return {
            ...item,
            isUploadedToEmr: isUploadedToEmr,
            emrUploadedBy: data.result.emrUploadedBy,
            emrUploadedDatetime: data.result.emrUploadedDatetime,
          };
        }
        return item;
      });
      yield put(setReportDownloadStatusCheckSucceed(updatedData));
    }

    return;
  } catch (error) {
    console.log(error);
    yield put(requestPrintReportFailed(error));
  }
}

function* _postTimeEvent(action) {
  try {
    const { params } = action;
    const isWholeUnMark = yield select(selectIsWholeUnMark); // 선택된 Event 의 전체 철회 상황
    if (isWholeUnMark) {
      yield put(setEventDetailEditPending(true));
    }

    const calls = yield call(preProcessEditedTimeEvent, params);
    const responseData = yield all(calls);
    const data = responseData?.[0]?.data ?? { result: null };

    if (isWholeUnMark) {
      yield put(setEventDetailEdited());
    }

    yield put(postTimeEventSucceed(data.result));

    const eventTypeToRequest = AV_BLOCK_LIST.includes(params.eventType)
      ? AV_BLOCK_LIST
      : [params.eventType];

    for (const eventType of eventTypeToRequest) {
      yield put(
        getTimeEventsListRequested(eventType, { isWholeUnMark, callback })
      );
    }
    yield put(getBothStatisticsDelegated());

    function* callback() {
      yield call(postProcessEditedTimeEvent, params);
    }
  } catch (error) {
    console.error(error);
    yield delay(0);
    yield put(postTimeEventFailed(error));
  }
}

function* _requestMoveEctopicPositionRequested(action) {
  try {
    const { eventType, beatType, ectopicType, currentValue, isNext } =
      action.params;
    const ecgTestId = yield select(selectEcgTestId);
    const sortOrder = yield select(selectEventReviewSortOrder);
    const { queryOrderBy: ordering } = sortOrder[eventType]; // 정렬 정보
    const {
      data: { results },
    } = yield call(ApiManager.getEctopicListFilterType, {
      ecgTestId,
      beatType,
      ectopicType,
      currentValue,
      isNext,
      ordering,
    });

    const ectopicInfo = results[0];
    const responseData = {
      ...ectopicInfo,
      onsetWaveformIndex: ectopicInfo.waveformIndex.at(0),
      terminationWaveformIndex: ectopicInfo.waveformIndex.at(-1),
    };
    const { recordingStartMs } = yield select(selectRecordingTime);
    yield put(
      setChartSelectedStrip(recordingStartMs + results[0].waveformIndex[0] * 4)
    );

    const prevSelectedValueList = yield select(selectSelectedValueList);
    const newSelectedValue = {
      position: responseData.position,
      waveformIndex: responseData.waveformIndex,
    };
    const newSelectedValueList = getUpdatedSelectedValueList(
      prevSelectedValueList,
      newSelectedValue,
      true // isSelectedValueUpdate
    );

    yield put(getEventDetailSucceed(responseData, newSelectedValueList));

    //  update total event (comparing with ecg statistics data and fresh ectopicInfo)
    const ecgStatistics = yield select(
      (state) => state.testResultReducer.ecgStatistics
    );
    const filteredBeatEventSection = getEventInfoByQuery({
      beatType: ectopicInfo.beatType,
      ectopicType: ectopicInfo.ectopicType,
    })?.eventSection;

    /**
     * # 이벤트 개수 동기화 작업
     *   - event review tab의 차트리스트에서 포지션 이동시 이동한 이벤트가
     *     위 api(getEctopicListFilterType) response값과 다를 경우 statistic의 ecgStatistics를 update
     */
    if (
      ecgStatistics.data[filteredBeatEventSection] !==
      ectopicInfo.totalEventCount
    ) {
      const copyEcgStatistics = rfdcClone(ecgStatistics.data);
      copyEcgStatistics[filteredBeatEventSection] = ectopicInfo.totalEventCount;
      yield put(getEcgsStatisticsSucceed(copyEcgStatistics));
    }

    function getUpdatedSelectedValueList(
      prevSelectedValueList,
      newSelectedValue,
      isSelectedValueUpdate
    ) {
      if (prevSelectedValueList.length !== 1 || !isSelectedValueUpdate) {
        return prevSelectedValueList;
      }

      const prevSelectedValue = prevSelectedValueList[0];
      return [
        {
          type: prevSelectedValue.type,
          position: newSelectedValue.position ?? prevSelectedValue.position,
          timeEventId:
            newSelectedValue.timeEventId ?? prevSelectedValue.timeEventId,
          waveformIndex:
            newSelectedValue.waveformIndex ?? prevSelectedValue.waveformIndex,
        },
      ];
    }
  } catch (error) {
    console.error('error: ', error);
  }
}

// Saga
export function* saga() {
  /* 
    :: TAB :: event review (ECG chart list)
    :: TAB :: event review (Arrhythmia Edit visualize)
    :: TAB :: event review (side panel)
    :: 10s strip detail - beat edit ::
  */

  // :: TAB :: event review (ECG chart list)
  yield takeLatest(INITIALIZE, _initializeState);
  yield debounce(200, GET_ECG_TEST_REQUESTED, _getEcgTest);
  yield takeLatest(PATCH_ECG_TEST_REQUESTED, _patchEcgTest);
  yield takeLatest(GET_DAILY_HEART_RATE_REQUESTED, _getDailyHeartRate);
  yield takeLatest(GET_ECGRAW_INIT_REQUESTED, _getEcgRaw);
  yield takeLatest(GET_ECGRAW_REQUESTED, _getEcgRaw);
  yield takeLatest(GET_ECGRAW_BACKWARD_REQUESTED, _getEcgRaw);
  yield takeLatest(GET_ECGRAW_FORWARD_REQUESTED, _getEcgRaw);
  yield takeLatest(SET_SELECTION_STRIP, _selectionStripHandler);
  yield takeLatest(SET_TENSEC_STRIP, _tenSecStripHandler);
  yield takeLatest(
    SET_SIDE_PANEL_SELECTED_VALUE_LIST,
    _setSelectedValueListHandler
  );
  yield takeLatest(SET_SORT_ORDER, _setSortOrderHandler);

  // :: TAB :: event review (Arrhythmia Edit visualize)
  yield takeEvery(GET_TIME_EVENTS_LIST_REQUESTED, _getTimeEventsList);
  yield takeEvery(GET_BEATS_N_ECTOPIC_LIST_REQUESTED, _getBeatsNEctopicByRange);

  // :: TAB :: event review (side panel)
  yield takeLatest(
    SET_REPRESENTATIVE_STRIP_INFO,
    _reportRepresentativeTenSecStripHandler
  );
  yield takeLatest(GET_ALL_STATISTICS_REQUESTED, _getAllStatistics);
  yield takeLatest(GET_BOTH_STATISTICS_DELEGATED, _getBothStatistics);
  yield takeLatest(GET_ECGS_STATISTICS_REQUESTED, _getEcgsStatistics);
  yield takeLatest(GET_REPORTS_STATISTICS_REQUESTED, _getReportStatistics);
  yield takeLatest(GET_EVENT_DETAIL_REQUESTED, _getEventDetail);
  yield takeLatest(GET_REPORT_EVENTS_REQUESTED, _getReportEvent);
  yield takeLatest(POST_REPORT_EVENT_REQUESTED, _postReportEvent);
  yield takeLatest(UPDATE_REPORT_EVENT_REQUESTED, _updateReportEvent);
  yield takeLatest(DELETE_REPORT_EVENT_REQUESTED, _deleteReportEvent);
  yield takeLatest(GET_NEXT_REPORT_EVENT, _getNextReportEventHandler);
  yield takeEvery(UPDATE_PTE_REPORT_INFO_REQUESTED, _updatePteReportInfo); // create beat

  // :: 10s strip detail - beat edit::
  yield takeEvery(POST_BEATS_REQUESTED, _postBeats); // create beat
  yield takeEvery(PATCH_BEATS_REQUESTED, _patchBeats); // modify beat
  yield takeEvery(DELETE_BEATS_REQUESTED, _deleteBeats); // delete beat
  yield takeEvery(POST_TIME_EVENT_REQUESTED, _postTimeEvent); // selection strip

  // :: Report 생성
  yield takeLatest(REQUEST_PRINT_REPORT_REQUESTED, _requestPrintReport);

  // :: ectopic position 이동
  yield takeLatest(
    REQUEST_MOVE_ECTOPIC_POSITION_REQUESTED,
    _requestMoveEctopicPositionRequested
  );
}
