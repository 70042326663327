import axios, { CancelToken } from 'axios';

import LocalStorageKey from 'constant/LocalStorageKey';
import { sendMessage, WebViewMessageConst } from 'constant/WebViewMessageConst';

import FileUtil from 'util/FileUtil';
import TrickUtil from 'util/TrickUtil';

import UrlList from 'network/UrlList';
import StatusCode from 'network/StatusCode';
import { ErrorCode } from 'network/ErrorConst';

import LocalStorageManager from 'manager/LocalStorageManager';

const REDIRECT_EXCEPT_URL_DICT = {
  [UrlList.getEmailCheckUrl()]: true,
  [UrlList.getPasswordResetUrl()]: true,
  [UrlList.getPasswordResetEmailUrl()]: true,
  [UrlList.getPasswordResetEmailCompleteUrl()]: true,
  [UrlList.getLoginUrl()]: true,
};

/** 401 Error 이지만, 토큰 만료가 아닌 다른 예외 처리가 필요한 API */
const ALERT_TOKEN_EXPIRE_EXCEPT_URL_DICT = [
  UrlList.getEmailCheckUrl(),
  UrlList.getPasswordResetUrl(),
  UrlList.getPasswordResetEmailUrl(),
  UrlList.getPasswordResetEmailCompleteUrl(),
  UrlList.getPasswordResetWithOldPasswordUrl(),
  UrlList.getLoginUrl(),
];
const convertRegexPattern = (url) =>
  '(' +
  url
    .split('undefined')
    .reduce(
      (acc, cur, idx) => (idx === 0 ? cur : `${acc})([^/]*)(${cur}`),
      ''
    ) +
  ')';
/** 401 외 Error 이지만, 별도의 예외 처리가 필요한 API */
const ALERT_NETWORK_ERROR_EXCEPT_URL_DICT = [
  // UrlList.getEmailCheckUrl(),
  // UrlList.getPasswordResetUrl(),
  // UrlList.getPasswordResetEmailUrl(),
  // UrlList.getPasswordResetEmailCompleteUrl(),
  // UrlList.getEcgTestsUrl(), // ???
  convertRegexPattern(UrlList.getRevertUrl()),
  convertRegexPattern(UrlList.getLoginUrl()),
  convertRegexPattern(UrlList.getPatchEcgsIdExplorerUrl()),
  convertRegexPattern(UrlList.bulkConfirmUrl()),
  convertRegexPattern(UrlList.getReportEventsUrl()),
  convertRegexPattern(UrlList.getAddBeatsUrl()),
  convertRegexPattern(UrlList.getTimeEventsDirectUrl()),
  convertRegexPattern(UrlList.getTimeEventsIdUrl()),
  convertRegexPattern(UrlList.getEcgTestsMedicalFeeExcelDownloadUrl()),
  convertRegexPattern(UrlList.getEcgTestsPaymentExcelDownloadUrl()),
];

// prettier-ignore
// axios.defaults.baseURL = 'https://api.example.com';
// application/json
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-Memo-Client-Name'] =
  process.env.REACT_APP_CLIENT_NAME;
axios.defaults.timeout = 30000;
axios.defaults.validateStatus = function (statusCode) {
  switch (statusCode) {
    // Successful 2xx
    case StatusCode.OK:
    case StatusCode.CREATED:
    case StatusCode.ACCEPTED:
    case StatusCode.NON_AUTHORITATIVE_INFORMATION:
    case StatusCode.NO_CONTENT:
    case StatusCode.RESET_CONTENT:
    case StatusCode.PARTIAL_CONTENT:
      // Client Error 4xx
      // case StatusCode.BAD_REQUEST:
      // case StatusCode.UNAUTHORIZED:
      // case StatusCode.NOT_FOUND:
      // case StatusCode.CONFLICT:
      return true;
    default:
      return false;
  }
};

// Request interceptor to encrypt params
axios.interceptors.request.use(
  async function (config) {
    if (config.headers['Disable-Custom-Auth']) {
      return config;
    }

    const tokenType = LocalStorageManager.getItem(LocalStorageKey.TOKEN_TYPE);
    const accessToken = LocalStorageManager.getItem(
      LocalStorageKey.ACCESS_TOKEN
    );
    if (accessToken) {
      config.headers.common['Authorization'] = `${tokenType} ${accessToken}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Response interceptor to decrypt params
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

const sendRequest = async (
  requestMethod,
  url,
  params,
  config,
  callback,
  notAnyMore = false
) => {
  try {
    let response;
    if (params?.cancelToken) {
      response = await requestMethod(url, params, config).catch(function (
        thrown
      ) {
        if (axios.isCancel(thrown)) {
          console.info('canceled api', thrown);
        } else {
          console.info('canceled api');
        }
      });
    } else {
      response = await requestMethod(url, params, config);
    }

    if (callback && typeof callback === 'function') {
      callback(response.status, response.data, response.headers);
    }
    return response;
  } catch (error) {
    if (requestMethod && typeof requestMethod === 'function' && url && params) {
      const errorData =
        error.config.responseType === 'blob'
          ? await error.data.text()
          : error.data;
      const errorCode = errorData?.error?.errorCode ?? errorData;
      const errorMessage = errorData?.error?.errorMessage ?? errorData;

      let errorDetails = errorData?.error?.details;
      const status = error.status ?? StatusCode.EXPECTATION_FAILED;
      const restClientError = new Error();
      restClientError.name = 'RestClient Error';
      restClientError.status = status;
      restClientError.errorMessage = errorMessage;
      restClientError.errorCode = errorCode;
      restClientError.errorDetails = errorDetails;

      if (!notAnyMore && status === StatusCode.UNAUTHORIZED) {
        if (
          errorCode === ErrorCode.EXPIRED_ACCESS_TOKEN // Access Token 의 만료로 401 응답인 경우의 Error Message 확인
        ) {
          const refreshTokenResult = await refreshToken();

          if (refreshTokenResult) {
            // Try again, 재시도 중 응답 실패 상황의 예외처리 위해 재귀로 구성!
            return sendRequest(
              requestMethod,
              url,
              params,
              config,
              callback,
              true
            );
          }
        }
      } else if (
        // 401 인데 Access Token 만료가 원인이 아닌경우, 화면에서 별도의 예외처리와 안내 필요.
        !ALERT_NETWORK_ERROR_EXCEPT_URL_DICT.filter((value) => url.match(value))
          .length
      ) {
        if (errorCode === ErrorCode.IP_NOT_ALLOWED) {
          TrickUtil.showNetworkAlert({
            errorData,
            shouldLogout: true,
            showTitle: false,
            showErrorCode: true,
          });
        } else if (status === StatusCode.NOT_FOUND) {
          TrickUtil.showNetworkAlert({
            errorData,
            callback: () => {
              window.location.href = '/';
            },
          });
        } else {
          TrickUtil.showNetworkAlert({ errorData });
        }
      }
      throw restClientError;
    } else {
      throw error;
    }
  }
};

const refreshToken = async () => {
  const oldRefreshToken = LocalStorageManager.getItem(
    LocalStorageKey.REFRESH_TOKEN
  );

  try {
    if (!oldRefreshToken) {
      throw new Error();
    }

    const refreshTokenParams = {
      clientName: process.env.REACT_APP_CLIENT_NAME,
      refreshToken: oldRefreshToken,
    };
    const response = await axios.post(
      UrlList.getTokenRefreshUrl(),
      refreshTokenParams
    );

    const { status, data } = response;
    if (data.result && status === StatusCode.OK) {
      const { accessToken, refreshToken } = data.result;
      LocalStorageManager.setItem(LocalStorageKey.ACCESS_TOKEN, accessToken);
      LocalStorageManager.setItem(LocalStorageKey.REFRESH_TOKEN, refreshToken);

      return true;
    }
  } catch (error) {}
  // Token Refresh 의 실패는 중복 로그인으로 간주함
  TrickUtil.showExpireAlert();
  sendMessage(WebViewMessageConst.LOGOUT_MULTIPLE_LOGIN());
  return false;
};

const RestClient = {
  get: async (url, params = {}, callback) => {
    const payload = {
      params: params,
      paramsSerializer: (params) => {
        // console.log({
        //   oo: params,
        //   rr: qs.stringify(params),
        // });

        return Object.entries(params)
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              return `${key}=${value.join(',')}`;
              // return value
              //     .map((subValue) => {
              //         return `${key}=${subValue}`;
              //     })
              //     .join('&');
            }
            return `${key}=${value}`;
          })
          .join('&');
      },
    };

    return sendRequest(axios.get, url, payload, {}, callback);
  },

  getWithCancelToken: async (url, params = {}, callback, source) => {
    const payload = {
      params: params,
      paramsSerializer: (params) => {
        return Object.entries(params)
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              return `${key}=${value.join(',')}`;
            }

            return `${key}=${value}`;
          })
          .join('&');
      },
    };

    payload.cancelToken = source.token;
    return sendRequest(axios.get, url, payload, {}, callback, false);
  },

  post: async (url, params = {}, callback, config = {}) => {
    return sendRequest(axios.post, url, params, config, callback);
  },

  uploadFile: async (url, params = {}, progressCallback, callback) => {
    const data = new FormData();
    Object.keys(params).map((key) => {
      data.append(key, params[key]);
    });

    const config = {
      timeout: 10 * 60 * 1000, // Set timeout to 10 minutes
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        if (progressCallback && typeof progressCallback === 'function') {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          progressCallback(percentCompleted);
        }
      },
    };

    return sendRequest(axios.post, url, data, config, callback);
  },

  uploadFileToS3: async (
    url,
    file,
    progressCallback,
    cancelFileUpload,
    callback
  ) => {
    const arrayBuffer = await FileUtil.readFileAsync(file);
    const config = {
      timeout: 10 * 60 * 1000, // Set timeout to 10 minutes
      headers: {
        'Content-Type': 'application/octet-stream',
        // To prevent attaching custom auth token to header
        'Disable-Custom-Auth': true,
      },
      onUploadProgress: function (progressEvent) {
        if (progressCallback && typeof progressCallback === 'function') {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          progressCallback(percentCompleted);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    };

    return sendRequest(axios.put, url, arrayBuffer, config, callback);
  },

  put: async (url, params = {}, callback) => {
    return sendRequest(axios.put, url, params, {}, callback);
  },

  patch: async (url, params = {}, callback) => {
    return sendRequest(axios.patch, url, params, {}, callback);
  },

  delete: async (url, params = {}, callback) => {
    return sendRequest(axios.delete, url, params, callback);
  },

  getFile: async (url, params = {}, progressCallback, callback) => {
    const payload = {
      params: params,
      paramsSerializer: (params) => {
        const result = Object.entries(params)
          .map(([key, value]) => {
            let subParam;
            if (Array.isArray(value)) {
              subParam = `${key}=${value.join(',')}`;
            }
            subParam = `${key}=${value}`;

            return subParam;
          })
          .join('&');

        return result;
      },
      responseType: 'blob',
      timeout: 10 * 60 * 1000, // Set timeout to 10 minutes
      onDownloadProgress: function (progressEvent) {
        if (progressCallback && typeof progressCallback === 'function') {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          progressCallback(percentCompleted, progressEvent);
        }
      },
    };

    const config = {};

    return sendRequest(axios.get, url, payload, config, callback);
  },
};

export default RestClient;

export const axiosSourceManager = {
  axiosSource: undefined,
  initAxiosSource() {
    this.axiosSource = undefined;
  },
  getSource() {
    if (this.axiosSource) {
      return this.axiosSource;
    } else {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      this.axiosSource = source;
      return this.axiosSource;
    }
  },
};
