import { hasOverlappedRange } from '../common';
import { WaveformIndex, Range, Ms } from '../types';

import { EVENT_CONST_TYPES } from 'constant/EventConst';

const { LEAD_OFF: EVENT_TYPE_LEAD_OFF, AF: EVENT_TYPE_AF } = EVENT_CONST_TYPES;

/** 특정 구간에 대한 Lead-Off 또는 AF 목록 Select */
export function selectFilteredEpisodeOrLeadOffList(
  rootState: any,
  onsetWaveformIndex: WaveformIndex,
  terminationWaveformIndex: WaveformIndex,
  ecgEventType: string,
  withoutTimeEvents: boolean = false
): Array<Range> {
  let result = Array<Range>();

  if (!isLoadedTestData(rootState)) return result;

  const recordingStartMs =
    rootState.testResultReducer.recordingTime.recordingStartMs;
  const targetRange = {
    onsetWaveformIndex,
    terminationWaveformIndex,
  };

  // XXX: 준호 - deep copy 필요?
  switch (ecgEventType) {
    case EVENT_TYPE_LEAD_OFF:
      result = rootState.testResultReducer.timeEventsList.leadOff
        .filter((leadOff: Range) =>
          hasOverlappedRange(
            getRangeObjFromMsRange(
              recordingStartMs,
              leadOff.onsetMs,
              leadOff.terminationMs
            ),
            targetRange
          )
        )
        .map((value: any) => setRangeFields(value, recordingStartMs));
      break;

    case EVENT_TYPE_AF:
      result = withoutTimeEvents
        ? []
        : rootState.testResultReducer.timeEventsList.data
            .filter(
              (timeEvent: Range) =>
                timeEvent.type === EVENT_TYPE_AF &&
                hasOverlappedRange(
                  getRangeObjFromMsRange(
                    recordingStartMs,
                    timeEvent.onsetMs,
                    timeEvent.terminationMs
                  ),
                  targetRange
                )
            )
            .map((value: any) => setRangeFields(value, recordingStartMs));
      break;

    default:
      break;
  }

  return result;
}

/** ECG Test 데이터가 Load 됐는지 여부 반환 */
function isLoadedTestData(rootState: any): boolean {
  const result = rootState.testResultReducer.recordingTime.recordingStartMs
    ? true
    : false;
  return result;
}
/** Timestamp 기반 구간 값을 Waveform Index 기반 Range 값으로 가공하여 반환 */
function getRangeObjFromMsRange(
  recordingStartMs: Ms,
  onsetMs: Ms,
  terminationMs: Ms
): Range {
  const result = {
    onsetWaveformIndex: getNatureNumberFromRational(
      (onsetMs - recordingStartMs) / 4
    ),
    terminationWaveformIndex: getNatureNumberFromRational(
      (terminationMs - recordingStartMs) / 4
    ),
  };
  return result;
}
/** 유리수의 소수점 이하를 버림한 자연수를 반환 */
function getNatureNumberFromRational(rationalNumber: number): number {
  const result = Math.floor(rationalNumber);
  return result;
}
/** ECG 이벤트 정보에 Waveform Index 기반 구간 값 추가 */
function setRangeFields(
  { onsetMs, terminationMs, ...restFields }: any = {},
  recordingStartMs: Ms
): Range {
  if (!(onsetMs && terminationMs))
    throw new Error(
      'setRangeFields error: 파라미터로 전달된 값이 이벤트 정보 아님'
    );
  if (restFields.onsetWaveformIndex && restFields.terminationWaveformIndex) {
    const result = {
      ...restFields,
      onsetMs,
      terminationMs,
    };
    return result;
  }
  const result = {
    ...getRangeObjFromMsRange(recordingStartMs, onsetMs, terminationMs),
    ...restFields,
    onsetMs,
    terminationMs,
  };
  return result;
}
