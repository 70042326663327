export type LocalStorageKeyType = keyof typeof LocalStorageKey;
export interface LastViewedTidState {
  ecgTestId?: string;
  showHRTrend?: boolean;
  tenSecStripAmplitudeRate?: number;
  thirtySecAmplitudeRate?: number;
}

const LocalStorageKey = {
  TOKEN_TYPE: 'TOKEN_TYPE',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  DO_NOT_SHOW_NOTICE_POPUP_TIL_THIS_DATE:
    'DO_NOT_SHOW_NOTICE_POPUP_TIL_THIS_DATE',
  SIDE_PANEL_STATE: 'SIDE_PANEL_STATE',
  HOSPITAL_FILTER_TYPE: 'HOSPITAL_FILTER_TYPE',
  CLINICAL_TRIAL_FILTER_TYPE: 'CLINICAL_TRIAL_FILTER_TYPE',
  HOSPITAL_TIMEZONE: 'HOSPITAL_TIMEZONE',
  SERVER_TIME_STAMP: 'SERVER_TIME_STAMP',
  TIME_DIFF_BTW_SERVER: 'TIME_DIFF_BTW_SERVER',
  LAST_VIEWED_TID_STATE: 'LAST_VIEWED_TID_STATE',
  REDIRECT_URL_AFTER_LOGIN: 'REDIRECT_URL_AFTER_LOGIN',
  USER_HID: 'USER_HID',
  BYPASS: 'BYPASS',
} as const;

export default LocalStorageKey;
