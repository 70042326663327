import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import DateUtil from 'util/DateUtil';
import { isSameDate } from 'util/TestNotification/TestNotificationUtil';
import {
  type IAccumulator,
  type ITestNotification,
} from 'redux/container/TestNotificationHistoryPageContainer';

import {
  getLastThirtyDaysNotificationRequested,
  patchReadByTidsRequested,
} from 'redux/duck/testNotificationDuck';
import { hideSidePanel } from 'redux/duck/ecgTestList/ecgTestListDuck';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import TestNotificationDropdownFragment from 'component/fragment/main/TestNotificationDropdownFragment';

function TestNotificationDropdownContainer() {
  // custom Hooks
  const location = useLocation();
  const dispatch = useDispatch();

  // Selectors
  const notiList = useShallowEqualSelector(
    (state: any) =>
      state.testNotificationReducer.lastThirtyDaysNotification.data
  ) as ITestNotification[];

  // disPatches
  const handleGetLastThirtyDaysNotificationRequested = () =>
    dispatch(getLastThirtyDaysNotificationRequested());
  const handlePatchReadByTidsRequested = (tids: string[]) =>
    dispatch(patchReadByTidsRequested({ tids }));
  const handleHideSidePanel = () => dispatch(hideSidePanel());

  // useState
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [isSwitchToggled, setIsSwitchToggled] = useState<boolean>(false);

  // useEffects
  useEffect(() => {
    handleGetLastThirtyDaysNotificationRequested();

    const intervalIdForPolling = setInterval(
      handleGetLastThirtyDaysNotificationRequested,
      30 * 1000
    );

    return () => clearInterval(intervalIdForPolling);
  }, []);

  useEffect(() => {
    if (!showDropdown) return;
    handleHideSidePanel();
    handleGetLastThirtyDaysNotificationRequested();
  }, [showDropdown]);

  useEffect(() => {
    setShowDropdown(false);
  }, [location]);

  // local variables
  const filteredByReadStatus: ITestNotification[] = notiList.filter(
    (v: ITestNotification) =>
      isSwitchToggled ? !v.isHospitalUserReadNotification : true
  );
  const notiCount: number = notiList.filter(
    (v: ITestNotification) => v.isHospitalUserReadNotification === false
  ).length;
  const today = DateUtil.getUserLocationTime();
  const { todaysNotiList, beforeTodayNotiList } =
    filteredByReadStatus.reduce<IAccumulator>(
      (acc, v) => {
        const date = new Date(v.createdAt);

        if (isSameDate(date, today)) {
          acc.todaysNotiList.push(v);
          return acc;
        }

        acc.beforeTodayNotiList.push(v);
        return acc;
      },
      { todaysNotiList: [], beforeTodayNotiList: [] }
    );

  // util funcs
  const handleReadAll = () => {
    const tids = notiList.map((v: ITestNotification) => v.tid);
    handlePatchReadByTidsRequested(tids);
  };

  return (
    <TestNotificationDropdownFragment
      showDropdown={showDropdown}
      setShowDropdown={setShowDropdown}
      isSwitchToggled={isSwitchToggled}
      setIsSwitchToggled={setIsSwitchToggled}
      notiCount={notiCount}
      filteredByReadStatus={filteredByReadStatus}
      todaysNotiList={todaysNotiList}
      beforeTodayNotiList={beforeTodayNotiList}
      handleReadAll={handleReadAll}
    />
  );
}

export default TestNotificationDropdownContainer;
