/**
 *
 * 에러 코드 정의.
 *
 * @link https://www.notion.so/huinno/Memo-service-Error-Code-log-32c90b561a3e4c9eb56c900076a8060d
 */
export const ErrorCode = {
  EXPIRED_ACCESS_TOKEN: 'not_authenticated',

  /**
   * @description 허용되지 않은 IP로 접근 시도시 발생하는 에러코드
   */
  IP_NOT_ALLOWED: 'E10002',

  /**
   * @description 이메일 확인에 실패
   */
  INCORRECT_AUTHENTICATION_CREDENTIALS: 'E10003',

  /**
   * @description 이메일은 확인되었으나 password 인증이 실패
   */
  INCORRECT_AUTHENTICATION_CREDENTIALS_OF_LOGIN_LOCKOUT: 'E10004',

  /**
   * @description 로그인 실패가 일정 횟수에 도달하여 계정이 잠김
   */
  LOGIN_LOCKOUT: 'E10005',

  /**
   * @description 해당 password_token에 대응되는 private key가 없거나 이미 만료되었음
   */
  INVALID_PASSWORD_TOKEN: 'E10006',

  /**
   * @description encrypt 실수 등 모종의 이유로 private key로 복호화 시도하였으나 실패
   */
  PASSWORD_DECRYPTION_FAIL: 'E10007',
};

export const ErrorDetails = {
  EXCEED_HR_BPM:
    'Some waveform indexes exceeds valid HR range, waveform indexes',
};
