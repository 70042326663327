import { call, select } from 'redux-saga/effects';
import {
  selectRecordingTime,
  selectTimeEventList,
} from 'redux/duck/testResultDuck';
import { BEAT_TYPE, EVENT_CONST_TYPES } from 'constant/EventConst';
import { TransformedEventType } from 'util/reduxDuck/TestResultDuckUtil';

const CONST_BEAT_TYPE = BEAT_TYPE;
const CONST_AF_EVENT_TYPE = EVENT_CONST_TYPES.AF;

/**
 * @typedef {Number} WaveformIndex Waveform Index
 *
 * @typedef BeatsOriginType Beats API 응답 데이터 타입
 * @property {WaveformIndex[]} waveformIndex
 * @property {BEAT_TYPE[]} beatType
 * @property {Number[]} hr
 *
 * @typedef WaveformIndexesBodyType Waveform Index 배열을 사용한 Beats API 편집 요청 Body 타입
 * @property {WaveformIndex[]} waveformIndexes
 * @property {BEAT_TYPE} beatType
 *
 * @typedef WaveformRangeBodyType Waveform 구간을 사용한 Beats API 편집 요청 Body 타입
 * @property {WaveformIndex} onsetWaveformIndex
 * @property {WaveformIndex} terminationWaveformIndex
 * @property {BEAT_TYPE} beatType
 *
 * @typedef {Boolean[]} ValidationTestResultType 각 편집 건의 테스트 결과 자료구조
 *
 * @typedef EditedBeatsValidateResultType Beat 편집 건 중 허용된(반영된) 것과 거부된 것의 자료구조
 * @property {WaveformIndex[]} accepted 편집이 허용된 Beat 의 식별값
 * @property {WaveformIndex[]} rejected 편집이 거부된 Beat 의 식별값
 */

/** Milliseconds 값을 Waveform Index 기준으로 변환 */
const transformMsToWaveformIndex = (recordingStartMs, targetMs) =>
  Math.floor((targetMs - recordingStartMs) / 4);
/**
 * Validation 검사 결과에 따라 최종 Output 형태로 변환
 *
 * @param {WaveformIndex[]} waveformIndexList
 * @param {ValidationTestResultType?} tested
 * @returns {EditedBeatsValidateResultType}
 */
const getTestedBeatsReturn = (waveformIndexList, tested) => {
  if (Boolean(tested)) {
    let result = {
      accepted: [],
      rejected: [],
    };
    for (let index in tested) {
      result[tested[index] ? 'accepted' : 'rejected'].push(
        waveformIndexList[index]
      );
    }
    return result;
  } else {
    return {
      accepted: waveformIndexList,
      rejected: [],
    };
  }
};
/** Time Event 구간에 특정 Waveform Index 가 포함하는지 여부 */
const isWaveformIndexInTimeEventSection = (
  timeEventInfo,
  waveformIndex,
  recordingStartMs
) =>
  transformMsToWaveformIndex(recordingStartMs, timeEventInfo.onsetMs) <=
    waveformIndex &&
  waveformIndex <=
    transformMsToWaveformIndex(recordingStartMs, timeEventInfo.terminationMs);
/**
 * 두 배열이 동일한지 테스트
 * @param {*[]} leftList
 * @param {*[]} rightList
 */
const isEqualList = (leftList, rightList) =>
  Array.isArray(leftList) &&
  Array.isArray(rightList) &&
  leftList.length === rightList.length &&
  [...leftList].sort().toString() === [...rightList].sort.toString();

/**
 * `requestWaveformIndexes` 의 목록과 `responseBody.waveformIndex` 이 다른 경우 `responseBody` 에 누락된 Beat 정보 복구하여 반환
 *
 * @param {WaveformIndex[]} requestWaveformIndexes 편집이 요청된 Beat 의 Waveform Index 목록
 * @param {BeatsOriginType} responseBody 편집 API 응답 Body
 */
const repairResponse = (requestWaveformIndexes, responseBody) => {
  if (!Array.isArray(requestWaveformIndexes)) return responseBody;
  if (isEqualList(requestWaveformIndexes, responseBody.waveformIndex))
    return responseBody;

  let repairedResponseBody = {
    waveformIndex: [...responseBody.waveformIndex],
    beatType: [...responseBody.beatType],
    hr: [...responseBody.hr],
  };
  requestWaveformIndexes.forEach((waveformIndex) => {
    if (repairedResponseBody.waveformIndex.includes(waveformIndex)) return;
    const targetIndex = Math.max(
      repairedResponseBody.waveformIndex.findIndex(
        (value) => waveformIndex < value
      ),
      0
    );
    repairedResponseBody.waveformIndex.splice(targetIndex, 0, waveformIndex);
    repairedResponseBody.beatType.splice(targetIndex, 0, null);
    repairedResponseBody.hr.splice(targetIndex, 0, null);
  });
  return repairedResponseBody;
};

/**
 * `responseBody` 의 Beat 중 `requestBody.beatType` 인 Beat 와 아닌 Beat 를 구분한 목록 반환
 *
 * `accepted`: requestBody.beatType 와 동일한 Beat 의 waveformIndex 목록
 * `rejected`: requestBody.beatType 와 다른 Beat 의 waveformIndex 목록
 *
 * @param {WaveformIndexesBodyType | WaveformRangeBodyType} requestBody Beats API 중 POST, PATCH 응답 Body
 * @param {BeatsOriginType} responseBody Beats API 중 POST, PATCH 응답 Body
 * @returns {EditedBeatsValidateResultType}
 */
export const validateBeatEditResponse = (requestBody, responseBody) => {
  if (!Boolean(responseBody?.beatType)) {
    return {
      accepted: [],
      rejected: [],
    };
  }

  const { beatType: targetBeatType, waveformIndexes } = requestBody;
  const repairedResponseBody = repairResponse(waveformIndexes, responseBody);

  return getTestedBeatsReturn(
    repairedResponseBody.waveformIndex,
    repairedResponseBody.beatType.map((value) => value === targetBeatType)
  );
};

/**
 * S Beat 편집 검증
 *
 * S Beat 로 편집된 건 중 AF 구간안에 포함됐는지 여부를 테스트
 *
 * @param {WaveformIndex[]} waveformIndexList S Beat 의 식별값 목록
 * @param {TransformedEventType[]} afList AF 구간 정보 목록
 * @param {Timestamp} recordingStartMs Waveform Index 기준 시간
 * @returns {ValidationTestResultType}
 */
export const validateSBeatList = (
  waveformIndexList,
  afList,
  recordingStartMs
) => {
  if (!Boolean(waveformIndexList.length)) {
    return [];
  }
  if (!Boolean(afList.length)) {
    return Array.from({ length: waveformIndexList.length }, (v, i) => true);
  }

  return waveformIndexList.map(
    (waveformIndex) =>
      !afList.some((eventInfo) =>
        isWaveformIndexInTimeEventSection(
          eventInfo,
          waveformIndex,
          recordingStartMs
        )
      )
  );
};

/**
 * S Beat 편집 검증
 *
 * S Beat 로 편집된 건 중 AF 구간안에 포함됐는지 여부를 테스트
 *
 * @param {WaveformIndex[]} waveformIndexList
 * @returns {ValidationTestResultType}
 */
function* validateSBeatEdit(waveformIndexList) {
  const { recordingStartMs } = yield select(selectRecordingTime);
  const afList = yield select((state) =>
    selectTimeEventList(state, CONST_AF_EVENT_TYPE)
  );

  return validateSBeatList(waveformIndexList, afList, recordingStartMs);
}

export function* validateBeatsEditRequest(waveformIndexList, beatType) {
  switch (beatType) {
    case CONST_BEAT_TYPE.NORMAL:
      return getTestedBeatsReturn(waveformIndexList);
    case CONST_BEAT_TYPE.APC:
      const tested = yield call(validateSBeatEdit, waveformIndexList);
      return getTestedBeatsReturn(waveformIndexList, tested);
    case CONST_BEAT_TYPE.VPC:
      return getTestedBeatsReturn(waveformIndexList);
    case CONST_BEAT_TYPE.NOISE:
      return getTestedBeatsReturn(waveformIndexList);
    default:
      return getTestedBeatsReturn(waveformIndexList);
  }
}
