import React, { useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 0;
  position: relative;
  height: 100%;
`;

const Title = styled.div`
  margin-bottom: 6px;
  font-size: 12px;
  color: ${(props) => props.theme.color.DARK};
`;

const DiaryTextarea = styled(TextareaAutosize)`
  width: calc(100% - 20px); // 12px + 8px
  min-height: ${(props) => (props.height ? props.height : 80)}px;
  padding: 12px 8px 8px;

  border: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  border-radius: 6px;

  background-color: ${(props) => props.theme.color.WHITE};

  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  font-size: 12px;
  line-height: 130%;
  font-weight: 400;
  color: ${(props) => props.theme.color.COOL_GRAY_90};

  outline: none;
  -webkit-transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
  resize: ${({ resize }) => resize};

  &:hover {
    border: 1px solid ${(props) => props.theme.color.COOL_GRAY_60};
  }
  &:focus {
    box-shadow: 0 0 1px 1px ${(props) => props.theme.color.BLUE_70};
  }

  ${(props) =>
    props.disabled &&
    `
      color: ${
        props.value
          ? props.theme.color.COOL_GRAY_90
          : props.theme.color.COOL_GRAY_70
      };
      cursor: not-allowed;
      &:hover {
        border: 1px solid ${props.theme.color.COOL_GRAY_40};
      }
    `};
  &::placeholder {
    color: ${(props) => props.theme.color.COOL_GRAY_70};
  }
`;

const HelperTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => {
    let result = '';
    const postfix = ';';
    if (props.helperRightText) {
      result = 'flex-end';
    } else if (props.helperLeftText || props.helperRightText) {
      result = 'space-between';
    }
    return result + postfix;
  }};

  position: relative;
  height: 20px;
  width: 100%;
`;

const HelperTextLeft = styled.div`
  display: block;
  padding: 8px 0px 8px 12px;
  width: 193px;
  z-index: 1;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 10px;
  font-weight: 400;
  line-height: 0px;
  color: ${(props) => props.theme.color.COOL_GRAY_70};

  :hover {
    ${(props) => {
      if (props.isOverflowNoteLastUpdateField) {
        return `
          &:before {
            position: absolute;
            left: 83px;
            transform: translate(50%, 50%) rotate(45deg);
            
            top: 11px;
            display: block;
            width: 8.5px;
            height: 8.5px;
            content: '';
            background:  ${props.theme.color.COOL_GRAY_80};
          }

          &:after {
            position: absolute;
            top: 19px;
            left: 0;
            transform: translate(10px, 0px);
            content: attr(data-text);
            padding: 5px 8px;
            width: 150px;
            line-height: 15.6px;
            border-radius: 4px;
            white-space: break-spaces;
            word-break: break-all;
            background:  ${props.theme.color.COOL_GRAY_80};
            color:  ${props.theme.color.WHITE};
        `;
      }
    }};
  }
`;

const HelperTextRight = styled.div`
  white-space: nowrap;
  font-size: 10px;
  font-weight: 400;
  line-height: 0px;
  padding: 8px 12px;
  color: ${(props) => props.theme.color.COOL_GRAY_80};
`;

function TextAreaInput(props) {
  const {
    style,
    title,
    placeholder,
    disabled,
    disabledPlaceHolder,
    value,
    defaultValue,
    onFocus,
    onChange,
    onBlur,
    onKeyDown,
    maxLength,
    height,
    //
    helperLeftText,
    helperRightText,
    resize,
    dataTestId,
  } = props;

  const noteLastUpdateFieldRef = useRef(null);
  const [isOverflowNoteLastUpdateField, setIsOverflowNoteLastUpdateField] =
    useState(false);

  useEffect(() => {
    if (!noteLastUpdateFieldRef.current) return;

    const isOverflowNOteLastUpdateField =
      noteLastUpdateFieldRef.current.scrollWidth !==
      noteLastUpdateFieldRef.current.clientWidth;
    setIsOverflowNoteLastUpdateField(isOverflowNOteLastUpdateField);
  }, [helperLeftText]);

  const $helperText = makeHelperText(
    helperLeftText,
    helperRightText,
    noteLastUpdateFieldRef,
    isOverflowNoteLastUpdateField
  );

  return (
    <Wrapper className="textAreaWrapper" style={style} data-testid={dataTestId}>
      {title && <Title>{title}</Title>}

      <DiaryTextarea
        className="DiaryTextarea"
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        placeholder={disabled ? disabledPlaceHolder : placeholder}
        maxLength={maxLength}
        height={height}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        resize={resize}
      />
      {$helperText}
    </Wrapper>
  );
}

export default TextAreaInput;

function makeHelperText(
  helperLeftText,
  helperRightText,
  noteLastUpdateFieldRef,
  isOverflowNoteLastUpdateField
) {
  let $helperText = '';
  if (helperLeftText || helperRightText) {
    $helperText = (
      <HelperTextWrapper
        helperLeftText={helperLeftText}
        helperRightText={helperRightText}>
        {helperLeftText && (
          // todo: jyoon - mui Tooltip이  dialog wrapper zidnex영향으로 보이지 않음
          // <Tooltip title={helperLeftText} arrow placement="bottom">
          <HelperTextLeft
            ref={noteLastUpdateFieldRef}
            isOverflowNoteLastUpdateField={isOverflowNoteLastUpdateField}
            data-text={helperLeftText}>
            {helperLeftText}
          </HelperTextLeft>
          // </Tooltip>
        )}
        {helperRightText && (
          <HelperTextRight>{helperRightText}</HelperTextRight>
        )}
      </HelperTextWrapper>
    );
  }
  return $helperText;
}
