import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { showMenuAnim, hideMenuAnim } from 'component/ui/animation/Animation';

import { ReactComponent as DropdownArrowIcon } from 'static/icon/icon-dropdown-arrow.svg';
import { ReactComponent as ShowMoreIcon } from 'static/icon/icon-show-more.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const AnchorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TitleText = styled.div`
  margin-right: 4px;
  font-size: 14px;
  letter-spacing: -0.28px;
`;

const ItemContainer = styled.div`
  z-index: 999;
  top: ${({ size }) => `${size === 'small' ? 20 : 32}px`};
  width: ${({ size }) => `${size === 'small' ? 80 : 160}px`};
  position: absolute;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  border: solid 1px #c7ced2;
  background-color: #ffffff;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  animation: ${(props) => (props.visible ? showMenuAnim : hideMenuAnim)} 0.2s
    ease-in-out;
  transform-origin: top;
  -webkit-transition: -webkit-visibility 0.2s ease-in-out;
  transition: visibility 0.2s ease-in-out;
`;

export const MenuBase = styled.div`
  padding: 6px 4px;
  gap: 1px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

/* 
  # autoClose : 메뉴 레이어 클릭시 바로 닫히는 여부 결정
  # iconType: 각 타입에 맞는 icon 렌더링
  # size : small, medium에 따라 style 분기 처리
*/
function Menu(props) {
  const {
    style,
    title,
    iconType = 'dropdown',
    children,
    size,
    handleHideSidePanel = () => {},
    dataTestId,
  } = props;

  const titleTextRef = useRef(null);

  const [show, _setShow] = useState(false);
  const showStateRef = useRef(show);

  useEffect(() => {
    if (!window.menuManagement) {
      window.menuManagement = [];
    }

    window.menuManagement.push(() => {
      setShow(false);
      handleHideSidePanel();
    });

    document.addEventListener('click', onMouseClick);
    return () => {
      window.menuManagement = [];
      document.removeEventListener('click', onMouseClick);
    };
  }, []);

  const setShow = (data) => {
    showStateRef.current = data;
    _setShow(data);
  };

  const getParentComponentType = (target, type) => {
    let targetEl = target;

    while (targetEl !== null && !targetEl?.dataset.autoClose) {
      if (targetEl?.tagName === 'BODY') return null;
      targetEl = targetEl?.parentElement;
    }
    return targetEl;
  };

  const onMouseClick = (event) => {
    if (event.target.tagName === 'rect') return null;

    if (
      showStateRef.current &&
      titleTextRef.current &&
      titleTextRef.current !== event.target
    ) {
      // TODO: JYOON - 모듈화 필요
      const hasAutoClose = getParentComponentType(event.target, false);
      !hasAutoClose && setShow(false);
    }
  };

  return (
    <Wrapper style={style} data-testid={dataTestId}>
      <AnchorContainer
        ref={titleTextRef}
        onClick={(event) => {
          event.stopPropagation();
          for (const menuManagement of window.menuManagement) {
            menuManagement();
          }
          setShow(!show);
        }}>
        <TitleText>{title}</TitleText>
        {iconType === 'dropdown' && (
          <DropdownArrowIcon style={{ cursor: 'pointer' }} />
        )}
        {iconType === 'more' && (
          <ShowMoreIcon style={{ cursor: 'pointer', zIndex: 1 }} />
        )}
      </AnchorContainer>
      <ItemContainer visible={show} size={size}>
        <MenuBase>{children}</MenuBase>
      </ItemContainer>
    </Wrapper>
  );
}

export default Menu;
