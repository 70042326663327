import { useState, useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import styled, { useTheme } from 'styled-components';

import Const, { WRITING_TYPE as _WRITING_TYPE } from 'constant/Const';
import { genderInfo, pacemakerInfo } from 'constant/EcgTestInfoConst';
import { DATA_TEST_ID } from 'constant/DataTestId';

import DateUtil from 'util/DateUtil';
import NumberUtil from 'util/NumberUtil';
import { getParsedTestDuration } from 'util/TestDataUtil';
import {
  buttonConditionByEcgTestStatus,
  haveDifferentWords,
  isPairingDeviceStatus,
  isPreventUpdateTestInfo,
  validationBirthDay,
  validationPhoneNumber,
  validationPrescribedBy,
} from 'util/validation/AddNewTestDialog';

import usePrevious from 'component/hook/usePrevious';
import useDoctorsDropdown from 'component/hook/useDoctorsDropdown';
import useSnackbarStack from 'component/hook/useSnackbarStack';

import {
  DialogWrapper,
  DialogContentContainer,
  DialogButtonWrapper,
} from 'component/ui/dialog/Dialog';
import DialogRowItem, {
  labelAlignConst,
} from 'component/ui/dialog/DialogRowItem';
import TextInput from 'component/ui/input/TextInput';
import TextButton from 'component/ui/button/TextButton';
import TextAreaInput from 'component/ui/input/TextAreaInput';
import { RadioContainer, RadioButton } from 'component/ui/radio/Radio';
import Button from 'component/ui/button/Button';

const WRITING_TYPE = _WRITING_TYPE;
const CHARACTER_LIMIT = 2000;
const NOTE_TEXTAREA_HEIGHT = 160;
const LABEL_WIDTH = 86;

function AddNewTestDialog(props) {
  const intl = useIntl();
  const theme = useTheme();
  const { enqueueMessage } = useSnackbarStack();

  const {
    open,
    params,
    callback: loadPageData,
    onClose,
    // Redux state
    ecgTestsCreateState,
    ecgTestsPatchState,
    ecgTestsDeleteState,
    // Redux dispatch
    showDialog,
    createEcgTest,
    patchEcgTest,
    deleteEcgTest,
  } = props;

  const { title, writingType, testDialogInfo, ecgTestState } = params;

  const _isNewMode = writingType === WRITING_TYPE.NEW;
  const _isEditMode = writingType === WRITING_TYPE.EDIT;
  const _isPreventUpdateTestInfo =
    _isEditMode && isPreventUpdateTestInfo(ecgTestState);

  const [patientName, setPatientName] = useState('');
  const [patientId, setPatientId] = useState('');
  const [gender, setGender] = useState(genderInfo[0].value);
  const [birthDate, setBirthDate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [prescriptionDuration, setPrescriptionDuration] = useState('');

  const [pacemaker, setPacemaker] = useState(null);
  const [note, setNote] = useState('');
  const [lastUpdateUser, setLastUpdateUser] = useState('');
  const [lastUpdateDate, setLastUpdateDate] = useState('');
  const [isAllInfoEntered, setIsAllInfoEntered] = useState(false);

  const initDoctor =
    _isNewMode || !testDialogInfo?.referredBy
      ? null
      : {
          key: testDialogInfo?.referredBy?.username ?? '',
          label: testDialogInfo?.referredBy?.firstName ?? '',
          value: testDialogInfo?.referredBy?.username ?? '',
        };

  const [referredBy, DoctorsDropdown] = useDoctorsDropdown(
    intl.formatMessage({
      id: '99-AddNewTestDialog-Dropdown-placeholder-referredBy',
      description: '3.4 검사 추가 팝업의 referredBy 필드 placeholder',
      defaultMessage: '처방의 선택',
    }),
    initDoctor,
    { disabled: _isPreventUpdateTestInfo }
  );

  //locale에 따라 width 값 변경
  const dialogWidth = intl.locale === Const.LANGUAGE.KO ? 400 : 445;
  const textInputWidth = intl.locale === Const.LANGUAGE.KO ? 254 : 300;
  const inputWidth = intl.locale === Const.LANGUAGE.KO ? 255 : 299; // DialogRowItem의 input width

  /**
   *
   * 처방 기간 입력란에 유저가 입력한 텍스트 처리
   *
   * @param {string} evt 처방 기간 입력란에 유저가 입력한 텍스트
   * @returns
   */
  const onChangePrescriptionDuration = (evt) => {
    const testDuration = getParsedTestDuration(evt.target.value);
    if (testDuration !== undefined) {
      setPrescriptionDuration(testDuration);
    }
  };

  useEffect(() => {
    if (_isNewMode) return;

    setPatientName(testDialogInfo.patientName);
    setPatientId(testDialogInfo.patientNumber);
    setGender(testDialogInfo.patientSex);
    setBirthDate(testDialogInfo.patientBirth);
    setPhoneNumber(
      NumberUtil.getPhoneNumberFormat(testDialogInfo.patientPhoneNumber)
    );
    setPrescriptionDuration(testDialogInfo.prescriptionDuration);
    setPacemaker(testDialogInfo.pacemaker);
    setNote(testDialogInfo.note ?? '');
    setLastUpdateUser(
      testDialogInfo?.noteLastModifier?.lastName +
        '' +
        testDialogInfo?.noteLastModifier?.firstName
    );
    setLastUpdateDate(testDialogInfo?.noteModifiedDatetime);
  }, [testDialogInfo]);

  const prevEcgTestsCreateState = usePrevious(ecgTestsCreateState);
  useEffect(() => {
    if (
      prevEcgTestsCreateState &&
      prevEcgTestsCreateState.pending &&
      !ecgTestsCreateState.pending
    ) {
      if (!ecgTestsCreateState.error) {
        onClose();
        loadPageData();
        enqueueMessage(
          intl.formatMessage({
            id: '99-AddNewTestDialog-AlertDialog-message-success',
            description: '3.4 검사 추가 팝업의 성공 결과 Alert 메시지',
            defaultMessage: '검사가 추가되었습니다.',
          })
        );
      }
    }
  }, [ecgTestsCreateState.pending]);

  const prevEcgTestsPatchState = usePrevious(ecgTestsPatchState);
  useEffect(() => {
    if (
      prevEcgTestsPatchState &&
      prevEcgTestsPatchState.pending &&
      !ecgTestsPatchState.pending
    ) {
      if (!ecgTestsPatchState.error) {
        onClose();
        loadPageData();

        if (isPairingDeviceStatus(ecgTestsPatchState.data.ecgTestStatus)) {
          showDialog('AlertDialog', {
            message: intl.formatMessage({
              id: '99-AddNewTestDialog-AlertDialog-limit-message',
              description:
                '[시작 전] > [부착 중] 상태 변경 중 환자 정보 - message',
              defaultMessage:
                '검사 측정을 시작한 이후에는 비고만 변경할 수 있습니다.',
            }),
          });
        }
        if (_isPreventUpdateTestInfo) {
          enqueueMessage(
            intl.formatMessage({
              id: '99-AddNewTestDialog-AlertDialog-message-patch-success',
              description: '3.4 검사 추가 팝업의 수정 성공 결과 Alert 메시지',
              defaultMessage: '비고가 수정되었습니다.',
            })
          );
        } else {
          enqueueMessage(
            intl.formatMessage({
              id: '99-AddNewTestDialog-AlertDialog-message-patch-success',
              description: '3.4 검사 추가 팝업의 수정 성공 결과 Alert 메시지',
              defaultMessage: '검사가 수정되었습니다.',
            })
          );
        }
      }
    }
  }, [ecgTestsPatchState.pending]);

  const prevEcgTestsDeleteState = usePrevious(ecgTestsDeleteState);
  useEffect(() => {
    if (
      prevEcgTestsDeleteState &&
      prevEcgTestsDeleteState.pending &&
      !ecgTestsDeleteState.pending
    ) {
      if (!ecgTestsDeleteState.error) {
        onClose();
        loadPageData();
        // 삭제 후 message는 'OngoingTestFragment'에 위치
      }
    }
  }, [ecgTestsDeleteState.pending]);

  useEffect(() => {
    if (_isNewMode) {
      const isValidation =
        patientId && birthDate && Number(prescriptionDuration) > 0;
      setIsAllInfoEntered(isValidation);
    }
    if (_isEditMode) {
      const resultPatientName = haveDifferentWords(
        testDialogInfo.patientName,
        patientName
      );
      const resultPatientId = haveDifferentWords(
        testDialogInfo.patientNumber,
        patientId
      );
      const resultGender = haveDifferentWords(
        testDialogInfo.patientSex,
        gender
      );
      const resultBirthDate = haveDifferentWords(
        testDialogInfo.patientBirth,
        birthDate
      );
      const resultPhoneNumber = haveDifferentWords(
        NumberUtil.getPhoneNumberFormat(testDialogInfo.patientPhoneNumber),
        NumberUtil.getPhoneNumberFormat(phoneNumber)
      );
      const resultPrescriptionDuration = haveDifferentWords(
        testDialogInfo.prescriptionDuration,
        prescriptionDuration
      );
      const resultPacemaker = haveDifferentWords(
        testDialogInfo.pacemaker,
        pacemaker
      );
      const resultReferredBy = haveDifferentWords(
        testDialogInfo?.referredBy?.username,
        referredBy?.value
      );
      const resultNote = haveDifferentWords(testDialogInfo.note, note);

      setIsAllInfoEntered(
        resultPatientName ||
          resultPatientId ||
          resultGender ||
          resultBirthDate ||
          resultPhoneNumber ||
          resultPrescriptionDuration ||
          resultPacemaker ||
          resultReferredBy ||
          resultNote
      );
    }
  }, [
    patientName,
    patientId,
    gender,
    birthDate,
    phoneNumber,
    prescriptionDuration,
    pacemaker,
    referredBy,
    note,
    testDialogInfo,
  ]);

  const onClickCreate = () => {
    if (resultOfValidateBeforeSave()) return;

    createEcgTest({
      patientName,
      patientNumber: patientId,
      patientSex: gender,
      patientPhoneNumber: phoneNumber.replace(/[^\d]/g, ''),
      patientBirth: birthDate,
      prescriptionDuration,
      pacemaker,
      referredBy: referredBy.value,
      note,
    });
  };
  const onClickEdit = () => {
    if (resultOfValidateBeforeSave()) return;

    patchEcgTest({
      ecgTestId: testDialogInfo.tid,
      form: {
        patientName,
        patientNumber: patientId,
        patientSex: gender,
        patientPhoneNumber: phoneNumber.replace(/[^\d]/g, ''),
        patientBirth: birthDate,
        prescriptionDuration,
        pacemaker,
        referredBy: referredBy?.value,
        confirmedBy: referredBy?.value,
        note: note,
      },
    });
  };
  const onClickDelete = () => {
    showDialog('ConfirmDialog', {
      title: intl.formatMessage(
        {
          id: '99-ConfirmDialog-TableRow-onClick-01',
          description: '홍길동님의 검사를 삭제하시겠습니까?',
          defaultMessage: '{patientName}님의 검사를 삭제하시겠습니까?',
        },
        { patientName: testDialogInfo.patientName }
      ),
      message: intl.formatMessage({
        id: '99-ConfirmDialog-TableRow-onClick-02',
        description: '삭제 후에는 복구할 수 없습니다.',
        defaultMessage: '삭제 후에는 복구할 수 없습니다.',
      }),
      confirmButtonText: intl.formatMessage({
        id: '99-ConfirmDialog-TableRow-onClick-03',
        description: '삭제',
        defaultMessage: '삭제',
      }),
      confirmButtonColor: theme.color.RED,
      onSubmit: () => {
        deleteEcgTest({ ecgTestId: testDialogInfo.tid });
      },
    });
  };

  const onChangeNoteTextArea = (event) => {
    if (event.target.value.length > CHARACTER_LIMIT) return;
    event.nativeEvent.trigger = true;
    setNote(event.target.value);
  };

  const resultOfValidateBeforeSave = () => {
    const validationList = [
      validationBirthDay(birthDate, { intl }),
      validationPhoneNumber(phoneNumber, { intl }),
      validationPrescribedBy(referredBy, { intl }),
    ];

    for (let validation of validationList) {
      let validationResult = validation;
      if (validationResult.result === true) {
        showDialog('AlertDialog', {
          message: validationResult.msg,
        });
        return true;
      }
    }

    return false;
  };

  const $writingTypeIsNewBottomBtnList = [
    getAddBtn({ style: { float: 'right' } }),
    getCancelBtn({ style: { float: 'right' } }),
  ];

  const $writingTypeIsEditBottomBtnList = [
    buttonConditionByEcgTestStatus.allowDeleteButton.includes(ecgTestState) &&
      getDeleteBtn({ style: { float: 'left' } }),
    getEdit({ style: { float: 'right' } }),
    getCancelBtn({ style: { float: 'right' } }),
  ].filter((v) => v !== false);

  return (
    <Wrapper>
      <DialogWrapper
        zIndex={3}
        open={open}
        width={dialogWidth}
        innerStyle={{
          boxSizing: 'border-box',
          margin: 0,
          padding: '20px 20px 0px 20px',
        }}>
        <TitleText>{title}</TitleText>

        <DialogContentContainer>
          <DialogRowItem
            inputWidth={inputWidth}
            labelWidth={LABEL_WIDTH}
            label={intl.formatMessage({
              id: '99-AddNewTestDialog-DialogRowItem-label-patientId',
              description: '3.4 검사 추가 팝업의 patientId 필드이름',
              defaultMessage: '환자번호',
            })}
            required={true}>
            <TextInput
              style={{ width: textInputWidth }}
              disabled={_isPreventUpdateTestInfo}
              inputType="text"
              value={patientId}
              dataTestId={DATA_TEST_ID.ADD_NEW_TEST_DIALOG.PATIENT.ID}
              placeholder={intl.formatMessage({
                id: '99-AddNewTestDialog-DialogRowItem-label-patientId',
                description: '3.4 검사 추가 팝업의 patientId 필드이름',
                defaultMessage: '환자번호',
              })}
              onChange={(text) => {
                setPatientId(text);
              }}
            />
          </DialogRowItem>

          <DialogRowItem
            inputWidth={inputWidth}
            labelWidth={LABEL_WIDTH}
            label={intl.formatMessage({
              id: '99-AddNewTestDialog-DialogRowItem-label-patientName',
              description: '3.4 검사 추가 팝업의 patientName 필드이름',
              defaultMessage: '환자명',
            })}
            required={false}>
            <TextInput
              style={{ width: textInputWidth }}
              disabled={_isPreventUpdateTestInfo}
              inputType="text"
              value={patientName}
              dataTestId={DATA_TEST_ID.ADD_NEW_TEST_DIALOG.PATIENT.NAME}
              placeholder={intl.formatMessage({
                id: '07-MyAccountPage-InfoItem-name-01',
                description: '이름',
                defaultMessage: '이름',
              })}
              onChange={(text) => {
                setPatientName(text);
              }}
            />
          </DialogRowItem>

          <DialogRowItem
            inputWidth={inputWidth}
            labelWidth={LABEL_WIDTH}
            label={intl.formatMessage({
              id: '99-AddNewTestDialog-DialogRowItem-label-gender',
              description: '3.4 검사 추가 팝업의 gender 필드이름',
              defaultMessage: '성별',
            })}
            required={true}>
            <RadioContainer
              style={{
                display: 'flex',
                gap: '4px',
              }}>
              {genderInfo.map((genderObj) => {
                return (
                  <RadioButton
                    wrapperStyle={{
                      padding: '6px 8px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    labelStyle={{
                      fontSize: 12,
                    }}
                    dataTestId={
                      genderObj.value === 'M'
                        ? DATA_TEST_ID.ADD_NEW_TEST_DIALOG.PATIENT.SEX.MAN
                        : DATA_TEST_ID.ADD_NEW_TEST_DIALOG.PATIENT.SEX.WOMAN
                    }
                    disabled={_isPreventUpdateTestInfo}
                    key={genderObj.value}
                    id={genderObj.value}
                    value={genderObj.value}
                    label={genderObj.label}
                    checked={gender === genderObj.value}
                    onChange={() => {
                      setGender(genderObj.value);
                    }}
                  />
                );
              })}
            </RadioContainer>
          </DialogRowItem>

          <DialogRowItem
            inputWidth={inputWidth}
            labelWidth={LABEL_WIDTH}
            label={intl.formatMessage({
              id: '99-AddNewTestDialog-DialogRowItem-label-birthDate',
              description: '3.4 검사 추가 팝업의 birthDate 필드이름',
              defaultMessage: '생년월일',
            })}
            required={true}>
            <TextInput
              disabled={_isPreventUpdateTestInfo}
              style={{ width: textInputWidth }}
              inputType="text"
              placeholder="YYYY-MM-DD"
              dataTestId={DATA_TEST_ID.ADD_NEW_TEST_DIALOG.PATIENT.BIRTH_DATE}
              value={birthDate}
              onChange={(text, { nativeEvent: { inputType } }) => {
                const regex = /^[0-9\b -]{0,10}$/;
                if (!regex.test(text)) return;
                setBirthDate(() => {
                  if (inputType === 'insertFromPaste') {
                    // 붙여넣기 한 경우
                    return text
                      .replace(/-/g, '')
                      .replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
                  }
                  return text
                    .replace(/[^0-9]/g, '')
                    .replace(/^(\d{0,4})(\d{0,2})(\d{0,2})$/g, '$1-$2-$3')
                    .replace(/(-{1,2})$/g, '');
                });
              }}
            />
          </DialogRowItem>

          <DialogRowItem
            inputWidth={inputWidth}
            labelWidth={LABEL_WIDTH}
            label={intl.formatMessage({
              id: '99-AddNewTestDialog-DialogRowItem-label-phoneNumber',
              description: '3.4 검사 추가 팝업의 phoneNumber 필드이름',
              defaultMessage: '전화번호',
            })}
            required={false}>
            <TextInput
              style={{ width: textInputWidth }}
              disabled={_isPreventUpdateTestInfo}
              inputType="text"
              value={phoneNumber}
              dataTestId={DATA_TEST_ID.ADD_NEW_TEST_DIALOG.PATIENT.PHONE_NUMBER}
              placeholder={intl.formatMessage({
                id: '99-AddNewTestDialog-TextInput-placeholder-phoneNumber',
                description: `'-' 없이 전화번호 입력 / Phone number (digits only)`,
                defaultMessage: `'-' 없이 전화번호 입력`,
              })}
              onChange={(text, { nativeEvent: { inputType } }) => {
                const regex = /^[0-9\b -]{0,13}$/;
                if (regex.test(text)) {
                  setPhoneNumber(() => {
                    if (inputType === 'insertFromPaste') {
                      return NumberUtil.getPhoneNumberFormatByPaste(text);
                    } else {
                      return NumberUtil.getPhoneNumberFormat(text);
                    }
                  });
                }
              }}
            />
          </DialogRowItem>

          <DialogRowItem
            inputWidth={inputWidth}
            labelWidth={LABEL_WIDTH}
            required={true}
            label={intl.formatMessage({
              id: '99-AddNewTestDialog-DialogRowItem-label-pacemaker',
              description: '페이스메이커',
              defaultMessage: '페이스메이커',
            })}>
            <RadioContainer
              style={{
                gap: '4px',
              }}>
              {pacemakerInfo.map((pacemakerObj, index) => {
                const getDataTestId = (value) => {
                  switch (value) {
                    case true:
                      return DATA_TEST_ID.ADD_NEW_TEST_DIALOG.PATIENT.PACEMAKER
                        .EXIST;
                    case false:
                      return DATA_TEST_ID.ADD_NEW_TEST_DIALOG.PATIENT.PACEMAKER
                        .NONE;
                    default:
                      return DATA_TEST_ID.ADD_NEW_TEST_DIALOG.PATIENT.PACEMAKER
                        .NOT_SELECTED;
                  }
                };

                return (
                  <RadioButton
                    wrapperStyle={{
                      padding: '6px 8px',
                      display: 'flex',
                      alignItems: 'center',
                      flex: index === 0 ? 1.5 : 1,
                    }}
                    labelStyle={{
                      fontSize: 12,
                    }}
                    disabled={_isPreventUpdateTestInfo}
                    key={`pacemaker_${pacemakerObj.value}`}
                    dataTestId={getDataTestId(pacemakerObj.value)}
                    value={pacemakerObj.value}
                    label={pacemakerObj.label}
                    checked={pacemaker === pacemakerObj.value}
                    onChange={() => {
                      setPacemaker(pacemakerObj.value);
                    }}
                  />
                );
              })}
            </RadioContainer>
          </DialogRowItem>

          <Divider />

          <DialogRowItem
            inputWidth={inputWidth}
            labelWidth={LABEL_WIDTH}
            label={intl.formatMessage({
              id: '99-AddNewTestDialog-DialogRowItem-label-testPeriod',
              description: '처방일수 / Test Duration',
              defaultMessage: '처방일수',
            })}
            required={true}>
            <TestPeriodTextWrapper>
              <TestPeriodInput
                data-testid={
                  DATA_TEST_ID.ADD_NEW_TEST_DIALOG.TEST.PRESCRIPTION_DURATION
                    .CUSTOM_INPUT
                }
                value={prescriptionDuration}
                onChange={onChangePrescriptionDuration}
                disabled={_isPreventUpdateTestInfo}
                placeholder="0"
              />
              <TestPeriodText>
                {intl.formatMessage(
                  INTL_MAP.PRESCRIPTION_DURATION_DAY_UNIT_FULL_NAME
                )}
              </TestPeriodText>
            </TestPeriodTextWrapper>
          </DialogRowItem>
          <DialogRowItem inputWidth={inputWidth}>
            <PrescriptionDurationButtonsContainer>
              {Const.PRESCRIPTION_DURATION_FAVORITES.map((day) => {
                const getDataTestIdForDay = (day) => {
                  switch (day) {
                    case 2:
                      return DATA_TEST_ID.ADD_NEW_TEST_DIALOG.TEST
                        .PRESCRIPTION_DURATION.TWO_DAYS;
                    case 3:
                      return DATA_TEST_ID.ADD_NEW_TEST_DIALOG.TEST
                        .PRESCRIPTION_DURATION.THREE_DAYS;
                    case 7:
                      return DATA_TEST_ID.ADD_NEW_TEST_DIALOG.TEST
                        .PRESCRIPTION_DURATION.SEVEN_DAYS;
                    case 8:
                      return DATA_TEST_ID.ADD_NEW_TEST_DIALOG.TEST
                        .PRESCRIPTION_DURATION.EIGHT_DAYS;
                    case 14:
                      return DATA_TEST_ID.ADD_NEW_TEST_DIALOG.TEST
                        .PRESCRIPTION_DURATION.FOURTEEN_DAYS;
                    default:
                      return null;
                  }
                };

                return (
                  <Button
                    dataTestId={getDataTestIdForDay(day)}
                    key={day}
                    style={{
                      width: 'fit-content',
                      padding: '4px 12px',
                      height: '24px',
                      background: theme.color.COOL_GRAY_30,
                      color: theme.color.COOL_GRAY_80,
                      fontSize: '12px',
                      fontWeight: '400',
                    }}
                    color={false ? 'pressed' : 'secondary'}
                    text={
                      day +
                      intl.formatMessage(
                        INTL_MAP.PRESCRIPTION_DURATION_DAY_UNIT_SHORT_NAME
                      )
                    }
                    onClick={(e) => {
                      setPrescriptionDuration(day);
                    }}
                  />
                );
              })}
            </PrescriptionDurationButtonsContainer>
          </DialogRowItem>

          <DialogRowItem
            inputWidth={inputWidth}
            labelWidth={LABEL_WIDTH}
            label={intl.formatMessage({
              id: '99-AddNewTestDialog-DialogRowItem-label-referredBy',
              description: '3.4 검사 추가 팝업의 referredBy 필드이름',
              defaultMessage: '처방의',
            })}
            required={true}>
            <DoctorsDropdown
              dataTestId={DATA_TEST_ID.ADD_NEW_TEST_DIALOG.TEST.REFERRED_BY}
              dropToTop={false}
              anchorContainerStyle={{
                height: 30,
              }}
              itemContainerStyle={{
                top: 33,
              }}
            />
          </DialogRowItem>

          <DialogRowItem
            inputWidth={inputWidth}
            labelWidth={LABEL_WIDTH}
            labelAlign={labelAlignConst.start}
            label={intl.formatMessage({
              id: '99-AddNewTestDialog-DialogRowItem-label-note ',
              description: '3.4 검사 추가 팝업의 비고',
              defaultMessage: '비고',
            })}>
            <NoteTextareaWrapper width={textInputWidth}>
              <TextAreaInput
                dataTestId={DATA_TEST_ID.ADD_NEW_TEST_DIALOG.TEST.NOTE}
                minLength={2000}
                value={note}
                onChange={onChangeNoteTextArea}
                helperLeftText={
                  !!lastUpdateUser &&
                  !!lastUpdateDate &&
                  `업데이트 : ${lastUpdateUser}(${DateUtil.formatDateOnly(
                    lastUpdateDate
                  )})`
                }
                helperRightText={`${note.length}/${CHARACTER_LIMIT}`}
              />
            </NoteTextareaWrapper>
          </DialogRowItem>
        </DialogContentContainer>

        <DialogButtonWrapper style={{ display: 'block' }}>
          {_isNewMode && $writingTypeIsNewBottomBtnList}
          {_isEditMode && $writingTypeIsEditBottomBtnList}
        </DialogButtonWrapper>
      </DialogWrapper>
    </Wrapper>
  );

  function getDeleteBtn(props) {
    return (
      <TextButton
        key="deleteBtn"
        style={{ ...props?.style, color: theme.color.RED_70 }}
        outline
        title={intl.formatMessage({
          id: '99-Dialog-Button-title-delete',
          description: '팝업 Dialog의 삭제 버튼',
          defaultMessage: '삭제',
        })}
        onClick={onClickDelete}
      />
    );
  }
  function getCancelBtn(props) {
    return (
      <TextButton
        dataTestId={DATA_TEST_ID.ADD_NEW_TEST_DIALOG.BUTTON.CANCEL}
        style={props?.style}
        key="cancelBtn"
        outline
        textColor={theme.color.COOL_GRAY_80}
        title={intl.formatMessage({
          id: '99-Dialog-Button-title-cancel',
          description: '팝업 Dialog의 취소 버튼',
          defaultMessage: '취소',
        })}
        onClick={onClose}
      />
    );
  }
  function getAddBtn(props) {
    return (
      <TextButton
        dataTestId={DATA_TEST_ID.ADD_NEW_TEST_DIALOG.BUTTON.ADD}
        style={props?.style}
        key="addBtn"
        disabled={!isAllInfoEntered}
        textColor={isAllInfoEntered && theme.color.BLUE_70}
        title={intl.formatMessage({
          id: '99-AddNewTestDialog-TextButton-title-01',
          description: '3.4 검사 추가 팝업의 추가 버튼',
          defaultMessage: '추가',
        })}
        onClick={onClickCreate}
      />
    );
  }
  function getEdit(props) {
    return (
      <TextButton
        style={props?.style}
        key="editBtn"
        disabled={!isAllInfoEntered}
        title={intl.formatMessage({
          id: '99-AddNewTestDialog-TextButton-title-01-edit',
          description: '3.4 검사 추가 팝업의 수정시 저장 버튼',
          defaultMessage: '저장',
        })}
        onClick={onClickEdit}
      />
    );
  }
}
const INTL_MAP = defineMessages({
  PRESCRIPTION_DURATION_DAY_ERROR: {
    id: '99-AddNewTestDialog-error-message-dayError',
    defaultMessage: '최대 14일까지 처방 가능합니다.',
    description:
      '최대 14일까지 처방 가능합니다. / Choose from 1 to a maximum of 14 days',
  },
  PRESCRIPTION_DURATION_PLACE_HOLDER: {
    id: '99-AddNewTestDialog-DialogRowItem-label-prescriptionDuration-placeHolder',
    description: '직접 입력하거나 선택 / Please type or choose below',
    defaultMessage: '직접 입력하거나 선택',
  },
  PRESCRIPTION_DURATION_DAY_UNIT_FULL_NAME: {
    id: 'AddNewTestDialog-PrescriptionDurationDayUnit-FullName',
    description: '일 / day(s)',
    defaultMessage: '일',
  },
  PRESCRIPTION_DURATION_DAY_UNIT_SHORT_NAME: {
    id: 'AddNewTestDialog-PrescriptionDurationDayUnit-ShortName',
    description: '일 / d',
    defaultMessage: '일',
  },
});

const Wrapper = styled.div`
  * {
    box-sizing: border-box;
  }
`;

const TitleText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.color.BLACK};
  padding-bottom: 12px;
`;

const NoteTextareaWrapper = styled.div`
  position: relative;
  width: ${(props) => props.textInputWidth}px;
  height: ${NOTE_TEXTAREA_HEIGHT + 10}px;
  border: 1px solid ${(props) => props.theme.color.MEDIUM_LIGHT};
  border-radius: 6px;

  & div.textAreaWrapper {
    display: flex;
    flex-direction: column;
    z-index: 0;
    position: relative;
    height: 100%;
  }
  & textarea {
    width: 98%;
    height: 100% !important;
    min-height: ${NOTE_TEXTAREA_HEIGHT - 15}px;

    box-sizing: border-box;
    margin: 3px 0px 0px 4px;
    padding: 8px 0px 0px 12px;

    border: none !important;
    border-radius: 0px;
    background: transparent;
    resize: none;
    outline: none !important;
    overflow: scroll !important;

    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme.color.DARK};

    transition: box-shadow 0.2s ease-in-out;
    :focus {
      box-shadow: none;
    }
    ${(props) =>
      props.disabled &&
      `
        color: rgba(199, 206, 210, 1);
        background-color: rgba(250, 250, 250, 1);
        cursor: not-allowed;
    `};
  }
  :focus-within {
    border: 1px solid ${({ theme }) => theme.color.BLUE_70};
  }
`;

const PrescriptionDurationButtonsContainer = styled.div`
  display: flex;
  padding-bottom: 4px;
  gap: 4px;
`;

const Divider = styled.div`
  width: 364px;
  height: 1px;
  background-color: ${({ theme }) => theme.color.COOL_GRAY_40};
`;

const TestPeriodInput = styled.input`
  width: 72px;
  height: 34px;
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color.COOL_GRAY_40};
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;

const TestPeriodTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TestPeriodText = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

export default AddNewTestDialog;
