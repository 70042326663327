import { call, put, takeLatest, select } from 'redux-saga/effects';

import ApiManager from 'network/ApiManager/ApiManager';
import DateUtil from 'util/DateUtil';
import {
  getThirtyDaysAgoTimestamp,
  updateIsRead,
} from 'util/TestNotification/TestNotificationUtil';

// Constants
// Selectors
// Actions
// etc function
// Reducer
// Action Creators
// Saga functions
// Saga

// Selectors
const selectNotification = ({ testNotificationReducer }) =>
  testNotificationReducer.notification;
const selectLastThirtyDaysNotification = ({ testNotificationReducer }) =>
  testNotificationReducer.lastThirtyDaysNotification;

// Actions
const GET_TEST_NOTIFICATION_REQUESTED =
  'test-notification/GET_TEST_NOTIFICATION_REQUESTED';
const GET_TEST_NOTIFICATION_SUCCEED =
  'test-notification/GET_TEST_NOTIFICATION_SUCCEED';
const GET_TEST_NOTIFICATION_FAILED =
  'test-notification/GET_TEST_NOTIFICATION_FAILED';
const POLLING_TEST_NOTIFICATION_REQUESTED =
  'test-notification/POLLING_TEST_NOTIFICATION_REQUESTED';
const POLLING_TEST_NOTIFICATION_SUCCEED =
  'test-notification/POLLING_TEST_NOTIFICATION_SUCCEED';
const POLLING_TEST_NOTIFICATION_FAILED =
  'test-notification/POLLING_TEST_NOTIFICATION_FAILED';

const GET_LAST_THIRTY_DAYS_NOTIFICATION_REQUESTED =
  'test-notification/GET_LAST_THIRTY_DAYS_NOTIFICATION_REQUESTED';
const GET_LAST_THIRTY_DAYS_NOTIFICATION_SUCCEED =
  'test-notification/GET_LAST_THIRTY_DAYS_NOTIFICATION_SUCCEED';
const GET_LAST_THIRTY_DAYS_NOTIFICATION_FAILED =
  'test-notification/GET_LAST_THIRTY_DAYS_NOTIFICATION_FAILED';

const PATCH_READ_BY_TIDS_REQUESTED =
  'test-notification/PATCH_READ_BY_TIDS_REQUESTED';
const PATCH_READ_BY_TIDS_SUCCEED =
  'test-notification/PATCH_READ_BY_TIDS_SUCCEED';
const PATCH_READ_BY_TIDS_FAILED = 'test-notification/PATCH_READ_BY_TIDS_FAILED';

// Initial State
const initialState = {
  notification: {
    page: 0,
    lastPage: 0,
    count: 0,
    pending: false,
    data: [],
    error: null,
  },
  lastThirtyDaysNotification: {
    pending: false,
    data: [],
    error: null,
  },
  patchReadBy: {
    pending: false,
    error: null,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case POLLING_TEST_NOTIFICATION_REQUESTED:
    case GET_TEST_NOTIFICATION_REQUESTED: {
      return {
        ...state,
        notification: {
          ...state.notification,
          pending: true,
          error: null,
        },
      };
    }
    case POLLING_TEST_NOTIFICATION_SUCCEED:
    case GET_TEST_NOTIFICATION_SUCCEED: {
      const { page, lastPage, count, data } = action.payload;

      return {
        ...state,
        notification: {
          ...state.notification,
          pending: false,
          page,
          lastPage,
          count,
          data,
        },
      };
    }
    case POLLING_TEST_NOTIFICATION_FAILED:
    case GET_TEST_NOTIFICATION_FAILED: {
      const { error } = action;

      return {
        ...state,
        notification: {
          ...state.notification,
          pending: false,
          error,
        },
      };
    }
    case GET_LAST_THIRTY_DAYS_NOTIFICATION_REQUESTED: {
      return {
        ...state,
        lastThirtyDaysNotification: {
          ...state.lastThirtyDaysNotification,
          pending: true,
          error: null,
        },
      };
    }
    case GET_LAST_THIRTY_DAYS_NOTIFICATION_SUCCEED: {
      const { data } = action.payload;

      return {
        ...state,
        lastThirtyDaysNotification: {
          ...state.lastThirtyDaysNotification,
          pending: false,
          data,
        },
      };
    }
    case GET_LAST_THIRTY_DAYS_NOTIFICATION_FAILED: {
      const { error } = action;

      return {
        ...state,
        lastThirtyDaysNotification: {
          ...state.lastThirtyDaysNotification,
          pending: false,
          error,
        },
      };
    }
    case PATCH_READ_BY_TIDS_REQUESTED: {
      return {
        ...state,
        patchReadBy: {
          pending: true,
          error: null,
        },
      };
    }
    case PATCH_READ_BY_TIDS_SUCCEED: {
      return {
        ...state,
        patchReadBy: {
          pending: false,
          error: null,
        },
      };
    }
    case PATCH_READ_BY_TIDS_FAILED: {
      return {
        ...state,
        patchReadBy: {
          pending: false,
          error: null,
        },
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function getTestNotificationRequested({ page }) {
  return { type: GET_TEST_NOTIFICATION_REQUESTED, payload: { page } };
}
function getTestNotificationSucceed({ page, lastPage, count, data }) {
  return {
    type: GET_TEST_NOTIFICATION_SUCCEED,
    payload: {
      page,
      lastPage,
      count,
      data,
    },
  };
}
function getTestNotificationFailed(error) {
  return { type: GET_TEST_NOTIFICATION_FAILED, error };
}

export function pollingTestNotificationRequested() {
  return { type: POLLING_TEST_NOTIFICATION_REQUESTED };
}
function pollingTestNotificationSucceed({ page, lastPage, count, data }) {
  return {
    type: POLLING_TEST_NOTIFICATION_SUCCEED,
    payload: {
      page,
      lastPage,
      count,
      data,
    },
  };
}
function pollingTestNotificationFailed(error) {
  return { type: POLLING_TEST_NOTIFICATION_FAILED, error };
}

export function getLastThirtyDaysNotificationRequested() {
  return { type: GET_LAST_THIRTY_DAYS_NOTIFICATION_REQUESTED };
}
function getLastThirtyDaysNotificationSucceed(data) {
  return { type: GET_LAST_THIRTY_DAYS_NOTIFICATION_SUCCEED, payload: { data } };
}
function getLastThirtyDaysNotificationFailed(error) {
  return { type: GET_LAST_THIRTY_DAYS_NOTIFICATION_FAILED, error };
}

export function patchReadByTidsRequested({ tids }) {
  return { type: PATCH_READ_BY_TIDS_REQUESTED, payload: { tids } };
}
function patchReadByTidsSucceed() {
  return { type: PATCH_READ_BY_TIDS_SUCCEED };
}
function patchReadByTidsFailed(error) {
  return { type: PATCH_READ_BY_TIDS_FAILED, error };
}

// Saga Functions
function* _getTestNotification({ payload: { page } }) {
  try {
    const { page: prevPage, data: notiList } = yield select(selectNotification);
    const params = { page, pageSize: 20 };

    if (page <= prevPage) {
      yield put(getTestNotificationFailed());
      return;
    }

    const {
      data: { count, results },
    } = yield call(ApiManager.getTestNotification, {
      ...params,
    });

    const lastPage = Math.ceil(count / params.pageSize);
    const newNotiList = [...notiList, ...results];

    yield put(
      getTestNotificationSucceed({
        page,
        lastPage,
        count,
        data: newNotiList,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(getTestNotificationFailed());
  }
}

function* _pollingTestNotification() {
  try {
    const {
      page,
      count: prevCount,
      data: prevNotiList,
    } = yield select(selectNotification);
    const { data: lastThirtyDaysNotiList } = yield select(
      selectLastThirtyDaysNotification
    );

    if (lastThirtyDaysNotiList[0].tid === prevNotiList[0].tid) {
      yield put(pollingTestNotificationFailed('변경사항 없음'));
      return;
    }

    const {
      data: { count: newCount, results },
    } = yield call(ApiManager.getTestNotification, {
      page: 1,
      pageSize: prevCount + 10,
    });

    const lastPage = Math.ceil(newCount / 20);

    yield put(
      pollingTestNotificationSucceed({
        page,
        lastPage,
        count: newCount,
        data: results,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(pollingTestNotificationFailed());
  }
}

function* _getLastThirtyDaysNotification() {
  try {
    const currentTimestamp = DateUtil.getUserLocationTime().getTime();

    const startDate = getThirtyDaysAgoTimestamp(currentTimestamp);
    const endDate = currentTimestamp;

    const {
      data: { results },
    } = yield call(ApiManager.getTestNotification, {
      createdAtTimestampMsRange: [startDate, endDate],
    });

    yield put(getLastThirtyDaysNotificationSucceed(results));
  } catch (error) {
    console.error(error);
    yield put(getLastThirtyDaysNotificationFailed());
  }
}

function* _patchReadByTids({ payload: { tids } }) {
  try {
    const {
      page,
      lastPage,
      count,
      data: entireNotiList,
    } = yield select(selectNotification);
    const { data: lastThirtyDaysNotiList } = yield select(
      selectLastThirtyDaysNotification
    );

    yield call(ApiManager.patchReadByTid, { tids });

    const tidsSet = new Set(tids);
    const newEntireNotiList = entireNotiList.map((noti) =>
      updateIsRead({ noti, tidsSet })
    );
    const newLastThirtyDaysNotiList = lastThirtyDaysNotiList.map((noti) =>
      updateIsRead({ noti, tidsSet })
    );

    yield put(patchReadByTidsSucceed());
    yield put(
      getTestNotificationSucceed({
        page,
        lastPage,
        count,
        data: newEntireNotiList,
      })
    );
    yield put(getLastThirtyDaysNotificationSucceed(newLastThirtyDaysNotiList));
  } catch (error) {
    console.error(error);
    yield put(patchReadByTidsFailed());
  }
}

// Saga
export function* saga() {
  /* GET */
  yield takeLatest(GET_TEST_NOTIFICATION_REQUESTED, _getTestNotification);
  yield takeLatest(
    GET_LAST_THIRTY_DAYS_NOTIFICATION_REQUESTED,
    _getLastThirtyDaysNotification
  );

  /* POLLING */
  yield takeLatest(
    POLLING_TEST_NOTIFICATION_REQUESTED,
    _pollingTestNotification
  );

  /* PATCH */
  yield takeLatest(PATCH_READ_BY_TIDS_REQUESTED, _patchReadByTids);
}
